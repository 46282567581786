import { DatePipe } from '@angular/common';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { UserService } from '../../../services/user/user.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-branch-report',
  templateUrl: './branch-report.component.html',
  styleUrls: ['./branch-report.component.scss'],
  providers: [DatePipe]
})
export class BranchReportComponent implements OnInit {
  // customerData: Array<any>;
  customerData: any;
  public iterableDiffer = null;
  userGroupType: any;
  
 // @Input() public customerData: Array<any>;
  branchData: Array<any>;
  @ViewChild('TABLE', { static: false }) TABLE: ElementRef;
  title = 'Excel';
  
  ExportTOExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.TABLE.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'BranchData.xlsx');
  }
  constructor(private customerService: UserService, public datepipe: DatePipe) {  }
  
  ngOnInit(): void {
    // this.loadCustomer();
  }

   loadCustomer(): any{
    const branchId = 0;
    var fromdate = ((document.getElementById("FormDate") as HTMLInputElement).value);
    var todate = ((document.getElementById("ToDate") as HTMLInputElement).value);

    const params = {branchId, fromdate, todate};

    this.customerService.getCustomerCountByBranch(params).subscribe((data: any) => {
      this.customerData = data;
      },
      error => {console.log(error); }
    );
   }

}
