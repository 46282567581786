<div class="col-md ">
    <div class="widget-bg-color-icon card-box fadeInDown animated d-flex">
        <div class="bg-icon bg-icon-info pull-left" (click)="viewCustomerReport(item.branchId)">
            <i class="md md-attach-money text-info"><span class="pointer"
                    style="font-size: medium; font-style: normal;">{{item.branchName}}</span></i>
        </div>
        <div class="text-right ml-5">
            <h3 class="text-dark"><b class="counter">{{item.noOfBranch}}</b></h3>
            <p class="text-muted mb-0" style="font-size: smaller">Total Customer</p>
        </div>
        <div class="clearfix"></div>
    </div>
</div>