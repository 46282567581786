import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { RegistrationService } from '../../services/registration.service';

@Component({
  selector: 'app-miniso-registration',
  templateUrl: './miniso-registration.component.html',
  styleUrls: ['./miniso-registration.component.scss']
})
export class MinisoRegistrationComponent implements OnInit {

  RegForm: FormGroup;

  public visibility = false;
  constructor(private fb: FormBuilder,
              private registration: RegistrationService,
              private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.RegForm = this.fb.group({
      FranchiseName: ['', [Validators.required]],
      ContactPersonName: [''],
      Address: [''],
      TelephoneLandline: ['', [Validators.pattern('^[0-9]*$')]],
      MobileNo: ['', [Validators.required, Validators.pattern('^[+]?[0-9]*$')]],
      Whatsapp: [false],
      AgreeCheckBox: ['']
    });
  }

  clicked(){
    if (this.visibility === true){
      this.visibility = false;
    }
    else{
      this.visibility = true;
    }
  }


  onSubmit() {

    console.log(this.RegForm.value);

    if (this.RegForm.get('FranchiseName').invalid) {
      this.toastr.error('Please Input Franchise Name', 'Error!');
      return;
    }


    if (this.RegForm.get('MobileNo').invalid) {
      this.toastr.error('Please Input Mobile No-1', 'Error!');
      return;
    }

    if (this.RegForm.get('AgreeCheckBox').value === '') {
      return this.toastr.error('Please agree with Terms & Conditions', 'Error!');
    }

    if (this.RegForm.get('FranchiseName').value !== ''
      && this.RegForm.get('MobileNo').value !== '') {
      this.registration.registrationService(this.RegForm.value).subscribe(success => {
        this.RegForm.reset();
        this.toastr.success(success.message, 'Success!');
      },
        error => {
          console.log(error);
          this.toastr.error(error.error, 'Error!');
        }
      );

      console.log(this.registration);
    }
  }


}
