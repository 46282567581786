import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { UserService } from '../../services/user/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  SearchForm: FormGroup;
  SearchFormSubmit: FormGroup;
  public searcReult : any;
  logoRoute: any;
  userGroupType: string;

  constructor(private formBuilder: FormBuilder,private authService: AuthService, private toastrService: ToastrService,
    private router: Router, private customerService: UserService) { }

  ngOnInit(): void {
    this.userGroupType = localStorage.getItem('userGroupType');

    if (this.userGroupType === 'SU') {
      this.logoRoute = 'dashboard';
    } else if (this.userGroupType === 'A') {
      this.logoRoute = 'dashboard';
    } else if (this.userGroupType === 'F') {
      this.logoRoute = 'dashboard';
    } else if (this.userGroupType === 'O') {
      this.logoRoute = 'outlet';
    }
    
    this.searchForm();
  }

  toggleClass(event: any, classp: string) {
    let bodyid: any = document.getElementById('bodytglid');
    const hasClass =  document.getElementById('bodytglid').classList.contains('sidenav-toggled');
    if (hasClass) {
    bodyid.classList.remove('sidenav-toggled');
    }
    else {
      document.getElementById('bodytglid').classList.add('sidenav-toggled');
    }
  }

  isLogedIn(){
    return this.authService.logedIn();
  }
  logout(){
    this.authService.logout();
    console.log(localStorage.getItem('token'));
    if(localStorage.getItem('token') == null){
      this.router.navigate(['/login']);
    }

  }

  ShowUser(){
    const userPanel =  document.getElementById('userPanel') as HTMLElement;
    userPanel.style.display = 'block';

  }

  searchForm(): any {
    this.SearchForm = this.formBuilder.group({
      iSerchField: new FormControl(''),
      iSearchId: new FormControl(''),
    });
  }

  get f(): any{
    return this.SearchForm.controls;
  }

  onSubmit(): any{
    debugger;
      this.SearchFormSubmit = this.formBuilder.group({
        iSearchField: [this.SearchForm.get('iSerchField').value],
        iSearchId: [this.SearchForm.get('iSearchId').value],
      });

      this.customerService.GetSearchDataById(this.SearchFormSubmit.value)
        .subscribe(res => {
          this.searchForm();
            const data = res;
            if(data){
              localStorage.setItem('searcReult', JSON.stringify(data));
            }
            this.router.navigate(['/search-result']);
        },
        error => {
          console.log(error);
          this.toastrService.error(error, 'Error!');
        });
    }
  }

