import { find } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { Component, OnInit, ElementRef, ViewChild, Input,IterableDiffers } from '@angular/core';
import { UserService } from '../../../services/user/user.service';
import * as XLSX from 'xlsx';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BranchService } from '../../../services/branch/branch.service';
import * as moment from 'moment';

@Component({
  selector: 'app-badreview-list',
  templateUrl: './badreview-list.component.html',
  styleUrls: ['./badreview-list.component.scss'],
  providers: [DatePipe]
})
export class BadReviewListComponent implements OnInit {
  // customerData: Array<any>;
  customerData: any;
  public iterableDiffer = null;
  userGroupType: any;
  
 // @Input() public customerData: Array<any>;
  branchData: Array<any>;
  @ViewChild('TABLE', { static: false }) TABLE: ElementRef;
  title = 'Excel';
  // tslint:disable-next-line: typedef
  ExportTOExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.TABLE.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'CustomerBadReviewList.xlsx');
  }
  constructor(private customerService: UserService, private activeRoute: ActivatedRoute,
    private toastrService: ToastrService, branchService: BranchService,
    private iterableDiffers: IterableDiffers, public datepipe: DatePipe)
     {
      this.iterableDiffer = iterableDiffers.find([]).create(null);
      const id = +this.activeRoute.snapshot.paramMap.get('id');

    branchService.getBranchList().subscribe((brData: any) => {
        if(id !==null && id !== 0){
           this.branchData = brData.filter(x => x.branchId === id);
        }
      },
      error => {console.log(error); }
    );
   }
   dtOptions: any = {};
  ngOnInit(): void {
    this.userGroupType = localStorage.getItem('userGroupType');
    const id = +this.activeRoute.snapshot.paramMap.get('id');
    if (id !== null && id !== 0){
      this.loadCustomerById(id);
      }else
      {
        this.loadCustomer();
      }
  }

  items: any;
  ngDoCheck() {
    const lookup = {};
      this.items = this.customerData.ProductArray;
      const changes = this.iterableDiffer.diff(this.items);
      if (changes) {
        this.dtOptions = {
          pagingType: 'full_numbers',
          pageLength: 5,
        lengthMenu : [5, 10, 25],
          processing: true
        };
      }
  }


  loadCustomerById(branchId): any{
    this.customerService.GetCustomerDetailForBadReviewReport(branchId)
    .subscribe(
      (data: any) => {        
        this.customerData = data;
        if(this.customerData == null) {
          this.toastrService.error('No Data Found', 'Error!');
        }
      },
      error => {console.log(error); }
    );
   }

   loadCustomer(): any{
    const branchId = +this.activeRoute.snapshot.paramMap.get('id');
    var fromdate = ((document.getElementById("FormDate") as HTMLInputElement).value);
    var todate = ((document.getElementById("ToDate") as HTMLInputElement).value);

    // var nFormdate = this.datepipe.transform(fromdate, 'dd/MM/yyyy');
    // var nFodate = this.datepipe.transform(todate, 'dd/MM/yyyy');
    const params = {branchId, fromdate, todate};
//.getCustomerList()
    this.customerService.GetCustomerDetailForBadReviewReportByParams(params).subscribe((data: any) => {
        if(fromdate == "" && todate == ""){
          this.customerData = data;
          if(this.customerData == null) {
            this.toastrService.error('No Data Found', 'Error!');
          }
        }
        else {
          this.iterableDiffer = this.iterableDiffers.find([]).create(null);
            // var table = $('#customerTable').DataTable();
            // table.clear().draw();
            $('#customerTable').DataTable().clear();
            $('#customerTable').DataTable().destroy();
            // data.forEach(item => item.createdDate = item.createdDate.split(" "));
            // data.filter((x: { cmP_BRANCH_ID: number; }) => x.cmP_BRANCH_ID == id);

            this.customerData = data; //.filter(item => (item.createdDate[0] >= nFormdate && item.createdDate[0] <= nFodate) && item.branchId == id);
        }
      },
      error => {console.log(error); }
    );
   }

}
