import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Common } from '../common/common';

@Injectable({
  providedIn: 'root'
})
export class BrandService {

  constructor(private http: HttpClient) { }
  // tslint:disable-next-line: typedef
  public AddBrand(data: any) {
    return this.http.post<any>(Common.baseUrl + '/Brand/InsertBrand', data);
  }
  // tslint:disable-next-line: typedef
  public UpdateBrand(data: any) {
    return this.http.post<any>(Common.baseUrl + '/Brand/UpdateBrand/', data);
  }
  // tslint:disable-next-line: typedef
  public getBrandList() {
    return this.http.get<any>(Common.baseUrl + '/Brand/GetBrandList/');
  }

  // tslint:disable-next-line: typedef
  public getBrandById(data: any) {
    return this.http.get<any>(Common.baseUrl + '/Brand/GetBrandById/' + data);
  }

  // tslint:disable-next-line: typedef
  public DeleteBrand(data: string) {
    return this.http.delete<any>(Common.baseUrl + '/Brand/DeleteBrand/' + data);
  }
}
