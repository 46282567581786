
    <div class="row">
      <div class="col-md-12">
        <div class="tile">
          <div class="tile-title-w-btn">
            <div class="col-12">
              <div class="row">
                <div class="col-md-3">
                  <input id="FormDate" type="date"class="form-control">
                </div>
                <div class="col-md-3">
                  <input id="ToDate" type="date"class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="loadCustomer()" class="btn btn-primary">Search</button>
                </div>
                <div class="col-md-4 text-center" *ngIf="customerData != null">
                  <button (click)="ExportTOExcel()" class="btn btn-success">Export To Excel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="customerData != null">
        <div class="col-md-12" #TABLE #table>
            <div class="tile">
              <table class="table table-striped table-bordered table-sm row-border hover" id="customerTable">
                <thead>
                  <tr>
                    <th style="text-align: center;">Serial</th>
                    <th>Store</th>
                    <th *ngFor="let item of customerData.months" style="text-align: center;">{{item}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of customerData.branchDataDetails; let i = index">
                    <td>{{i+1}}</td>
                    <td>{{item.store}}</td>
                    <td *ngFor="let item of customerData.branchDataDetails[i].monthsData; let i = index" style="text-align: center;">{{item}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
    </div>
