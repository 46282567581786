
    <div class="app-title">
        <div>
          <h1><i class="fa fa-edit"></i>Shop list</h1>
          <!-- <p>Add Supplier</p> -->
        </div>
        <ul class="app-breadcrumb breadcrumb">
          <li class="breadcrumb-item"><i class="fa fa-home fa-lg"></i></li>
          <li class="breadcrumb-item">Shop</li>
          <li class="breadcrumb-item"><a routerLink="/shop-list">shop list</a></li>
        </ul>
      </div>
      <div class="row">
        <!-- <div>
          <p>Form value: {{BrandForm.value | json}}</p>
          <p>Form status: {{BrandForm.status | json}}</p>
        </div> -->
          <div class="col-md-12">
              <div class="tile">
                <h3 class="tile-title">Add Shop</h3>
                <div class="tile-body">
                  <form class="form-horizontal">
                    <div class="form-group row">
                      <label class="control-label col-md-2">Name</label>
                      <div class="col-md-8">
                        <input class="form-control" type="text" placeholder="Enter full name">
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="control-label col-md-2">Email</label>
                      <div class="col-md-8">
                        <input class="form-control col-md-8" type="email" placeholder="Enter email address">
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="control-label col-md-2">Mobile</label>
                      <div class="col-md-8">
                        <input class="form-control col-md-8" type="number" placeholder="Enter mobile address">
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="control-label col-md-2">Address</label>
                      <div class="col-md-8">
                        <textarea class="form-control" rows="4" placeholder="Enter your address"></textarea>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="control-label col-md-2">Upload Pic</label>
                      <div class="col-md-8">
                        <input class="form-control" type="file">
                      </div>
                    </div>
                  </form>
                </div>
                <div class="tile-footer">
                  <div class="row">
                    <div class="col-md-8 col-md-offset-3">
                      <button class="btn btn-primary" type="button">Submit</button>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
    