import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Common } from '../../../services/common/common';
import { ProductService } from '../../../services/product/product.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { ShopService } from '../../../services/shop/shop.service';
import { BrandService } from '../../../services/brand/brand.service';
import { CategoryService } from '../../../services/category/category.service';

@Component({
  selector: 'app-product-update',
  templateUrl: './product-update.component.html',
  styleUrls: ['./product-update.component.scss']
})
export class ProductUpdateComponent implements OnInit {

  imgData: any[] = [];
  productImages: any[];
  product: any;
  prodImages =  [];
  images = [];
  shopData: Array<any>;
  categoryData: Array<any>;
  brandData: Array<any>;
  currentField: any;
  addProductForm: FormGroup;
  addProductFormSubmit: FormGroup;
  ProductImageOne: any;
  ProductImageNameOne: any;
  ProductImageTwo: any;
  ProductImageNameTwo: any;
  ProductImageThree: any;
  ProductImageNameThree: any;
  ProductImageFour: any;
  ProductImageNameFour: any;
  ProductImageFive: any;
  ProductImageNameFive: any;
  productImagePath: string;
  shopProfileId: any;
  photoData: Array<any>;
  photosId: Array<any>;

  constructor(private formBuilder: FormBuilder, private productService: ProductService,
              private toastrService: ToastrService, private router: Router, private shopService: ShopService,
              private brandService: BrandService, private categoryService: CategoryService,
              private activeRoute: ActivatedRoute) {
                this.getShopList();
                this.getCategoryList();
                this.getBrandList();
               }

  ngOnInit(): void {
    const productId = this.activeRoute.snapshot.paramMap.get('id');
    if (productId !== null){
      this.loadProduct(productId);
      }
    this.productForm();

  }

  loadProduct(productId): any{
    this.photoData = new Array<any>();
    this.photosId = new Array<any>();
    this.productService.getProductById(productId).subscribe((data: any) => {
      this.product = data;
      this.photoData = data.productImages;
      this.photosId = data.photosId;
      for (let i = 0; i < this.photoData.length; i++) {
        this.photoData[i] = Common.baseUrl + '/images/' + data.productImages[i];
      }
      this.imgData = this.photoData;
      console.log(this.imgData);
      console.log(this.product);
      console.log(this.photosId);


      this.SetData(this.product);
    });
  }

  SetData(data): any {
    console.log(data);
    this.addProductForm.patchValue({
      ProductName: data.productName,
      ProductDescription: data.productDescription,
      CategoryId: data.categoryId,
      BrandId: data.brandId,
      ShopId: data.shopProfileId,
      StockQuantity: data.quantity,
      RegularPrice: data.regularPrice,
      SellPrice: data.sellPrice,
      fileSource: data.productImages.forEach(item => item.productImages),
      file: data.productImages.forEach(item => item.productImages),
      PhotosId: this.photosId
    });
  }

  getShopList(): any{
    this.shopData = new Array<any>();
    this.shopService.getShopList()
                  .subscribe(
                    (data: any) => {
                      this.shopData = data;
                      console.log(this.shopData);
                    },
                    error => {console.log(error); }
                    );
  }

  getCategoryList(): any{
    this.categoryData = new Array<any>();
    this.categoryService.getCategoryList()
                  .subscribe(
                    (data: any) => {
                      this.categoryData = data;
                      console.log(this.categoryData);
                    },
                    error => {console.log(error); }
                    );
  }

  getBrandList(): any{
    this.brandData = new Array<any>();
    this.brandService.getBrandList()
                  .subscribe(
                    (data: any) => {
                      this.brandData = data;
                    },
                    error => {console.log(error); }
                    );
  }
  productForm(): any {
    this.addProductForm = this.formBuilder.group({
      ProductName: new FormControl('', [Validators.required, Validators.maxLength(150)]),
      ProductDescription: new FormControl('', [Validators.required, Validators.maxLength(2000)]),
      ShopId: new FormControl('', [Validators.required]),
      CategoryId: new FormControl('', [Validators.required]),
      BrandId: new FormControl('', [Validators.required]),
      StockQuantity: 0,
      RegularPrice: 0.00,
      SellPrice: 0.00,
      file: new FormControl('', [Validators.required]),
      fileSource: new FormControl('', [Validators.required]),
      productImages: [''],
      ShopProfileId: [''],
      PhotosId: []
    });
  }

  get f(): any{
    return this.addProductForm.controls;
  }

  onFileChange(event): any {
    if (event.target.files && event.target.files[0]) {
        const filesAmount = event.target.files.length;
        for (let i = 0; i < filesAmount; i++) {
            const reader = new FileReader();
            reader.readAsDataURL(event.target.files[i]); // read file as data url
            this.currentField = i;
            const file = event.target.files[i];
            // tslint:disable-next-line: no-shadowed-variable;
            reader.onloadend = (e) => {
              console.log(e.target.result);

              this.images.push(e.target.result as string);
              this.prodImages.push(file.name);

              this.addProductForm.patchValue({
                  fileSource: this.images,
                  productImages: this.prodImages
                });
            };
        }
    }
  }

  imgDelete(iId: number): any{
    this.imgData.forEach( (item, index) => {
      if (item.id === iId){
         this.imgData.splice(index, 1);
      }
    });
  }
  onSubmit(): any {
  const productId = +this.activeRoute.snapshot.paramMap.get('id');
  if (productId !== null && productId !== 0) {
    this.addProductFormSubmit = this.formBuilder.group({
      productName: [this.addProductForm.get('ProductName').value],
      productDescription: [this.addProductForm.get('ProductDescription').value],
      shopProfileId: [+this.addProductForm.get('ShopId').value],
      categoryId: [+this.addProductForm.get('CategoryId').value],
      brandId: [+this.addProductForm.get('BrandId').value],
      shopId: [+this.addProductForm.get('ShopId').value],
      regularPrice: [parseFloat(this.addProductForm.get('RegularPrice').value)],
      sellPrice: [parseFloat(this.addProductForm.get('SellPrice').value)],
      productImages: [this.addProductForm.get('productImages').value],
      fileSource: [this.addProductForm.get('fileSource').value]
  });

    if (this.addProductForm.get('ProductName').hasError('required')) {
      this.toastrService.error('Please Input Product Name', 'Error!');
      return;
    }

    if (this.addProductForm.get('ProductDescription').hasError('required')) {
      this.toastrService.error('Please Input Product Description', 'Error!');
      return;
    }

    if (this.addProductForm.get('CategoryId').hasError('required')) {
      this.toastrService.error('Please Input Category', 'Error!');
      return;
    }

    if (this.addProductForm.get('BrandId').hasError('required')) {
      this.toastrService.error('Please Input Brand', 'Error!');
      return;
    }

    if (this.addProductForm.get('file').hasError('required')) {
      this.toastrService.error('Please Input Image', 'Error!');
      return;
    }
    this.productService.UpdateProduct(this.addProductFormSubmit.value)
      .subscribe(res => {
        this.toastrService.success(res.message, 'Data Save Successfully.');
        this.productForm();
        this.router.navigate(['/product-list']);
      },
      error => {
        console.log(error);
        this.toastrService.error(error, 'Error!');
      });
    }
  }
}
