import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ProductService } from '../../../services/product/product.service';
import { Router, ActivatedRoute } from '@angular/router';
import { BrandService } from '../../../services/brand/brand.service';
import { CategoryService } from '../../../services/category/category.service';
import { Common } from '../../../services/common/common';
import { ShopService } from '../../../services/shop/shop.service';

@Component({
  selector: 'app-product-add',
  templateUrl: './product-add.component.html',
  styleUrls: ['./product-add.component.scss']
})
export class ProductAddComponent implements OnInit {
  // image: string = Common.baseUrl + '/images/';
  imgData: any[] = [];
  productImages: any[];
  product: any;
  prodImages =  [];
  images = [];
  shopData: Array<any>;
  categoryData: Array<any>;
  brandData: Array<any>;
  currentField: any;
  addProductForm: FormGroup;
  addProductFormSubmit: FormGroup;
  ProductImageOne: any;
  ProductImageNameOne: any;
  ProductImageTwo: any;
  ProductImageNameTwo: any;
  ProductImageThree: any;
  ProductImageNameThree: any;
  ProductImageFour: any;
  ProductImageNameFour: any;
  ProductImageFive: any;
  ProductImageNameFive: any;
  productImagePath: string;
  shopProfileId: any;
  photoData: Array<any>;
  photosId: Array<any>;

  constructor(private formBuilder: FormBuilder, private productService: ProductService,
              private toastrService: ToastrService, private router: Router, private shopService: ShopService,
              private brandService: BrandService, private categoryService: CategoryService,
              private activeRoute: ActivatedRoute) {
                this.getShopList();
                this.getCategoryList();
                this.getBrandList();
               }

  ngOnInit(): void {
    const productId = this.activeRoute.snapshot.paramMap.get('id');
    if (productId !== null){
      this.loadProduct(productId);
      }
    this.productForm();

  }

  // tslint:disable-next-line: typedef
  loadProduct(productId){
    // tslint:disable-next-line: no-debugger
    // debugger;
    this.photoData = new Array<any>();
    this.photosId = new Array<any>();
    this.productService.getProductById(productId).subscribe((data: any) => {
      this.product = data;
      this.photoData = data.productImages;
      this.photosId = data.photosId;
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < this.photoData.length; i++) {
        this.photoData[i] = Common.baseUrl + '/images/' + data.productImages[i];
      }
      this.imgData = this.photoData;
      console.log(this.product);
      console.log(this.photosId);


      this.SetData(this.product);
    });
  }

  // tslint:disable-next-line: typedef
  SetData(data) {
    // tslint:disable-next-line: no-debugger
    //debugger;
    this.addProductForm.patchValue({
      ProductName: data.productName,
      ProductDescription: data.productDescription,
      CategoryId: data.categoryId,
      BrandId: data.brandId,
      ShopId: data.shopProfileId,
      StockQuantity: data.quantity,
      RegularPrice: data.regularPrice,
      SellPrice: data.sellPrice,
      // file: data.productImages,
      fileSource: this.imgData,
      PhotosId: this.photosId
    });
  }
  // tslint:disable-next-line: typedef
  getShopList(){
    this.shopData = new Array<any>();
    this.shopService.getShopList()
                  .subscribe(
                    (data: any) => {
                      this.shopData = data;
                      console.log(this.shopData);
                    },
                    error => {console.log(error); }
                    );
  }
  // tslint:disable-next-line: typedef
  getCategoryList(){
    this.categoryData = new Array<any>();
                // user_photo: SafeResourceUrl;
    this.categoryService.getCategoryList()
                  .subscribe(
                    (data: any) => {
                      this.categoryData = data;
                      console.log(this.categoryData);
                    },
                    error => {console.log(error); }
                    );
  }

  // tslint:disable-next-line: typedef
  getBrandList(){
    this.brandData = new Array<any>();
                // user_photo: SafeResourceUrl;
    this.brandService.getBrandList()
                  .subscribe(
                    (data: any) => {
                      this.brandData = data;
                      // console.log(this.brandData);
                    },
                    error => {console.log(error); }
                    );
  }
  // tslint:disable-next-line: typedef
  productForm() {
    this.addProductForm = this.formBuilder.group({
      ProductName: new FormControl('', [Validators.required, Validators.maxLength(150)]),
      ProductDescription: new FormControl('', [Validators.required, Validators.maxLength(2000)]),
      ShopId: new FormControl('', [Validators.required]),
      CategoryId: new FormControl('', [Validators.required]),
      BrandId: new FormControl('', [Validators.required]),
      StockQuantity: 0,
      RegularPrice: 0.00,
      SellPrice: 0.00,
      file: new FormControl('', [Validators.required]),
      fileSource: new FormControl('', [Validators.required]),
      productImages: [''],
      ShopProfileId: [''],
      PhotosId: []
    });
  }

  // tslint:disable-next-line: typedef
  get f(){
    return this.addProductForm.controls;
  }

  // tslint:disable-next-line: typedef
onFileChange(event) {
    // tslint:disable-next-line: no-debugger
    //debugger;
    if (event.target.files && event.target.files[0]) {
        const filesAmount = event.target.files.length;
        for (let i = 0; i < filesAmount; i++) {
            const reader = new FileReader();
            reader.readAsDataURL(event.target.files[i]); // read file as data url
            this.currentField = i;
            const file = event.target.files[i];
            // tslint:disable-next-line: no-shadowed-variable;
            reader.onloadend = (e) => {
              console.log(e.target.result);

              this.images.push(e.target.result as string);
              this.prodImages.push(file.name);

              this.addProductForm.patchValue({
                  fileSource: this.images,
                  productImages: this.prodImages
                });
            };
        }
    }
  }
  // tslint:disable-next-line: typedef
  imgDelete(iId: number){
    this.imgData.forEach( (item, index) => {
      if (item.id === iId){
         this.imgData.splice(index, 1);
      }
    });
  }
  // tslint:disable-next-line: typedef
onSubmit() {
    this.addProductFormSubmit = this.formBuilder.group({
      ProductName: [this.addProductForm.get('ProductName').value],
      ProductDescription: [this.addProductForm.get('ProductDescription').value],
      ShopProfileId: [+this.addProductForm.get('ShopId').value],
      CategoryId: [+this.addProductForm.get('CategoryId').value],
      BrandId: [+this.addProductForm.get('BrandId').value],
      productImages: [this.addProductForm.get('productImages').value],
      fileSource: [this.addProductForm.get('fileSource').value]
    });

    if (this.addProductForm.get('ProductName').hasError('required')) {
      this.toastrService.error('Please Input Product Name', 'Error!');
      return;
    }

    if (this.addProductForm.get('ProductDescription').hasError('required')) {
      this.toastrService.error('Please Input Product Description', 'Error!');
      return;
    }

    if (this.addProductForm.get('CategoryId').hasError('required')) {
      this.toastrService.error('Please Input Category', 'Error!');
      return;
    }

    if (this.addProductForm.get('BrandId').hasError('required')) {
      this.toastrService.error('Please Input Brand', 'Error!');
      return;
    }

    if (this.addProductForm.get('file').hasError('required')) {
      this.toastrService.error('Please Input Image', 'Error!');
      return;
    }
    this.productService.AddProduct(this.addProductFormSubmit.value)
      .subscribe(res => {
        console.log(res);
        this.toastrService.success(res.message, 'Data Save Successfully.');
        this.productForm();
        this.router.navigate(['/product-list']);
      },
      error => {
        console.log(error);
        this.toastrService.error(error, 'Error!');
      });
  }

}
