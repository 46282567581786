import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HTTP_INTERCEPTORS, HttpErrorResponse } from '@angular/common/http';
// import 'rxjs/add/operator/do';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { tap, catchError } from 'rxjs/operators';
import { ErrorInterceptor } from './auth/auth-service/error.interceptor';
import { AuthService } from './auth/auth.service';
import { Observable, throwError } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor
{
  constructor(private router: Router)
  {}
  intercept(req: HttpRequest<any>, next: HttpHandler): import('rxjs').Observable<HttpEvent<any>> {
      if (req.headers.get('No-Auth') === 'True') {
          return next.handle(req.clone());
      }
      //debugger;
      if (localStorage.getItem('token') != null) {
      const clonedreq = req.clone({
      headers: req.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
      });
      return next.handle(clonedreq)
      .pipe(
        catchError(error => {
          if(error instanceof HttpErrorResponse) {
            if(error.status === 401) {
              return throwError(error.statusText);
            }
            const applicationError = error.headers.get('Application-Error');
            if(applicationError) {
              //console.log(applicationError);
              return throwError(applicationError);
            }
            const serverError = error.error;
            let modalStateErrors = '';
            if (serverError && typeof serverError === 'object') {
              for (const key in serverError) {
               if(serverError[key]) {
                 modalStateErrors += serverError[key] + '\n';
               }

              }
            }
            return throwError(modalStateErrors || serverError || 'Server Error')
          }
        })
      );
    }
    else {
      this.router.navigateByUrl('/login');
    }
  }
}