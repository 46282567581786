import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shop-add',
  templateUrl: './shop-add.component.html',
  styleUrls: ['./shop-add.component.scss']
})
export class ShopAddComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
