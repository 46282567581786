import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { OrderService } from '../../../services/order/order.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormGroup, FormBuilder } from '@angular/forms';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-pending-order',
  templateUrl: './pending-order.component.html',
  styleUrls: ['./pending-order.component.scss']
})
export class PendingOrderComponent implements OnInit {

  data: Array<any>;
  orderData: any;
 // categoris: any[] = [];
  pendingorder: FormGroup;
  detailsValue = [];

  constructor(
    private orderService: OrderService,
    private router: Router,
    private http: HttpClient,
    private SpinnerService: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    ) {
      this. GetPendingOrder();
      this.GetPendingOrderDetails();
   }

  ngOnInit(): void {
  }
  GetPendingOrder()
  {
    const id = +this.activatedRoute.snapshot.paramMap.get('id');
    this.pendingorder = this.fb.group({
      orderId: id,
    });
    this.orderService.getPendingOrderbyid(this.pendingorder.value).subscribe(
     data1 => {
      /// this.orderData = data1;
       this.orderData = data1;
       console.log(data1);
     },
     error => {console.log(error);
     // this.SpinnerService.hide(); 
    }
     );
  }

  GetPendingOrderDetails()
    {
     // debugger;
      const id = +this.activatedRoute.snapshot.paramMap.get('id');
      this.pendingorder = this.fb.group({
        orderId: id,
      });
      this.SpinnerService.show();
      this.orderService.getPendingOrderdetails(this.pendingorder.value)
     .subscribe(
       data => {
         this.data = data;
        // console.log(data);
         this.SpinnerService.hide(); 
       },
       error => {console.log(error);
                 this.SpinnerService.hide(); 
      }
       );
    }
    orderd(data1){
      Swal.fire({
        title: 'Are you sure want to submit?',
        text: 'You will not be able to recover this file!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Submit it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        const id = +this.activatedRoute.snapshot.paramMap.get('id');
        this.pendingorder = this.fb.group({
          orderId: id
        });
        if (result.value) {
           this.orderService.submitorderchkout(this.pendingorder.value).subscribe(
               
            data => {
               Swal.fire(
                'Submited!',
                'success'
              )
              
             
               },
               error => {console.log(error); }
             );
           this.router.navigate(['/pending-order-list']);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your imaginary file is safe :)',
            'error'
          )
        }
      })

  //  const list = data1;
  //  for (const i in list) {
  //   this.detailsValue.push(
  //     {
  //       Qty: list[i].quantity,
  //       productId: +list[i].productId,
  //     });
  //   }
  //   const id = +this.activatedRoute.snapshot.paramMap.get('id');
  //   this.pendingorder = this.fb.group({
  //     orderId: id,
  //     purchaseList: [this.detailsValue]
  //   });
  //   console.log(this.pendingorder.value)
  //   alert(JSON.stringify(this.pendingorder.value));
  //   return this.detailsValue;
  
    }
 
}


