import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Common } from '../common/common';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http: HttpClient) { }

  public GetBillingSummaryForChart(branchId: number) {
    return this.http.get<any>(Common.baseUrl + '/Customer/GetBillingSummaryForChart/' + branchId);
  }

  public GetAgeSummaryForChart(branchId: number) {
    return this.http.get<any>(Common.baseUrl + '/Customer/GetAgeSummaryForChart/' + branchId);
  }

  public GetExperienceSummaryForChart(branchId: number) {
    return this.http.get<any>(Common.baseUrl + '/Customer/GetExperienceSummaryForChart/' + branchId);
  }
  
  public GetInterestedCategorySummaryForChart(branchId: number) {
    return this.http.get<any>(Common.baseUrl + '/Customer/GetInterestedCategorySummaryForChart/' + branchId);
  }

  public GetGotToKnowSummaryForChart(branchId: number) {
    return this.http.get<any>(Common.baseUrl + '/Customer/GetGotToKnowSummaryForChart/' + branchId);
  }

  public GetSeeOnlineChart(branchId: number) {
    return this.http.get<any>(Common.baseUrl + '/Customer/GetSeeOnlineChart/' + branchId);
  }

  public GetAssociateOfferChart(branchId: number) {
    return this.http.get<any>(Common.baseUrl + '/Customer/GetAssociateOfferChart/' + branchId);
  }

  public GetReceiveCommercialChart(branchId: number) {
    return this.http.get<any>(Common.baseUrl + '/Customer/GetReceiveCommercialChart/' + branchId);
  }
  
  public getDashboardChartData(branchId: number) {
    return this.http.get<any>(Common.baseUrl + '/Customer/GetDashboardChartData/' + branchId);
  }

  public getDashboardChartDataByParams(data: any) {
    return this.http.post<any>(Common.baseUrl + '/Customer/getDashboardChartDataByParams/', data);
  }

  public getBranchChartDataByParams(data: any) {
    return this.http.post<any>(Common.baseUrl + '/Customer/getBranchChartDataByParams/', data);
  }
}
