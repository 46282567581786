import { Component, OnInit, Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { Common } from '../../../services/common/common';
import { CategoryService } from '../../../services/category/category.service';
import { ThrowStmt } from '@angular/compiler';
@Component({
  selector: 'app-category-add',
  templateUrl: './category-add.component.html',
  styleUrls: ['./category-add.component.scss']
})
@Injectable({
  providedIn: 'root'
})
export class CategoryAddComponent implements OnInit {
  // category: any[] = [];
  category: Array<any>;
  image = '';
  entryForm: FormGroup;
  formSubmit: FormGroup;
  id = 0;
  parentId = 0;
  imgId = 0;
  imgData: any[] = ['https://www.google.com/search?q=dummy+images&sxsrf=ALeKk00X8bwIbKsO6gWH9fk0QgWcYCfYEQ:1604323939729&tbm=isch&source=iu&ictx=1&fir=6Q3bW6aEcE7lnM%252Cd4UfnB9cDHR6MM%252C_&vet=1&usg=AI4_-kTDO6sqXnHaZhK7FuyctWgRfjOzMA&sa=X&ved=2ahUKEwiUxqC0_OPsAhW7IbcAHSCNCxQQ9QF6BAgNEGk&biw=1366&bih=625#imgrc=wNwYhuVmPXUBSM'];
  constructor(private formBuilder: FormBuilder, private http: HttpClient,
              private toastrService: ToastrService, private activatedRoute: ActivatedRoute,
              private categoryService: CategoryService) { }

  ngOnInit(): void {
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    this.parentId = 0;
    this.form();
    // if (id !== null){
    this.loadCategory(id);
   // }
  }
  // tslint:disable-next-line: typedef
  loadData(categoryId){
    this.category.find(x => {
      if (+x.id === +categoryId){
        this.id = x.id;
        this.parentId = x.parentId;
        document.getElementById('showImage').style.display = 'block';

        const list = new DataTransfer();
        const file = new File(['content'], x.icon);
        list.items.add(file);
        const myFileList = list.files;

        (document.getElementById('Name') as HTMLInputElement).value = x.name;
        // console.log((document.getElementById('Parent') as HTMLSelectElement).value);
        (document.getElementById('file') as HTMLInputElement).files = myFileList;
        this.image = x.icon;

        this.entryForm.setValue({
          Name: x.name,
          Parent: x.parentId,
          fileSource: x.icon,
          file: x.icon,
          isActive: x.isActive,
          isHome: x.isHome
       });
        (document.getElementById('isActive') as HTMLInputElement).checked = x.isActive;
        (document.getElementById('isHome') as HTMLInputElement).checked = x.isHome;
    }
   });
  }
  // tslint:disable-next-line: typedef
  loadCategory(categoryId){
    this.category = new Array<any>();
    this.categoryService.getCategoryList().subscribe((data: any[]) => {
      this.category = data;
      this.category.forEach(item => item.icon = Common.baseUrl + '/images/' + item.icon);
      console.log(this.category);
      if (categoryId !== null){
         this.loadData(categoryId);
      }
    });
  }
  // tslint:disable-next-line: typedef
  form() {
    this.entryForm = this.formBuilder.group({
      Name: new FormControl('', [Validators.required, Validators.pattern('^[+]?[a-zA-Z .-]*$'), Validators.minLength(3)]),
      Parent: new FormControl('', [Validators.required]),
      file: new FormControl('', [Validators.required]),
      fileSource: new FormControl('', [Validators.required]),
      isActive: new FormControl(true, [Validators.required]),
      isHome: new FormControl(false, [Validators.required])
    });
  }

  // tslint:disable-next-line: typedef
  get f(){
    return this.entryForm.controls;
  }

  imgDelete(iId: number): any{
    this.imgData.forEach( (item, index) => {
      if (item.id === iId){
         this.imgData.splice(index, 1);
      }
    });
  }

  // tslint:disable-next-line: typedef
  onFileChange(event) {
    // tslint:disable-next-line: no-debugger
    if (event.target.files && event.target.files[0]) {
        const filesAmount = event.target.files.length;
        // const ext = event.target.files[0].substr(event.target.files[0].lastIndexOf('.') + 1);
        if (event.target.files[0].name.match(/.(jpg|jpeg|png|gif|ico)$/i)) {
        for (let i = 0; i < filesAmount; i++) {
            const reader = new FileReader();

            // tslint:disable-next-line: no-shadowed-variable
            reader.onload = (event: any) => {
              console.log(event.target.result);
              this.image = event.target.result;
              document.getElementById('showImage').style.display = 'block';
              this.imgData.push({id: this.imgId++, url: event.target.result});
              this.entryForm.patchValue({
                  fileSource: this.image
                });
            };

            reader.readAsDataURL(event.target.files[i]);
        }
        }
        else{
          (document.getElementById('file') as HTMLInputElement).value = '';
          document.getElementById('showImage').style.display = 'none';
          this.toastrService.error('Please Input Image File', 'Error!');
        }
    }
  }

  // tslint:disable-next-line: typedef
  onSubmit(){
    // tslint:disable-next-line: no-debugger
    if (this.entryForm.get('Name').hasError('required')) {
      this.toastrService.error('Please Input Name', 'Error!');
      return;
    }
    const e = (document.getElementById('Parent')) as HTMLSelectElement;
    // const sel = e.selectedIndex;
    // const opt = e.options[sel];
    const ParentValue = e.value;

    (document.getElementById('btnSubmit')as HTMLButtonElement).classList.add('disabled');
    // console.log(this.entryForm.value);
    let flag = true;
    const activeCategory = document.getElementById('isActive') as HTMLInputElement;
    const homeCategory = document.getElementById('isHome') as HTMLInputElement;

    if (this.id !== null && this.id !== 0){
      flag = false;
      this.formSubmit = this.formBuilder.group({
        Id: this.id,
        Name: [this.entryForm.get('Name').value],
        // tslint:disable-next-line: radix
        ParentId: parseInt(ParentValue),
        // Icon: [this.entryForm.get('file').value]
        Icon: [this.image],
        isActive: activeCategory.checked ? true : false,
        isHome: homeCategory.checked ? true : false
      });
    }
    else{
      this.formSubmit = this.formBuilder.group({
        Name: [this.entryForm.get('Name').value],
        // tslint:disable-next-line: radix
        ParentId: parseInt(ParentValue),
        // Icon: [this.entryForm.get('file').value]
        Icon: [this.image],
        isActive: activeCategory.checked ? true : false,
        isHome: homeCategory.checked ? true : false
      });
    }
    flag ? this.Post(this.formSubmit.value) : this.Update(this.formSubmit.value);
  }
  Clear(): any{
    (document.getElementById('Parent') as HTMLSelectElement).selectedIndex = 0;
    (document.getElementById('file') as HTMLInputElement).value = '';
    (document.getElementById('Name') as HTMLInputElement).value = '';
    document.getElementById('showImage').style.display = 'none';
    (document.getElementById('btnSubmit')as HTMLButtonElement).classList.remove('disabled');
  }
  Post(v): any{
    this.categoryService.AddCategory(v)
      .subscribe((res: any) => {
        this.SuccessOrFail(res.message);
      });
  }
  Update(v): any{
    this.categoryService.UpdateCategory(v)
      .subscribe((res: any) => {
        this.SuccessOrFail(res.message);
      });
  }
  SuccessOrFail(msg): any{
    if (msg === 'Success'){
      this.loadCategory(null);
      // tslint:disable-next-line: radix
      this.parentId = 0;
      this.toastrService.success('Save Successfully', 'Success');
      this.Clear();
    }
    else{
      this.toastrService.error(msg, 'Warnning!');
    }
  }
}
