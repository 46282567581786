  <div>
    <div class="tile">
      <div class="tile-title-w-btn mt-4">
        <h1><i class="fa fa-edit"></i> Branch Admin Registration</h1>
      </div>
    </div>
  </div>

  <div class="row">
    <!-- <div>
      <p>Form value: {{SuperAdminregForm.value | json}}</p>
      <p>Form status: {{SuperAdminregForm.status | json}}</p>
    </div> -->
    <div class="col-md-12">
      <div class="tile">
        <div class="tile-body">
          <form [formGroup]="SuperAdminregForm"  (ngSubmit)="onSubmit()" class="login-form">
            <!-- <h3 class="login-head" style="text-align: center;"><i class="fa fa-lg fa-fw fa-user"></i>Admin Registration</h3> -->
            <div class="form-group row mt-3">
              <div class="col-md-4">
                <label class="control-label">Branch</label>
                <div class="controls">
                    <div class="input-prepend input-group">
                        <select class="form-control" formControlName="BranchId">
                            <option selected value="">--Select Branch--</option>
                            <option *ngFor='let item of branchData' value="{{item.branchId}}">{{item.branchName}}</option>
                        </select>
                        <div *ngIf="f.BranchId.touched && f.BranchId.invalid" class="alert alert-danger">
                            <div *ngIf="f.BranchId.errors.required">Branch Name is required.</div>
                        </div>
                    </div>
                </div>
              </div>
              <div class="col-md-4">
                <label>User Group</label>
                <div class="controls">
                  <div class="input-prepend input-group">
                      <select class="form-control" formControlName="UserGroupId">
                          <option selected value="">--Select User Group--</option>
                          <option value="13052901001002">Super Admin</option>
                          <option value="200705180920000001">Admin</option>
                          <option value="200603010000000002">Franchisee</option>
                          <option value="200603010000000003">Outlet</option>
                      </select>
                      <div *ngIf="f.UserGroupId.touched && f.UserGroupId.invalid" class="alert alert-danger">
                          <div *ngIf="f.UserGroupId.errors.required">Staus is required.</div>
                      </div>
                  </div>
              </div>
              </div>
              <div class="col-md-4">
                <label class="control-label">Status</label>
                <div class="controls">
                    <div class="input-prepend input-group">
                        <select class="form-control" formControlName="StatusId">
                            <option selected value="">--Select Status--</option>
                            <option value="A">Active</option>
                            <option value="I">Inactive</option>
                        </select>
                        <div *ngIf="f.StatusId.touched && f.StatusId.invalid" class="alert alert-danger">
                            <div *ngIf="f.StatusId.errors.required">Staus is required.</div>
                        </div>
                    </div>
                </div>
              </div>                         
            </div>


            <div class="form-group row">
              <div class="col-md-4">
                <label>Username</label>
                <input type="text" formControlName="UserName" class="form-control" placeholder="Enter User Name" [ngClass]="{ 'is-invalid': submitted && f.UserName.errors }" />
                <div *ngIf="submitted && f.UserName.errors" class="invalid-feedback">
                    <div *ngIf="f.UserName.errors.required">User Name is required</div>
                </div>
              </div>

              <div class="col-md-4">
                <label class="control-label">Password</label>
                <input type="text" formControlName="UserPassword" class="form-control" placeholder="Enter Password"
                [ngClass]="{ 'is-invalid': submitted && f.UserPassword.errors }" />
                <div *ngIf="submitted && f.UserPassword.errors" class="invalid-feedback">
                    <div *ngIf="f.UserPassword.errors.required">Password is required</div>
                </div>
              </div>

              <div class="col-md-4">
                <label class="control-label">Confirm Password</label>
                <input type="text" formControlName="confirmPassword" class="form-control" placeholder="Enter Confirm Password"
                [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" />
                <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                    <div *ngIf="f.confirmPassword.errors.required">Confirm Password is required</div>
                    <div *ngIf="f.confirmPassword.errors.mustMatch">Passwords must match</div>
                </div>
              </div>
            </div>

            <div class="form-group btn-container">
              <button type="submit" class="btn btn-primary btn-block"><i class="fa fa-sign-in fa-lg fa-fw"></i>SIGN UP</button>
            </div>
          </form>
        </div>
      </div>
    </div>

  </div>
