import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';//this.router.navigate([pathName]);

@Injectable()

export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) { }
  // canActivate(
  //   next: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot):boolean {
  //     if(localStorage.getItem('token') != null)
  //     {
  //       //this.router.navigate(state.url);
  //       return true;
  //     }
  //     else
  //     {
  //       this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
  //       return false;
  //     }        
  // }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //debugger;
    const pathName = state.url.substr(1);
    if (this.authService.logedIn()) {
      if (localStorage.getItem('token')) {
        if(pathName == ""){
          let branchId = +localStorage.getItem('branchId');
          
          if (branchId > 0)
            this.router.navigateByUrl('/branch/'+ branchId); 
          else
            this.router.navigate(['/dashboard']);
        }
        else{
          return true;
        }
      }
    }
    else{
        // not logged in so redirect to login page
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        return false;
    }

  }

  public isLoggedIn(): boolean {
    let status = false;
    if (localStorage.getItem('logedIn') == "true") {
       status = true;
    }
    else {
       status = false;
       }
    return status;
  }
}
