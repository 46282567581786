<div class="app-title">
    <div>
      <h1><i class="fa fa-th-list"></i>Brand</h1>
      <!-- <p>Basic bootstrap tables</p> -->
    </div>
    <ul class="app-breadcrumb breadcrumb">
      <li class="breadcrumb-item"><i class="fa fa-home fa-lg"></i></li>
      <li class="breadcrumb-item">Brand</li>
      <li class="breadcrumb-item active"><a routerLink="/brand-add">Add Brand</a></li>
    </ul>
  </div>
  <div class="row">
      <div class="col-md-12">
          <div class="tile">
            <div class="tile-title-w-btn">
                <h3 class="title">Brand List</h3>
                <p><a class="btn btn-primary icon-btn" routerLink="/brand-add"><i class="fa fa-plus"></i>Add Brand</a></p>
              </div>
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>Serial</th>
                  <th>Name</th>
                  <th>Sort Order</th>
                  <th>Brand Images</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of data; let i = index" id="tr_{{item.brandId}}">
                  <td>{{i+1}}</td>
                  <td>{{item.brandName}}</td>
                  <td>{{item.sortOrder}}</td>
                  <td><img src="{{item.brandImageName}}" width="100px" height="100px"/> </td>
                  <td>
                    <button class="btn btn-success btn-sm mr-2" (click)="edit(item.brandId)"><i class="fa fa-edit"></i></button>
                    <button class="btn btn-danger btn-sm" (click)="delete(item.brandId)"><i class="fa fa-trash"></i></button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        </div>    
