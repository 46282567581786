import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-supplier-add',
  templateUrl: './supplier-add.component.html',
  styleUrls: ['./supplier-add.component.scss']
})
export class SupplierAddComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
