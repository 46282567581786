<div class="app-title">
    <div>
      <h1><i class="fa fa-edit"></i>Brand</h1>
      <!-- <p>Sample forms</p> -->
    </div>
    <ul class="app-breadcrumb breadcrumb">
      <li class="breadcrumb-item"><i class="fa fa-home fa-lg"></i></li>
      <li class="breadcrumb-item">Brand</li>
      <li class="breadcrumb-item"><a routerLink="/brand-list">Brand List</a></li>
    </ul>
  </div>
  
  <div class="row">
    <!-- <div>
      <p>Form value: {{BrandForm.value | json}}</p>
      <p>Form status: {{BrandForm.status | json}}</p>
    </div> -->
    <div class="col-md-12">
      <div class="tile">
        <h3 class="tile-title">Add Brand</h3>
        <div class="tile-body">
          <form [formGroup]="BrandForm" (ngSubmit)="onSubmit()" class="form-horizontal">
            <div class="form-group row">
              <div class="col-md-4">
                <label class="control-label">Brand Name</label>
                <input 
                    formControlName="BrandName"
                    id="BrandName" 
                    type="text"
                    placeholder="Enter brand name" 
                    class="form-control">
                    <div *ngIf="f.BrandName.touched && f.BrandName.invalid" class="alert alert-danger">
                      <div *ngIf="f.BrandName.errors.required">Name is required.</div>
                      <div *ngIf="f.BrandName.errors.maxLength">Name maximum 150 character.</div>
                      <div *ngIf="f.BrandName.errors.pattern">Only character & number allowed.</div>
                  </div>
              </div>
              <div class="col-md-4">
                <label class="control-label">Sort Order</label>
                <input class="form-control" type="number" placeholder="Enter Sort Order" 
                formControlName="SortOrder" id="SortOrder">
                <div *ngIf="f.SortOrder.touched && f.SortOrder.invalid" class="alert alert-danger">
                  <div *ngIf="f.SortOrder.errors.required">Name is required.</div>
                  <div *ngIf="f.SortOrder.errors.pattern">Only number allowed.</div>
                </div>
              </div>
              <div class="col-md-4">
                <label class="control-label">Brand Status</label>
                <select id="BrandStatus" class="form-control"  formControlName="BrandStatus">
                  <option selected value="">Select Status </option>
                  <option value="true">Active</option>
                  <option value="false">Inactive</option>
                </select>
                <div *ngIf="f.BrandStatus.touched && f.BrandStatus.invalid" class="alert alert-danger">
                  <div *ngIf="f.BrandStatus.errors.required">Name is required.</div>
                </div>
              </div>
            </div>
            
            <div class="form-group row">
              <div class="col-md-12">
                <label for="file" class="control-label">Brand Image</label>
                <input 
                      formControlName="file"
                      id="file" 
                      type="file" 
                      class="form-control",
                      accept="image/*"
                      (change)="onFileChange($event, 1)">
                    <div *ngIf="f.file.touched && f.file.invalid" class="alert alert-danger">
                        <div *ngIf="f.file.errors.required">File is required.</div>
                    </div>
              </div>
            </div>
            
            <div class="col-12">
              <img   [src]="brandImagePath" id="showImage" height="150" width="200px" style="margin: 3px;"> <br/>
            </div>

            <div class="button-align">
              <button class="btn btn-primary btn-lg" type="submit">Submit</button>
            </div>
            
          </form>
        </div>
        <!-- <div class="tile-footer">
          <div class="row">
            <div class="col-md-8 col-md-offset-3">
              <button class="btn btn-primary" type="button"><i class="fa fa-fw fa-lg fa-check-circle"></i>Register</button>&nbsp;&nbsp;&nbsp;<a class="btn btn-secondary" href="#"><i class="fa fa-fw fa-lg fa-times-circle"></i>Cancel</a>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    
  </div>