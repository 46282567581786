import { Injectable } from '@angular/core';
import { Common } from '../common/common';
import { HttpClient } from '@angular/common/http';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AdminProfileService {
  customerData = {userGroupId: '', profileId: 0, userId: 0, fullName: '', userName: '', mobile: '', email: '', customerPhoto: ''};
  constructor(private http: HttpClient) { }

  public UpdateCustomer(data: any) {
    return this.http.post<any>(Common.baseUrl + '/CustomerProfile/UpdateCustomerInfo', data);
  }

  public UpdateCustomerAddress(data: any) {
    return this.http.post<any>(Common.baseUrl + '/Address/UpdateCustomerAddress', data);
  }
 // tslint:disable-next-line: typedef
 public getAddressList(data: any) {
  return this.http.get<any>(Common.baseUrl + '/Address/GetAddressById/' + data);
  }
  public GetCustomer(){
    const decoded = jwt_decode(localStorage.getItem('token'));
    const datas = JSON.stringify(decoded);
    JSON.parse(datas, (key, value) => {
      // debugger;
      console.log(key + ' ' + value);
      if (key.trim() === 'nameid'){
        // this.profileIdOrUserId = value;
        this.customerData.userId = value;
      }
      else if (key === '0'){
        // this.userName = value;
        this.customerData.userName = value;
      }
      else  if (+key === 1){
        this.customerData.userGroupId = value;
      }
      else  if (+key === 2){
        this.customerData.fullName = value;
      }
      else  if (+key === 3){
        this.customerData.email = value;
      }
      else  if (+key === 4){
        this.customerData.mobile = value;
      }
      else  if (+key === 5){
        this.customerData.profileId = value;
      }
      else  if (+key === 6){
        this.customerData.customerPhoto = value;
      }
     });
    return this.customerData;
  }
  public GetAddress(id: number) {
    return this.http.get(Common.baseUrl + '/Address/GetAddressById/' + id);
  }
  public ChangePassword(data: any) {
    return this.http.post<any>(Common.baseUrl + '/CustomerProfile/ChangePassword', data);
  }

}
