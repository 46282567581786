import { Component, OnInit, Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { Common } from '../../../services/common/common';

@Component({
  selector: 'app-slide-add',
  templateUrl: './slide-add.component.html',
  styleUrls: ['./slide-add.component.scss']
})
@Injectable({
  providedIn: 'root'
})
export class SlideAddComponent implements OnInit {
  constructor(private formBuilder: FormBuilder, private http: HttpClient,
              private toastrService: ToastrService, private activatedRoute: ActivatedRoute) { }

  get f(): any{
    return this.entryForm.controls;
  }
  slide: any[] = [];
  entryForm: FormGroup;
  formSubmit: FormGroup;
  id = 0;
  image: string = Common.baseUrl + '/images/';
  imgData: any[] = [];
  imgId = 0;
  url: string[] = [];
  file = '';
  ngOnInit(): void {
    // tslint:disable-next-line: no-unused-expression
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    this.form();
    this.loadslide(id);
  }
  loadData(slideId): any{
    this.slide.find(x => {
      if (+x.id === +slideId){
        this.id = x.id;
        const list = new DataTransfer();
        const file = new File(['content'], x.img);
        list.items.add(file);
        const myFileList = list.files;
        (document.getElementById('Title') as HTMLInputElement).value = x.title;
        (document.getElementById('Description') as HTMLInputElement).value = x.description;
        (document.getElementById('file') as HTMLInputElement).files = myFileList;
        this.image += x.img;

        this.entryForm.setValue({
          Title: x.title,
          Description: x.description,
          // fileSource: x.icon64String,
          file: '',
       });
    }
   });
  }
  loadslide(slideId): any{
    this.http.get(Common.baseUrl + 'Slide/GetSlide').subscribe((data: any[]) => {
      this.slide = data;
      console.log(data);
      if (slideId !== null){
         this.loadData(slideId);
    }
    });
  }
  // tslint:disable-next-line: typedef
  form() {
    this.entryForm = this.formBuilder.group({
      Description: new FormControl(''),
      Title: new FormControl(''),
      file: new FormControl('', [Validators.required, Validators.minLength(1)]),
      // fileSource: new FormControl('', [Validators.required])
    });
  }
  onSelectFile(event): any {
    if (event.target.files && event.target.files[0]) {
        const filesAmount = event.target.files.length;
        for (let i = 0; i < filesAmount; i++) {
                const reader = new FileReader();
                // tslint:disable-next-line: no-shadowed-variable
                reader.onload = (event: any) => {
                  // console.log(event.target.result);
                  this.imgData.push({id: this.imgId++, url: event.target.result});
                };
                reader.readAsDataURL(event.target.files[i]);
        }
    }
  }
  imgDelete(iId: number): any{
    this.imgData.forEach( (item, index) => {
      if (item.id === iId){
         this.imgData.splice(index, 1);
      }
    });
  }

  onSubmit(): any{
    this.imgData.forEach( (item, index) => {
         this.url.push(item.url);
    });
    (document.getElementById('btnSubmit')as HTMLButtonElement).classList.add('disabled');
    // console.log(this.entryForm.value);
    let url = 'Slide/EntrySlide';
    if (this.id !== null && this.id !== 0){
      url = 'Slide/EditSlide';
      alert(this.entryForm.get('Description').value);
      this.formSubmit = this.formBuilder.group({
        Id: this.id,
        Description: [this.entryForm.get('Description').value],
        Title: [this.entryForm.get('Title').value],
        IsActive: (document.getElementById('isActive') as HTMLInputElement).checked ? true : false,
        Img64String: [this.url],
        Img: [this.url.join('|')],
      });
    }
    else{
      this.formSubmit = this.formBuilder.group({
        Description: [this.entryForm.get('Description').value],
        Title: [this.entryForm.get('Title').value],
        IsActive: [(document.getElementById('isActive') as HTMLInputElement).checked ? true : false],
        Img: [this.url.join('|')],      });
    }
    let params = new HttpParams();
    params = params.append('Img', this.imgData.join('|'));
    this.http.post(Common.baseUrl + url, this.formSubmit.value)
      .subscribe((res: any) => {
        if (res.message === 'Success'){
          this.loadslide(null);
          this.toastrService.success('Save Successfully', 'Success');
          this.Clear();
        }
        else{
          this.toastrService.error(res.message, 'Warnning!');
        }
      });
  }
  Clear(): any{
    this.file = '1.jpg';
    (document.getElementById('Description') as HTMLInputElement).value = '';
    (document.getElementById('Title') as HTMLInputElement).value = '';
    (document.getElementById('btnSubmit')as HTMLButtonElement).classList.remove('disabled');
  }
}
