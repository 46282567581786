
    <div class="row">
      <div class="col-md-12">
        <div class="tile">
          <div class="tile-title-w-btn" *ngFor="let data of branchData">
            <!-- <h1><i class="fa fa-th-list"></i> {{data.branchName}} Customer List</h1>
            <button (click)="ExportTOExcel()" class="btn btn-success">ExportTOExcel</button> -->
            <div class="col-12">
              <div class="row mt-4">
                <div class="col-md-4">
                  <h1 style="text-align: left;"><i class="fa fa-th-list"></i>{{data.branchName}} Customer List</h1>
                </div>
                <div class="col-md-8 mt-2">
                  <div class="row">
                    <div class="col-md-3">
                      <input id="FormDate" type="date"class="form-control">
                    </div>
                    <div class="col-md-3">
                      <input id="ToDate" type="date"class="form-control">
                    </div>
                    <div class="col-md-2">
                      <button (click)="loadCustomer()" class="btn btn-primary">Search</button>
                    </div>
                    <div class="col-md-4 text-center" *ngIf="userGroupType == 'SU'">
                      <button (click)="ExportTOExcel()" class="btn btn-success">Export To Excel</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
      <div class="row">
          <div class="col-md-12" #TABLE #table>
              <div class="tile">
                <table class="table table-striped table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions" id = "customerTable">
                  <thead>
                    <tr>
                      <th>Serial</th>
                      <th>Name</th>
                      <th>City</th>
                      <th>Mobile No</th>
                      <th>Email</th>
                      <th>Age Group</th>
                      <th>I would be interested in <br/> receving information about</th>
                      <th>How did you get to know about us?</th>
                      <th>I would like to receive commercial via?</th>
                      <th>Billing Range</th>
                      <th>Frequency</th>
                      <th>Create Date</th>
                      <th>Overall Experience</th>
                      <th>Remarks</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of customerData; let i = index" id="tr_{{item.customerId}}">
                      <td>{{i+1}}</td>
                      <td *ngIf="userGroupType !== 'F'"><a routerLink="/customer-details/{{item.customerId}}">{{item.name}}</a></td>
                      <td *ngIf="userGroupType === 'F'">{{item.name}}</td>
                      <td>{{item.city}}</td>
                      <td>{{item.userMobile}}</td>
                      <td>{{item.userEmail}}</td>
                      <td>{{item.ageGroupName}}</td>
                      <td>{{item.interestedInfo}}</td>
                      <td>{{item.socialInfoId}}</td>
                      <td>{{item.rcvComVia}}</td>
                      <td>{{item.billingRange}}</td>
                      <td>{{item.frequency}}</td>
                      <td>{{item.createdDate }}</td>
                      <td>{{item.overallExperienceText }}</td>
                      <td>{{item.remarks }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
      </div>
