import { ErrorInterceptor } from './view/services/auth/auth-service/error.interceptor';
import { TokenInterceptor } from './view/services/auth/token.interceptor';
import { SessionExpiredComponent } from './view/services/auth/session-expired/session-expired.component';
import { BranchAdminListComponent } from './view/pages/app-admin/branch-admin-list/branch-admin-list.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './view/_layout/header/header.component';
import { SideMenuComponent } from './view/_layout/side-menu/side-menu.component';
import { CommonComponent } from './view/_layout/common/common.component';
import { ProductAddComponent } from './view/pages/app-product/product-add/product-add.component';
import { ProductListComponent } from './view/pages/app-product/product-list/product-list.component';
import { SupplierListComponent } from './view/pages/app-supplier/supplier-list/supplier-list.component';
import { SupplierAddComponent } from './view/pages/app-supplier/supplier-add/supplier-add.component';
import { BrandAddComponent } from './view/pages/app-brand/brand-add/brand-add.component';
import { BrandListComponent } from './view/pages/app-brand/brand-list/brand-list.component';
import { CategoryAddComponent } from './view/pages/app-category/category-add/category-add.component';
import { CategoryListComponent } from './view/pages/app-category/category-list/category-list.component';
import { ShopAddComponent } from './view/pages/app-shoplist/shop-add/shop-add.component';
import { ShopListComponent } from './view/pages/app-shoplist/shop-list/shop-list.component';
import { ShopListAddCatComponent } from './view/pages/app-shoplist/shop-list-add-cat/shop-list-add-cat.component';
import { ShopListCatListComponent } from './view/pages/app-shoplist/shop-list-cat-list/shop-list-cat-list.component';
import { UserAddComponent } from './view/pages/app-user/user-add/user-add.component';
import { UserListComponent } from './view/pages/app-user/user-list/user-list.component';
import { StockListComponent } from './view/pages/app-stock/stock-list/stock-list.component';
import { TestComponent } from './view/pages/test/test.component';
import { TestnComponent } from './view/pages/testn/testn.component';
import { AdminloginComponent } from './view/adminlogin/adminlogin.component';
import { PurchaseDetailsComponent } from './view/pages/app-purchase/purchase-details/purchase-details.component';
import { PurchaseListComponent } from './view/pages/app-purchase/purchase-list/purchase-list.component';
import { AuthGuard } from './view/services/auth/auth.guard';
import { PurchaseUpdateComponent } from './view/pages/app-purchase/purchase-update/purchase-update.component';
import { ProductUpdateComponent } from './view/pages/app-product/product-update/product-update.component';
import { AdminregComponent } from './view/adminreg/adminreg.component';
import { AuthService } from './view/services/auth/auth.service';
import { EncryptionDescryptionService } from './view/services/auth/encryption-descryption.service';
import { AuthInterceptor } from './view/services/auth.interceptor';
import { DatePipe, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { PendingOrderlistComponent } from './view/pages/app-order/pending-orderlist/pending-orderlist.component';
import { DataTablesModule } from 'angular-datatables';
import { NgxSpinnerModule } from 'ngx-spinner';
import { EmployeeRegComponent } from './view/pages/employee-reg/employee-reg.component';
import { SlideAddComponent } from './view/pages/app-slide/slide-add/slide-add.component';
import { SlideComponent } from './view/pages/app-slide/slide-list/slide.component';
import { AdvertiseAddComponent } from './view/pages/app-advertise/advertise-add/advertise-add.component';
import { AdvertiseListComponent } from './view/pages/app-advertise/advertise-list/advertise-list.component';
import { PendingOrderComponent } from './view/pages/app-order/pending-order/pending-order.component';
import { DeliveryOrderComponent } from './view/pages/app-order/delivery-order/delivery-order.component';
import { DeliverOrderDetailsComponent } from './view/pages/app-order/deliver-order-details/deliver-order-details.component';
// import { SweetAlertArrayOptions } from 'sweetalert2';
import { SellerListComponent } from './view/pages/app-seller/seller-list/seller-list.component';
import { SellerComponent } from './view/pages/app-seller/seller/seller.component';

import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { SuperAdminComponent } from './view/pages/app-admin/super-admin/super-admin.component';
import { MinisoRegistrationComponent } from './view/pages/miniso-registration/miniso-registration.component';
import { BranchAddComponent } from './view/pages/app-branch/branch-add/branch-add.component';
import { BranchListComponent } from './view/pages/app-branch/branch-list/branch-list.component';
import { CustomerDetailsComponent } from './view/pages/app-customer/customer-details/customer-details.component';
import { CommonLayoutComponent } from './view/_layout/common-layout/common-layout.component';
import { SearchResultComponent } from './view/pages/app-customer/search-result/search-result.component';
import { CustomerListComponent } from './view/pages/app-user/customer-list/customer-list.component';
import { ConnectionErrorComponent } from './view/services/auth/connection-error/connection-error.component';
import { ChartComponent } from './view/_layout/chart/chart.component';
import { DashboardComponent } from './view/_layout/dashboard/dashboard.component';
import { HomeComponent } from './view/_layout/home/home.component';
import { PreviewBranchesComponent } from './view/pages/dashboardComponents/preview-branches/preview-branches.component';
import { PreviewBranchesDetailComponent } from './view/pages/dashboardComponents/preview-branches-detail/preview-branches-detail.component';
import { OutletComponent } from './view/pages/app-outlet/outlet.component';
import { BadReviewListComponent } from './view/pages/app-user/badreview-list/badreview-list.component';
import { BranchReportComponent } from './view/pages/app-user/branch-report/branch-report.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SideMenuComponent,
    CommonComponent,
    ProductAddComponent,
    ProductListComponent,
    SupplierListComponent,
    SupplierAddComponent,
    BrandAddComponent,
    BrandListComponent,
    CategoryAddComponent,
    CategoryListComponent,
    ShopAddComponent,
    ShopListComponent,
    ShopListAddCatComponent,
    ShopListCatListComponent,
    UserAddComponent,
    UserListComponent,
    StockListComponent,
    TestComponent,
    TestnComponent,
    AdminloginComponent,
    PurchaseDetailsComponent,
    PurchaseListComponent,
    PurchaseUpdateComponent,
    ProductUpdateComponent,
    AdminregComponent,
    PendingOrderlistComponent,
    PendingOrderComponent,
    DeliveryOrderComponent,
    DeliverOrderDetailsComponent,
    EmployeeRegComponent,
    SlideAddComponent,
    SlideComponent,
    AdvertiseAddComponent,
    AdvertiseListComponent,
    // SellerAddComponent,
    SellerListComponent,
    SellerComponent,
    SuperAdminComponent,
    MinisoRegistrationComponent,
    BranchAddComponent,
    BranchListComponent,
    CustomerDetailsComponent,
    CommonLayoutComponent,
    SearchResultComponent,
    CustomerListComponent,
    BranchAdminListComponent,
    SessionExpiredComponent,
    ConnectionErrorComponent,
    ChartComponent,
    DashboardComponent,
    HomeComponent,
    PreviewBranchesComponent,
    PreviewBranchesDetailComponent,
    OutletComponent,
    BadReviewListComponent,
    BranchReportComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    BsDropdownModule.forRoot(),
    ReactiveFormsModule,
    DataTablesModule,
    NgxSpinnerModule,
    CKEditorModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-right',
      preventDuplicates: false
    }),
  ],
  providers: [
    AuthGuard,
    AuthService,
    EncryptionDescryptionService,
    DatePipe,
    // TimepickerConfig,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true,
    },
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
