import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { OrderService } from '../../../services/order/order.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-delivery-order',
  templateUrl: './delivery-order.component.html',
  styleUrls: ['./delivery-order.component.scss']
})
export class DeliveryOrderComponent implements OnInit {
  data: Array<any>;
  PendingorderData: any;
  posts;
  categoris: any[] = [];
  pendingorder: FormGroup;
  dtOptions: any = {};

  constructor(
    private orderService: OrderService,
    private router: Router,
    private http: HttpClient,
    private fb: FormBuilder,
    private SpinnerService: NgxSpinnerService
    ) {
       this.GetPendingOrderList();
   }

  ngOnInit() {
    this.dtOptions = {
     // pagingType: 'full_numbers',
      pageLength: 5,
    lengthMenu : [5, 10, 25],
      processing: true
    };
  }


  GetPendingOrderList()
    {
      this.pendingorder = this.fb.group({
        orderStatus: 'D',
      });
      this.SpinnerService.show();
      this.orderService.getOrderListdata(this.pendingorder.value)
     .subscribe(
       data => {
         this.PendingorderData = data;
         this.SpinnerService.hide();
       },

       error => {console.log(error);
                 this.SpinnerService.hide();
      }
       );


    }

    orderdetail(orderId){
      this.router.navigate(['/received-order-details/' + orderId]);
    }
    
  
}

