import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';
import { EncryptionDescryptionService } from '../services/auth/encryption-descryption.service';

@Component({
  selector: 'app-adminlogin',
  templateUrl: './adminlogin.component.html',
  styleUrls: ['./adminlogin.component.scss']
})
export class AdminloginComponent implements OnInit {
  userGroupType: string;

  constructor(private fb: FormBuilder,
              private router: Router,
              private toastr: ToastrService,
              private encryptObj: EncryptionDescryptionService,
              private authService: AuthService) { }

  loginForm: FormGroup;
  isLoginHidden = false;
  isRegHidden = true;
  isForgotPassHidden = true;
  regIcon = 'fa-pencil';
  toolTip = 'Create An Account';
  isToolTipHidden = false;
  shopImgSrc = '../../../../assets/images/shop-icon.png';
  working = false;
  loginFormEncrypt: FormGroup;

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });
  }

  // tslint:disable-next-line: typedef
  onSubmit() {
      // if (this.working) { return; }
      // this.working = true;
      // // const $this = $(this),
      // // $state = $this.find('button > .state');
      // // $this.addClass('loading');
      // // $state.html('Authenticating');
      // setTimeout(() => {
      //  // $this.addClass('ok');
      //  // $state.html('Welcome back!');
      //   setTimeout(function() {
      //   //  $state.html('Log in');
      //   //  $this.removeClass('ok loading');
      //     this.working = false;
      //   }, 4000);
      // }, 3000);



    // tslint:disable-next-line: no-debugger
    // debugger;
      if (this.loginForm.invalid) {
        debugger;
        this.toastr.error('Carefully fill the from', 'Error!');
        return;
      }

      this.loginFormEncrypt = this.fb.group({
        // username: this.encryptObj.encryptDataAuth(this.loginForm.get('username').value),
        // password: this.encryptObj.encryptDataAuth(this.loginForm.get('password').value)
        username: this.loginForm.get('username').value,
        password: this.loginForm.get('password').value
      });

      this.authService.authenticate(this.loginFormEncrypt.value).subscribe(data => {

        // let branchId = +localStorage.getItem('branchId');

        // if (branchId > 0)
        //   this.router.navigateByUrl('/branch/'+ branchId); 
        // else
        //   this.router.navigate(['/dashboard']);

        // this.router.navigate(['/dashboard']);

        this.userGroupType = localStorage.getItem('userGroupType');

        if (this.userGroupType === 'SU') {
          this.router.navigate(['/dashboard']);
        } else if (this.userGroupType === 'A') {
          this.router.navigate(['/dashboard']);
        } else if (this.userGroupType === 'F') {
          this.router.navigate(['/dashboard']);
        } else if (this.userGroupType === 'O') {
          this.router.navigate(['/outlet']);
        }

      //this.router.navigate(['dashboard']);
      // if (localStorage.getItem('username') === 'admin@gmail.com')
      // {
      //   this.router.navigate(['/']);
      // }
      // else
      // {
      //   this.router.navigate(['/sa']);
      // }

    },
    error => {
      this.toastr.error(error, 'Login Failed');
      console.log(error);
    });
  }
  
  toggle(){
    if (this.isLoginHidden){
      this.toolTip = 'Create An Account';
      this.regIcon = 'fa-pencil';
      this.isLoginHidden = false;
      this.isRegHidden = true;
      this.isForgotPassHidden = true;
    }
    else{
      this.toolTip = 'Back To Login';
      this.regIcon = 'fa-times';
      this.isLoginHidden = true;
      this.isRegHidden = false;
      this.isForgotPassHidden = true;
    }
  }
  forgotPassword(){
    this.isLoginHidden = true;
    this.isRegHidden = true;
    this.isForgotPassHidden = false;
    this.isToolTipHidden = true;
  }
  backToLogin(){
    this.isLoginHidden = false;
    this.isRegHidden = true;
    this.isForgotPassHidden = true;
    this.isToolTipHidden = false;
  }
  // tslint:disable-next-line: typedef
  onFileChange(event, field: any) {
    // tslint:disable-next-line: no-debugger
    // debugger;
    // this.currentField = field;
    if (event.target.files && event.target.files[0]) {
        const filesAmount = event.target.files.length;
        for (let i = 0; i < filesAmount; i++) {
            const reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]); // read file as data url
            const file = event.target.files[0];
            reader.onloadend = (e) => {
              console.log(event.target.result);
              // if (this.currentField === 1) {
              this.shopImgSrc = e.target.result.toString();
              //   this.entryUser.patchValue({
              //     CustomerProfilePhotoName: file.name,
              //   });
              //   // this.handleChanges(event.target.files[0]);
              //   console.log(file.name);
              // }
            };
        }
    }
  }

}

