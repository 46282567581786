<div class="app-title">
    <div>
      <h1><i class="fa fa-th-list"></i>Category</h1>
      <!-- <p>Basic bootstrap tables</p> -->
    </div>
    <ul class="app-breadcrumb breadcrumb">
      <li class="breadcrumb-item"><i class="fa fa-home fa-lg"></i></li>
      <li class="breadcrumb-item">Category</li>
      <li class="breadcrumb-item active"><a routerLink="/category-add">Add Category</a></li>
    </ul>
  </div>
  <div class="row">
      <div class="col-md-12">
          <div class="tile">
            <div class="tile-title-w-btn">
                <h3 class="title">Category List</h3>
                <p><a class="btn btn-primary icon-btn" routerLink="/category-add"><i class="fa fa-plus"></i>Add Category</a></p>
              </div>
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Parent</th>
                  <th>Is Home Page?</th>
                  <th>Created Date</th>
                  <th>Icon</th>
                  <!-- <th>Created By</th>
                  <th>Updated Date</th>
                  <th>Updated By</th> -->
                  <th>Action</th>
                </tr>
              </thead>
              <tbody id="tr_with_No_data" >
                <tr style="text-align: center;">
                  <td colspan="10">No Data Found!</td>
                </tr>
              </tbody>
              <tbody id="tr_with_data">
                <tr *ngFor="let item of categoris; let i = index" id="tr_{{item.id}}">
                  <td>{{i+1}}</td>
                  <td>{{item.name}}</td>
                  <td>{{item.parentName}}</td>
                  <!-- <td>{{item.icon}}<img src="{{icon}}{{item.icon}}" width="100px" height="100px"/> </td> -->
                  <td>{{item.isHome}}</td>
                  <td>{{item.createdDate | date}}</td>
                  <!-- <td>{{item.createdByName}}</td>
                  <td>{{item.updatedDate}}</td>
                  <td>{{item.UpdatedByName}}</td>  -->
                  <td><img src="{{icon}}{{item.icon}}" width="100px" height="100px"/> </td>
                  <td><button class="btn btn-success btn-sm mr-2" (click)="edit(item.id)"><i class="fa fa-edit"></i></button>
                    <button class="btn btn-danger btn-sm" (click)="delete(item.id)"><i class="fa fa-trash"></i></button></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        </div>    
