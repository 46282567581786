
<div class="app-title">
    <div>
        <h1><i class="fa fa-edit"></i> Purchase Details</h1>
        <!-- <p>Sample forms</p> -->
    </div>
    <ul class="app-breadcrumb breadcrumb">
        <li class="breadcrumb-item"><i class="fa fa-home fa-lg"></i></li>
        <li class="breadcrumb-item">Purchase Details</li>
        <li class="breadcrumb-item"><a routerLink="/productlist">Purchase List</a></li>
    </ul>
</div>

<div class="row">
<div>
    <!-- <p>Form value: {{addPurchaseForm.value | json}}</p> -->
    <!-- <p>Form value: {{addPurchaseForm.controls.purchaseRows.value | json}}</p> -->
    <!-- <p>Form status: {{addProductForm.status | json}}</p> -->
</div>
<div class="col-md-12">
    <div class="tile">
    <h3 class="tile-title">Purchase</h3>
    <div class="tile-body">
        <form [formGroup]="addPurchaseForm" (ngSubmit)="onSubmit()" class="form-horizontal">
        <div class="form-group row">
            <div class="col-md-3">
                <label class="control-label">Select Supplier</label>
                <div class="controls">
                    <div class="input-prepend input-group">
                        <select class="form-control" formControlName="SupplierId">
                            <option selected value="">Select Supplier </option>
                            <option *ngFor='let item of supplierData' value="{{item.supplier_Id}}">{{item.supplier_Name}}</option>
                        </select>
                        <div *ngIf="f.SupplierId.touched && f.SupplierId.invalid" class="alert alert-danger">
                            <div *ngIf="f.SupplierId.errors.required">Supplier Name is required.</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <label class="control-label">Select Shop</label>
                <div class="controls">
                    <div class="input-prepend input-group">
                        <select class="form-control" formControlName="ShopId">
                            <option selected value="">Select Shop </option>
                            <option *ngFor='let item of shopData' value="{{item.shopId}}">{{item.shopName}}</option>
                        </select>
                        <div *ngIf="f.SupplierId.touched && f.SupplierId.invalid" class="alert alert-danger">
                            <div *ngIf="f.SupplierId.errors.required">Shop Name is required.</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <label class="control-label">Invoice No</label>
                <div class="controls">
                    <div class="input-prepend input-group">
                        <input class="form-control" formControlName="InvoiceNo" value="0" type="text"
                        placeholder="Invoice No">
                        <div *ngIf="f.InvoiceNo.touched && f.InvoiceNo.invalid" class="alert alert-danger">
                            <div *ngIf="f.InvoiceNo.errors.required">Invoice No is required.</div>
                            <div *ngIf="f.InvoiceNo.errors.maxLength">Invoice No maximum 50 character.</div>
                            <div *ngIf="f.InvoiceNo.errors.pattern">Only character & number allowed.</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <label class="control-label">Purchase Date</label>
                <div class="controls">
                    <div class="input-prepend input-group">
                        <input placeholder="dd-mm-yyyy" id="purchaseDate" formControlName="PurchaseDate"
                        class="form-control InputBox" type="date">
                    </div>
                </div>
            </div>
        </div>

        <div class="form-group row">
           
            <div class="col-md-4">
                <label class="control-label">Total Amount</label>
                <div class="controls">
                    <div class="input-prepend input-group">
                        <input class="form-control" formControlName="TotalAmount" id ="totalAmount" value="0" type="text"
                        placeholder="Total Amount" disabled>
                        <div *ngIf="f.TotalAmount.touched && f.TotalAmount.invalid" class="alert alert-danger">
                            <div *ngIf="f.TotalAmount.errors.required">Total Amount is required.</div>
                            <div *ngIf="f.TotalAmount.errors.pattern">Only number allowed.</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <label class="control-label">Paid Amount</label>
                <div class="controls">
                    <div class="input-prepend input-group">
                        <input class="form-control" formControlName="PaidAmount" id ="paidAmount" value="0" type="text"
                        placeholder="Paid Amount" (change)="getDueAmount($event)">
                        <div *ngIf="f.PaidAmount.touched && f.PaidAmount.invalid" class="alert alert-danger">
                            <div *ngIf="f.PaidAmount.errors.required">Paid Amount is required.</div>
                            <div *ngIf="f.PaidAmount.errors.pattern">Only number allowed.</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <label class="control-label">Due Amount</label>
                <div class="controls">
                    <div class="input-prepend input-group">
                        <input class="form-control" formControlName="DueAmount" id="dueAmount" value="0" type="text"
                        placeholder="Due Amount" disabled>
                        <div *ngIf="f.DueAmount.touched && f.DueAmount.invalid" class="alert alert-danger">
                            <div *ngIf="f.DueAmount.errors.required">Due Amount is required.</div>
                            <div *ngIf="f.DueAmount.errors.pattern">Only number allowed.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <h3 class="tile-title">Purchase Details</h3>
        <table class="table table-borderless">
            <thead class="thead-light">
                <th>Product Name</th>
                <th>Color Name</th>
                <th>Stock Quantiy</th>
                <th>Purchase Price</th>
                <th>Regular Price</th>
                <th>Sell Price</th>
                <th>Total Price</th>
                <th>Reorder Point</th>
                <th>
                <!-- <a class="btn btn-sm btn-success text-white" data-toggle="modal" data-target="#addDetailsModal"><i class="fa fa-plus"></i> Add Item</a> -->
                <button type="button" (click)="addNewRow()" class="btn btn-primary btn-sm"><i class="fa fa-plus-circle" aria-hidden="true"></i>Add Item</button>
                </th>
            </thead>
            <tbody>
                
            </tbody>
        </table>

        <div class="col-md-12" formArrayName="purchaseRows" >
            <div class="row" *ngFor="let itemrow of addPurchaseForm.controls.purchaseRows['controls']; let i=index;"  [formGroupName]="i">
                <input formControlName="PurchaseDetailsId"  id="purchaseDetailsid{{i}}" value="0"  class="form-control" type="hidden" >
                <!-- <div class="col-md-12">
                <strong>Details - {{i+1}}</strong>
                </div> -->
                <div class="col-md-2" >
                    <div class="form-group">
                        <!-- <label class="col-form-label" for="prependedInput">Select Product</label> -->
                        <div class="controls">
                            <div class="input-prepend input-group">
                                <select class="form-control" formControlName="ProductId" (change)="onSelectProduct($event)">
                                    <option selected value="">Select Product</option>
                                    <option *ngFor='let item of productData' value="{{item.productId}}" [selected]="item.productId === productSelectedId">{{item.productName}}</option>
                                </select>
                            </div>                    
                        </div>
                    </div>                  
                </div>
                <div class="col-md-2" >
                    <div class="form-group">
                        <!-- <label class="col-form-label" for="prependedInput">Select Color</label> -->
                        <div class="controls">
                            <div class="input-prepend input-group">
                                <select class="form-control" formControlName="ColorId" (change)="onSelectColor($event)">
                                    <option selected value="">Select Color</option>
                                    <option *ngFor='let item of colorData' [value]="item.colorId" [selected]="item.colorId === colorSelectedId">{{item.colorName}}</option>
                                </select>
                            </div>                    
                        </div> 
                    </div>                 
                </div>
                <div class="col-md-1" >
                <!-- <label class="col-form-label" for="stockQuantiy{{i}}">Stock Quantiy</label> -->
                    <div class="controls">
                        <div class="input-prepend input-group ">
                            <input formControlName="StockQuantiy" id="stockQuantiy{{i}}" value="0" (click)="setSelectionRange($event)" class="form-control number-field" 
                            type="text" placeholder="Stock Quantiy" (change)="getItemPrice($event)">
                        </div>                      
                    </div>                  
                </div>
                <div class="col-md-1" >
                <!-- <label class="col-form-label" for="unitPrice{{i}}">Unit Price</label> -->
                    <div class="controls">
                        <div class="input-prepend input-group ">
                            <input formControlName="UnitPrice" id="unitPrice{{i}}" value="0" (click)="setSelectionRange($event)" class="form-control number-field" 
                            type="text" placeholder="Unit Price" (change)="getItemPrice($event)">
                        </div>                      
                    </div>                  
                </div>
                <div class="col-md-1" >
                <!-- <label class="col-form-label" for="unitPrice{{i}}">Unit Price</label> -->
                    <div class="controls">
                        <div class="input-prepend input-group ">
                            <input formControlName="RegularPrice" id="regularPrice{{i}}" value="0" (click)="setSelectionRange($event)" class="form-control number-field" 
                            type="text" placeholder="Regular Price" (change)="getItemPrice($event)">
                        </div>                      
                    </div>                  
                </div>
                <div class="col-md-1" >
                <!-- <label class="col-form-label" for="unitPrice{{i}}">Unit Price</label> -->
                    <div class="controls">
                        <div class="input-prepend input-group ">
                            <input formControlName="SellPrice" id="sellPrice{{i}}" value="0" (click)="setSelectionRange($event)" class="form-control number-field" 
                            type="text" placeholder="Sell Price" (change)="getItemPrice($event)">
                        </div>                      
                    </div>                  
                </div>
                <div class="col-md-2" >
                <!-- <label class="col-form-label" for="totalPrice{{i}}">Total Price</label> -->
                    <div class="controls">
                        <div class="input-prepend input-group ">
                            <input formControlName="TotalPrice" id="totalPrice{{i}}" value="" class="form-control number-field" 
                            type="text" placeholder="Total Price" disabled>
                        </div>                      
                    </div>                  
                </div>
                <div class="col-md-1" >
                <!-- <label class="col-form-label" for="reorderPoint{{i}}">Reorder Point</label> -->
                    <div class="controls">
                        <div class="input-prepend input-group ">
                        <input formControlName="ReorderPoint" id="reorderPoint{{i}}" value="0" class="form-control number-field" 
                        type="text" placeholder="">
                        </div>                      
                    </div>                  
                </div>
                
                <div class="col-md-1 mt-2">
                <button type="button" *ngIf="addPurchaseForm.controls.purchaseRows['controls'].length > 1" (click)="deleteRow(i)" class="btn btn-danger btn-sm"><i class="fa fa-trash" aria-hidden="true"></i></button>
                </div>
            </div>                
        </div>
        <div class="button-align mt-5">
            <button class="btn btn-primary btn-lg" type="submit">Submit</button>
        </div>
        </form>
    </div>
    </div>
</div>
</div>
  