

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from '../../../services/auth/auth.service';
import { RegistrationService } from '../../../services/user/registration';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { MustMatch } from '../../../_helpers/must-match.validator';
import { BranchService } from '../../../services/branch/branch.service';
// import { UserService } from 'src/app/view/services/user/user.service';
import { UserService } from '../../../services/user/user.service';


@Component({
  selector: 'app-super-admin',
  templateUrl: './super-admin.component.html',
  styleUrls: ['./super-admin.component.scss']
})
export class SuperAdminComponent implements OnInit {
  userGroup: any[] = [];
  groupId = '0';
  isAdminReg = true;
  branchAdmin: Array<any>;
  branchData: Array<any>;
  submitted = false;
  SuperAdminregForm: FormGroup;
  SuperAdminForm: FormGroup;
  id = 0;

  SuperAdminGroupId = '200603010000000005';

  constructor(private formBuilder: FormBuilder,private toastrService: ToastrService,
              private activeRoute: ActivatedRoute,
              private userService: UserService,private router: Router,
              private authService: AuthService,private branchService: BranchService)
    {
      this.getBranchList();
    }

  ngOnInit(): void {

    // this.reg.getUserGroup().subscribe((data: any) => {
    //   this.userGroup = data;
    //   console.log(this.userGroup);
    //   //this.groupId = (this.router.url === '/login') ? data.filter(s => s.userGroupTitle === 'Seller')[0].userGroupId : '0';
    //   //this.isAdminReg =  (this.router.url === '/login') ? false : true;
    // });

    const id = +this.activeRoute.snapshot.paramMap.get('id');
    if (id !== null){
      this.loadBranchAdmin(id);
      }

    this.adminForm();
  }

  loadBranchAdmin(id): any{
    // tslint:disable-next-line: no-debugger
   //debugger;
   this.branchAdmin = new Array<any>();
   this.userService.getBranchAdminList().subscribe((data: any[]) => {
    this.branchAdmin = data;
      if (id !== null) {
        this.SetData(id);
      }
    });
   }

   SetData(branchAdminId): any {
     //debugger;
    this.branchAdmin.find(x => {
      if (+x.userId === +branchAdminId ) {
        this.SuperAdminregForm.setValue({
          UserName: x.userLoginName,
          BranchId: x.branchId,
          StatusId: x.userActive,
          UserPassword: x.userPassword,
          confirmPassword: x.userPassword,
          UserGroupId: x.userGroupId
        });
       }
    });
  }

  getBranchList(){
    this.branchData = new Array<any>();
    this.branchService.getBranchList()
                  .subscribe(
                    (data: any) => {
                      this.branchData = data;
                    },
                    error => {console.log(error); }
                    );
  }

  adminForm(): any {
    this.SuperAdminregForm = this.formBuilder.group({
      UserName: ['', [Validators.required]],
      BranchId: ['', [Validators.required]],
      StatusId: [true, [Validators.required]],
      UserPassword:['', Validators.required],
      confirmPassword:['', Validators.required],
      UserGroupId:[true, Validators.required]
    }, {
      validator: MustMatch('UserPassword', 'confirmPassword')
  });
  }
   // tslint:disable-next-line: typedef
   get f(){
    return this.SuperAdminregForm.controls;
  }

  onSubmit(): any{
    //debugger;
    this.submitted = true;
     // stop here if form is invalid
    if (this.SuperAdminregForm.invalid) {
         return;
     }
     const branchAdminId = +this.activeRoute.snapshot.paramMap.get('id');
     if (branchAdminId !== null && branchAdminId !== 0) {
      this.SuperAdminForm= this.formBuilder.group({
        userId: branchAdminId,
        userGroupId: [this.SuperAdminGroupId],
        userPassword: this.SuperAdminregForm.get('UserPassword').value,
        userLoginName: this.SuperAdminregForm.get('UserName').value,
        branchId: +this.SuperAdminregForm.get('BranchId').value,
        userActive: this.SuperAdminregForm.get('StatusId').value,
        UserGroupId: this.SuperAdminregForm.get('UserGroupId').value
      });

      this.authService.adminUpdate(this.SuperAdminForm.value)
      .subscribe(data => {
        this.toastrService.success('Data Update Successfully.');
        this.adminForm();
        this.router.navigate(['/branch-admin-list']);
      },
      error => {
      this.toastrService.error(error, 'Update Failed');
      });
     }
     else{
      this.SuperAdminForm= this.formBuilder.group({
        userGroupId: [this.SuperAdminGroupId],
        userPassword: this.SuperAdminregForm.get('UserPassword').value,
        userLoginName: this.SuperAdminregForm.get('UserName').value,
        branchId: +this.SuperAdminregForm.get('BranchId').value,
        userActive: this.SuperAdminregForm.get('StatusId').value,
        UserGroupId: this.SuperAdminregForm.get('UserGroupId').value
      });

      this.authService.signup(this.SuperAdminForm.value)
      .subscribe(data => {
        this.toastrService.success('Data Save Successfully.');
        this.adminForm();
        this.router.navigate(['/branch-admin-list']);
      },
      error => {
      this.toastrService.error(error, 'Admin Register Failed');
      });
     }
  }

}
