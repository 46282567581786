
    <div class="app-title">
      <div>
        <h1><i class="fa fa-th-list"></i> Product</h1>
        <!-- <p>Basic bootstrap tables</p> -->
      </div>
      <ul class="app-breadcrumb breadcrumb">
        <li class="breadcrumb-item"><i class="fa fa-home fa-lg"></i></li>
        <li class="breadcrumb-item">Product</li>
        <li class="breadcrumb-item active"><a href="#" routerLink="/product-add">Add Product</a></li>
      </ul>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="tile">
             
              <div class="tile-title-w-btn">
                <h3 class="title">Product List</h3>
                <p><a class="btn btn-primary icon-btn" routerLink="/product-update"><i class="fa fa-plus"></i>Add Product</a></p>
              </div>
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>Serial</th>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Category Name</th>
                    <th>Brand Name</th>
                    <th>Stock Quantity</th>
                    <th>Regular Price</th>
                    <th>Sell Price</th>
                    <th>Shop Name</th>
                    <!-- <th>Low Stock Order</th> -->
                    <th>Photo</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody id="tr_with_No_data" >
                  <tr style="text-align: center;">
                    <td colspan="10">No Data Found!</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr *ngFor="let item of data; let i = index" id="tr_{{item.productId}}">
                    <td>{{i+1}}</td>
                    <td>{{item.productName}}</td>
                    <td>{{item.productDescription}}</td>
                    <td>{{item.categoryName}}</td>
                    <td>{{item.brandName}}</td>
                    <td>{{item.quantity}}</td>
                    <td>{{item.regularPrice}}</td>
                    <td>{{item.sellPrice}}</td>
                    <td>{{item.shopName}}</td>
                    <!-- <td>{{item.lowStockOrder}}</td> -->
                    <td><img src="{{item.photoUrl}}" width="100px" height="100px"/> </td>
                    <td>
                      <button class="btn btn-success btn-sm mr-2" (click)="edit(item.productId)"><i class="fa fa-edit"></i></button>
                      <button class="btn btn-danger btn-sm" (click)="delete(item.productId)"><i class="fa fa-trash"></i></button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          </div>    
