
    <div class="app-title">
      <div>
        <h1><i class="fa fa-th-list"></i> Supplier</h1>
        <!-- <p>Supplier list</p> -->
      </div>
      <ul class="app-breadcrumb breadcrumb">
        <li class="breadcrumb-item"><i class="fa fa-home fa-lg"></i></li>
        <li class="breadcrumb-item">Supplier</li>
        <li class="breadcrumb-item active"><a routerLink="/supplier-add">Add Supplier</a></li>
      </ul>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="tile">
              
              <div class="tile-title-w-btn">
                <h3 class="title">Supplier List</h3>
                <p><a class="btn btn-primary icon-btn" routerLink="/supplier-add"><i class="fa fa-plus"></i>Add Supplier</a></p>
              </div>
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Mobile</th>
                      <th>Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>Mark Otto</td>
                      <td>1234567889</td>
                      <td>test@gmail.com</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>Jacob Thornton</td>
                      <td>017211545554</td>
                      <td>kacob@gmail.comt</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Larry Bird</td>
                      <td>011998256466</td>
                      <td>ales@twitter.com</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>Jacob Thornton</td>
                      <td>017126464646</td>
                      <td>netr@fat.com</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        
    </div>
