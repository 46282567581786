import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Common } from '../common/common';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(private http: HttpClient) { }
  // tslint:disable-next-line: typedef
  public AddCategory(data: any) {
    return this.http.post<any>(Common.baseUrl + '/category/EntryCategory', data);
  }
  // tslint:disable-next-line: typedef
  public UpdateCategory(data: any) {
    return this.http.post<any>(Common.baseUrl + '/category/EditCategory/', data);
  }
  // tslint:disable-next-line: typedef
  public getCategoryList() {
    return this.http.get<any>(Common.baseUrl + '/category/GetCategory');
  }

  // tslint:disable-next-line: typedef
  public getCategory(data: any) {
    return this.http.get<any>(Common.baseUrl + '/category/getcategory/' + data);
  }

  // tslint:disable-next-line: typedef
  public DeleteCategory(data: string) {
    return this.http.delete<any>(Common.baseUrl + '/category/DeleteCategory/' + data);
  }
}
