
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router,ActivatedRoute } from '@angular/router';
import { OrderService } from '../../../services/order/order.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormGroup, FormBuilder } from '@angular/forms';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-deliver-order-details',
  templateUrl: './deliver-order-details.component.html',
  styleUrls: ['./deliver-order-details.component.scss']
})
export class DeliverOrderDetailsComponent implements OnInit {

  data: Array<any>;
  orderData: any;
  pendingorder: FormGroup;
  detailsValue = [];

  constructor(
    private orderService: OrderService,
    private router: Router,
    private http: HttpClient,
    private SpinnerService: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    ) {
      this. GetPendingOrder();
      this.GetPendingOrderDetails();
   }

  ngOnInit(): void {
  }
  GetPendingOrder()
  {
    const id = +this.activatedRoute.snapshot.paramMap.get('id');
    this.pendingorder = this.fb.group({
      orderId: id,
    });
    this.orderService.getPendingOrderbyid(this.pendingorder.value).subscribe(
     data1 => {
       this.orderData = data1;
       console.log(data1);
     },
     error => {console.log(error);
    }
     );
  }

  GetPendingOrderDetails()
    {
      const id = +this.activatedRoute.snapshot.paramMap.get('id');
      this.pendingorder = this.fb.group({
        orderId: id,
      });
      this.SpinnerService.show();
      this.orderService.getPendingOrderdetails(this.pendingorder.value)
     .subscribe(
       data => {
         this.data = data;
         this.SpinnerService.hide(); 
       },
       error => {console.log(error);
                 this.SpinnerService.hide(); 
      }
       );
    }

}


