

<div class="app-sidebar__overlay" data-toggle="sidebar"></div>
<aside class="app-sidebar">

  <ul class="app-menu" id="ulsubid" *ngIf="userGroupType === 'SU'">
    <li><a class="app-menu__item active" href="javascript:void(0)" routerLink="/dashboard" ><i class="app-menu__icon fa fa-dashboard"></i><span class="app-menu__label">Dashboard</span></a></li>
    <li><a class="app-menu__item active" href="javascript:void(0)" routerLink="/home"><i class="app-menu__icon fa fa-bar-chart-o"></i><span class="app-menu__label">Chart</span></a></li>
    <!-- <li><a class="app-menu__item active" href="javascript:void(0)" routerLink="/branch"><i class="app-menu__icon fa fa-bar-chart-o"></i><span class="app-menu__label">Dashboard</span></a></li> -->

    <li class="treeview is-expanded"><a class="app-menu__item" href="javascript:void(0)" data-toggle="treeview"><i class="app-menu__icon fa fa-braille"></i><span class="app-menu__label">Branch</span><i class="treeview-indicator fa fa-angle-right"></i></a>
      <ul class="treeview-menu">
        <li><a class="treeview-item" routerLink="/branch-add"><i class="icon fa fa-circle-o"></i> Branch Add</a></li>
        <li><a class="treeview-item" routerLink="/branch-list"><i class="icon fa fa-circle-o"></i> Branch List</a></li>
      </ul>
    </li>

    <li class="treeview is-expanded"><a class="app-menu__item" href="javascript:void(0)" data-toggle="treeview"><i class="app-menu__icon fa fa-paper-plane-o"></i><span class="app-menu__label">Manage Customer</span><i class="treeview-indicator fa fa-angle-right"></i></a>
      <ul class="treeview-menu">
        <li><a class="treeview-item" routerLink="/customer-list"><i class="icon fa fa-circle-o"></i> Customer List</a></li>
      </ul>
    </li>

    <li class="treeview is-expanded"><a class="app-menu__item" href="javascript:void(0)" data-toggle="treeview"><i class="app-menu__icon fa fa-user"></i><span class="app-menu__label">Branch Admin</span><i class="treeview-indicator fa fa-angle-right"></i></a>
      <ul class="treeview-menu">
        <li><a class="treeview-item" routerLink="/superadmin-reg"><i class="icon fa fa-circle-o"></i> Admin Registration</a></li>
        <li><a class="treeview-item" routerLink="/branch-admin-list"><i class="icon fa fa-circle-o"></i> Admin List</a></li>
      </ul>
    </li>

    <li class="treeview is-expanded"><a class="app-menu__item" href="javascript:void(0)" data-toggle="treeview"><i class="app-menu__icon fa fa-file"></i><span class="app-menu__label">Reports</span><i class="treeview-indicator fa fa-angle-right"></i></a>
      <ul class="treeview-menu">
        <li><a class="treeview-item" routerLink="/report"><i class="icon fa fa-circle-o"></i> Branch Data</a></li>
      </ul>
    </li>

  </ul>

  <ul class="app-menu" id="ulsubid" *ngIf="userGroupType === 'A'">
    <li><a class="app-menu__item active" href="javascript:void(0)" routerLink="/dashboard" ><i class="app-menu__icon fa fa-dashboard"></i><span class="app-menu__label">Dashboard</span></a></li>
    <li><a class="app-menu__item active" href="javascript:void(0)" routerLink="/home"><i class="app-menu__icon fa fa-bar-chart-o"></i><span class="app-menu__label">Chart</span></a></li>
    <!-- <li><a class="app-menu__item active" href="javascript:void(0)" routerLink="/branch"><i class="app-menu__icon fa fa-bar-chart-o"></i><span class="app-menu__label">Dashboard</span></a></li> -->

    <li class="treeview is-expanded"><a class="app-menu__item" href="javascript:void(0)" data-toggle="treeview"><i class="app-menu__icon fa fa-braille"></i><span class="app-menu__label">Branch</span><i class="treeview-indicator fa fa-angle-right"></i></a>
      <ul class="treeview-menu">
        <!-- <li><a class="treeview-item" routerLink="/branch-add"><i class="icon fa fa-circle-o"></i> Branch Add</a></li> -->
        <li><a class="treeview-item" routerLink="/branch-list"><i class="icon fa fa-circle-o"></i> Branch List</a></li>
      </ul>
    </li>
    <li class="treeview is-expanded"><a class="app-menu__item" href="javascript:void(0)" data-toggle="treeview"><i class="app-menu__icon fa fa-user"></i><span class="app-menu__label">Branch Admin</span><i class="treeview-indicator fa fa-angle-right"></i></a>
      <ul class="treeview-menu">
        <!-- <li><a class="treeview-item" routerLink="/superadmin-reg"><i class="icon fa fa-circle-o"></i> Admin Registration</a></li> -->
        <li><a class="treeview-item" routerLink="/branch-admin-list"><i class="icon fa fa-circle-o"></i> Admin List</a></li>
      </ul>
    </li>
  </ul>

  <ul class="app-menu" id="ulsubid" *ngIf="userGroupType === 'F'">
    <li><a class="app-menu__item active" href="javascript:void(0)" routerLink="/dashboard" ><i class="app-menu__icon fa fa-dashboard"></i><span class="app-menu__label">Dashboard</span></a></li>
    <li><a class="app-menu__item active" href="javascript:void(0)" routerLink="/branch/{{branchId}}"><i class="app-menu__icon fa fa-bar-chart-o"></i><span class="app-menu__label">Chart</span></a></li>
  </ul>

  <ul class="app-menu" id="ulsubid" *ngIf="userGroupType === 'O'">
    <li><a class="app-menu__item active" href="javascript:void(0)" routerLink="/outlet" ><i class="app-menu__icon fa fa-dashboard"></i><span class="app-menu__label">Dashboard</span></a></li>
  </ul>


</aside>



