import { Component, OnInit, SecurityContext } from '@angular/core';
import { ProductService } from '../../../services/product/product.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Common } from '../../../services/common/common';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit {
  data: Array<any>;
  ProductImagePath: any;

  constructor(private productService: ProductService, private toastrService: ToastrService,
              private router: Router, private sanitizer: DomSanitizer)
              {
                // tslint:disable-next-line: no-debugger
                //debugger;
                this.data = new Array<any>();
                this.productService.getProductList()
                  .subscribe(
                    (data: any) => {
                      this.data = data;
                      this.data.forEach(item => item.photoUrl = Common.baseUrl + '/images/' + item.photoUrl);
                      if (data.length === 0) {
                        (document.getElementById('tr_with_data') as HTMLTableElement).style.display = 'none';
                      }
                      else{
                        (document.getElementById('tr_with_No_data') as HTMLTableElement).style.display = 'none';
                      }
                      console.log(this.data);
                    },
                    error => {console.log(error); }
                    );
              }

  ngOnInit(): void {
  }

  // tslint:disable-next-line: typedef
  edit(id: any){
    // tslint:disable-next-line: no-debugger
    //debugger;
    this.router.navigate(['/product-update/' + id]);
  }

  delete(id: any): any{
    // this.brandService.DeleteBrand(id).subscribe(success => {
    //   this.toastrService.success(success.message, 'Data Delete Successfully.');
    //   this.brandService.getBrandList()
    //   .subscribe(
    //     (data: any) => {
    //       this.data = data;
    //       // tslint:disable-next-line: max-line-length
    // tslint:disable-next-line: max-line-length
    //       this.data.forEach(item => item.brandImage = this.sanitizer.sanitize(SecurityContext.HTML, this.sanitizer.bypassSecurityTrustHtml(`data:image/jpeg;base64,${item.brandImage}`)));
    //       console.log(this.data);
    //     },
    //     error => {console.log(error); }
    //     );
    //   });
    }
}
