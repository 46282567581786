

    <div class="app-title">
        <div>
          <h1><i class="fa fa-edit"></i>Manage User</h1>
        </div>
        <ul class="app-breadcrumb breadcrumb">
          <li class="breadcrumb-item"><i class="fa fa-home fa-lg"></i></li>
          <li class="breadcrumb-item">User</li>
          <li class="breadcrumb-item"><a routerLink="/user-list">User list</a></li>
        </ul>
      </div>
      <div class="row">
        <div>
          <p>Form value: {{addUserForm.value | json}}</p>
          <p>Form status: {{addUserForm.status | json}}</p>
        </div>
          <div class="col-md-12">
              <div class="tile">
                <h3 class="tile-title">Add User</h3>
                <div class="tile-body">
                  <form  [formGroup]="addUserForm" (ngSubmit)="onSubmit()"  class="form-horizontal">
                    <div class="form-group row">
                      <label class="control-label col-md-2">Name</label>
                      <div class="col-md-8">
                        <input class="form-control"  formControlName="UserName" type="text" placeholder="Enter full name">
                        <div *ngIf="f.UserName.touched && f.UserName.invalid" class="alert alert-danger">
                          <div *ngIf="f.UserName.errors.required">Name is required.</div>
                          <div *ngIf="f.UserName.errors.minlength">Name should be 4 character.</div>
                          <div *ngIf="f.UserName.errors.pattern">Only character allowed.</div>
                      </div>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="control-label col-md-2">Email</label>
                      <div class="col-md-8">
                        <input class="form-control col-md-8" formControlName="Email" type="email" placeholder="Enter email address">
                      </div>
                    </div>
                    <div class="form-group row">
                        <label class="control-label col-md-2">Designation</label>
                        <div class="col-md-8">
                          <input class="form-control col-md-8" formControlName="Designation" type="text"  placeholder="Enter Designation">
                        </div>
                      </div>
                    <div class="form-group row">
                      <label class="control-label col-md-2">Mobile</label>
                      <div class="col-md-8">
                        <input class="form-control col-md-8"  formControlName="Mobileno" type="number" pattern="[0-9]" placeholder="Enter mobile address">
                      </div>
                    </div>
                    <div class="form-group row">
                        <label class="control-label col-md-2">Password</label>
                        <div class="col-md-8">
                          <input class="form-control col-md-8"  formControlName="Password" type="password"  placeholder="Enter Password">
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="control-label col-md-2">Confirm Password</label>
                        <div class="col-md-8">
                          <input class="form-control col-md-8" type="password"  placeholder="Enter confirm password">
                        </div>
                      </div>
                    
                    <div class="form-group row">
                      <label class="control-label col-md-2">Address</label>
                      <div class="col-md-8">
                        <textarea class="form-control" rows="4"  formControlName="Address" placeholder="Enter your address"></textarea>
                      </div>
                    </div>
                   
                    <div class="form-group row">
                        <label class="control-label col-md-2">Select User Role</label>
                        <div class="col-md-8">
                            <select class="form-control"  formControlName ="UserRole" id="ddlCategory">
                              <option selected value="">Select </option>
                                <option>Super Admin</option>
                                <option>Admin</option>
                                <option>Genaral</option>
                              </select>
                        </div>
                      </div>
                      <button class="btn btn-primary" type="submit" >Submit</button>
                      <!-- <div class="tile-footer">
                        <div class="row">
                          <div class="col-md-8 col-md-offset-3">
                            <button class="btn btn-primary" type="button" (ngSubmit)="onSubmit()">Submit</button>
                           
                          </div>
                        </div>
                      </div> -->
                  </form>
                </div>
                
              </div>
            </div>
          </div>
  
    