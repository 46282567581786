import { Component, IterableDiffers, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { OrderService } from '../../../services/order/order.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
// import Swal from 'sweetalert2/dist/sweetalert2.js';
// import { DataTableModule } from 'ng-angular9-datatable';

@Component({
  selector: 'app-pending-orderlist',
  templateUrl: './pending-orderlist.component.html',
  styleUrls: ['./pending-orderlist.component.scss']
})
export class PendingOrderlistComponent implements OnInit {

  data: Array<any>;
  PendingorderData: any;
  posts;
  categoris: any[] = [];
  pendingorder: FormGroup;
  public iterableDiffer = null;

  constructor(
    private orderService: OrderService,
    private router: Router,
    private http: HttpClient,
    private fb: FormBuilder,
    private SpinnerService: NgxSpinnerService,
    private iterableDiffers: IterableDiffers
    ) {
      this.iterableDiffer = iterableDiffers.find([]).create(null);
   }

  //  public data1 = [
  //   {name: 'Ajay', email: 'therichpost@gmail.com', website: 'therichpost.com'},
  //   {name: 'Jas', email: 'therichpost@gmail.com', website: 'therichpost.com'},
  //   {name: 'therichpost', email: 'therichpost@gmail.com', website: 'therichpost.com'},
  //   {name: 'therichpost', email: 'therichpost@gmail.com', website: 'therichpost.com'},
  //   {name: 'Jas', email: 'therichpost@gmail.com', website: 'therichpost.com'},
  //   {name: 'therichpost', email: 'therichpost@gmail.com', website: 'therichpost.com'},
  //   {name: 'therichpost', email: 'therichpost@gmail.com', website: 'therichpost.com'},
  //   {name: 'Jas', email: 'therichpost@gmail.com', website: 'therichpost.com'},
  //   {name: 'therichpost', email: 'therichpost@gmail.com', website: 'therichpost.com'},
  //   {name: 'therichpost', email: 'therichpost@gmail.com', website: 'therichpost.com'},
  // ];

dtOptions: any = {};

  ngOnInit() {
    this.GetPendingOrderList();
  }

// tslint:disable-next-line: use-lifecycle-interface
ngDoCheck() {
  const lookup = {};
  const items = this.PendingorderData.ProductArray;
  const changes = this.iterableDiffer.diff(items);
  if (changes) {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
    lengthMenu : [5, 10, 25],
      processing: true
    };
  }
}
  GetPendingOrderList()
    {
      this.pendingorder = this.fb.group({
        orderStatus: 'P',
      });
      this.SpinnerService.show();
      this.orderService.getOrderListdata(this.pendingorder.value)
     .subscribe(
       data => {
         this.PendingorderData = data;
         this.SpinnerService.hide();
       },

       error => {console.log(error);
                 this.SpinnerService.hide();
      }
       );

    }

    orderdetail(orderId){
      this.router.navigate(['/pending-order-details/' + orderId]);
    }
    Chekout(orderId) {
      Swal.fire({
        title: 'Are you sure want to submit?',
        text: 'You will not be able to recover this file!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Submit it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        this.pendingorder = this.fb.group({
          orderId
        });
        if (result.value) {
           this.orderService.submitorderchkout(this.pendingorder.value).subscribe(
            data => {
               Swal.fire(
                'Submited!',
              //  'Your imaginary file has been deleted.',
                'success'
              );
              //debugger;
               this.GetPendingOrderList();
               },
               error => {console.log(error); }
             );

        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your imaginary file is safe :)',
            'error'
          );
        }
      });
  }
}

