
    <div class="app-title">
      <div>
        <h1><i class="fa fa-edit"></i> Category</h1>
        <!-- <p>Sample forms</p> -->
      </div>
      <ul class="app-breadcrumb breadcrumb">
        <li class="breadcrumb-item"><i class="fa fa-home fa-lg"></i></li>
        <li class="breadcrumb-item">Category</li>
        <li class="breadcrumb-item"><a routerLink="/category-list">Category List</a></li>
      </ul>
    </div>
    
    <div class="row">
      <div style="display: none;">
        <p>Form value: {{entryForm.value | json}}</p>
        <p>Form status: {{entryForm.status | json}}</p>
      </div>
      <div class="col-md-12">
        <div class="tile">
          <h3 class="tile-title">Add Category</h3>
          <div class="tile-body">
            <form [formGroup]="entryForm" (ngSubmit)="onSubmit()" class="form-horizontal">

              <div class="form-group row">
                <label class="control-label col-md-2">Name</label>
                <div class="col-md-8">
                  <input 
                    formControlName="Name"
                    id="Name" 
                    type="text"
                    placeholder="Enter Category Name" 
                    class="form-control">
                  <div *ngIf="f.Name.touched && f.Name.invalid" class="alert alert-danger">
                      <div *ngIf="f.Name.errors.required">Name is required.</div>
                      <div *ngIf="f.Name.errors.minlength">Name should be 3 character.</div>
                      <div *ngIf="f.Name.errors.pattern">Only character allowed.</div>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label class="control-label col-md-2"> Parent</label>
                <div class="col-md-8">
                  <select name="Parent" id="Parent"  class="form-control">
                    <option value="0" selected>Select A Parent</option>
                    <option *ngFor='let m of category' value="{{m.id}}" [selected]="m.id === parentId">{{m.name}}</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                  <label for="file" class="control-label col-md-2">Category Icon</label>
                  <div class="col-md-5">
                    <input 
                      formControlName="file"
                      id="file" 
                      type="file" 
                      class="form-control"
                      accept="image/*"
                      (change)="onFileChange($event)">
                    <div *ngIf="f.file.touched && f.file.invalid" class="alert alert-danger">
                        <div *ngIf="f.file.errors.required">Image is required.</div>
                    </div>
                  </div>
                  <!-- <div class="col-md-5 mt-5">
                    <img [src]="image" id="showImage" height="150" width="150" style="margin: 3px;"> 
                  </div> -->


                  <div class="img-container" ng-repeat="file in imagefinaldata">
                    <img height=200 width=250 id="showImage"  data-ng-if="showImage !== ''" [src]="image" class="imgResponsiveMax" alt="" />
                    <img class="close" src="http://wecision.com/enterprise/images/icons/closeIcon.png" />
                  </div>
                  <!-- <div class="form-group row">
                    <label for="file" class="control-label col-md-2"></label>
                    
                    <div class="col-md-3" *ngFor='let d of imgData'>
                        <img [src]="d.url" height="100">
                        <span (click)="imgDelete(d.id)" style="color: red;position: absolute;top: -10px;"><i class="fa fa-times-circle"></i></span>
                    </div>
                </div> -->
              </div>
            
             
              <div class="form-group row">
                <label class="control-label col-md-2"> Is Home Page</label>
                <div class="col-md-1">
                 <input type="checkbox" name="isHome" id="isHome"/>
                </div>
                <label class="control-label col-md-2"> Is Active</label>
                <div class="col-md-1">
                 <input type="checkbox" name="isActive" id="isActive" checked/>
                </div>
              </div>
              <div class="row">
              <div class="col-7"></div>
              <div class="col-2">
                <span class="btn btn-primary" (click)="Clear()">Clear</span>
              </div>
              <div class="col-2" style="float:right;position: relative;">
                <button id="btnSubmit" class="btn btn-primary" type="submit">Submit</button>
              </div>
              <div class="col-1"></div>
            </div>
            </form>
          </div>
        </div>
      </div>
    </div>