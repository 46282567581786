

import { Component, OnInit, SecurityContext } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
// import { BrandService } from '../../../services/brand/brand.service';
import { SellerService } from '../../../services/seller/seller.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Common } from '../../../services/common/common';
import { EncryptionDescryptionService } from '../../../services/auth/encryption-descryption.service';
import { AdminProfileService } from '../../../services/admin/admin-profile.service';

@Component({
  selector: 'app-seller',
  templateUrl: './seller.component.html',
  styleUrls: ['./seller.component.scss']
})
export class SellerComponent implements OnInit {
  submitted = false;

  SellerForm: FormGroup;
  SellerFormSubmit: FormGroup;
  shopData: Array<any>;
  brand: any[] = [];
  id = 0;
 // bandImage: any;
  images = [];

  shopImage: any;
  ShopImage: any;
  currentField: any;
  ShopImagePath: any;
  imageurl: any;
  SuperAdminGroupId: '13052901001002';
  UserGroupId = '200705180920000001';

  public Editor = ClassicEditor;

  constructor(private formBuilder: FormBuilder, private toastrService: ToastrService,
              private activeRoute: ActivatedRoute, private sellerService: SellerService,
              private activatedRoute: ActivatedRoute, private router: Router,
              private encryptObj: EncryptionDescryptionService, private adminProfileService: AdminProfileService)
              {
              }


  ngOnInit(): void {
    const id = this.activeRoute.snapshot.paramMap.get('id');
    if (id !== null){
      this.loadSeller(id);
      }
    this.sellerForm();
  }

 loadSeller(sellerId): any {
    this.sellerService.getSeller(sellerId).subscribe((data: any[]) => {
    this.brand = data;
    this.SetData(this.brand);
    });
  }

  SetData(data): any {
    this.SellerForm.patchValue({
      SellerName: data.sellerName,
      SellerEmail: data.sellerEmail,
      SellerMobile: data.sellerMobile,
      ShopName: data.shopName,
      SellerAddress: data.sellerAddress,
      ShopDescription: data.shopDescription,
      ShopLocation: data.shopLocation,
      ShopImagePath: Common.baseUrl + '/images/' + data.shopImageName,
      SellerDescription: data.sellerDescription
    });
  }

  sellerForm(): any {
    this.SellerForm = this.formBuilder.group({
      SellerName: new FormControl('', [Validators.required, Validators.pattern('^[+]?[0-9a-zA-Z .-]*$'), Validators.maxLength(150)]),
      UserName: ['', [Validators.required]],
      SellerEmail: ['', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]],
      SellerMobile: ['', [Validators.required, Validators.pattern('^[+]?[0-9]*$')]],
      ShopName: ['', [Validators.required]],
      SellerAddress: ['', Validators.required],
      ShopDescription: ['', Validators.required],
      ShopLocation: ['', Validators.required],
      UserPassword: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(12)]],
      ConfirmPassword: ['', Validators.required],
      file: new FormControl('', [Validators.required]),
      fileSource: new FormControl('', [Validators.required]),
      ShopImageName: [''],
      ShopImage: [this.ShopImage],
      ShopImagePath: ['']
    }, {validators: this.passwordMatchValidator});
  }

  passwordMatchValidator(g: FormGroup): any {
    return g.get('UserPassword').value === g.get('ConfirmPassword').value ? null : { mismatch : true};
  }

  get SellerEmail(): any {
    return this.SellerForm.get('SellerEmail');
  }

  get SellerMobile(): any {
    return this.SellerForm.get('SellerMobile');
  }

  get f(): any {
    return this.SellerForm.controls;
  }

  onFileChange(event, field: any): any {
    // tslint:disable-next-line: no-debugger
    //debugger;
    this.currentField = field;
    if (event.target.files && event.target.files[0]) {
        const filesAmount = event.target.files.length;
        for (let i = 0; i < filesAmount; i++) {
            const reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]); // read file as data url
            const file = event.target.files[0];
            reader.onloadend = (e) => {
              console.log(event.target.result);
              if (this.currentField === 1) {
                this.ShopImagePath = e.target.result as string;
                this.SellerForm.patchValue({
                  ShopImageName: file.name,
                });
                this.handleChanges(event.target.files[0]);

              }
              this.images.push(event.target.result as string);

              this.SellerForm.patchValue({
                  fileSource: this.images
                });
            };
        }
    }
  }


  handleChanges(files: any): any
  {
      const file = files;
      const pattern = /image-*/;
      const reader = new FileReader();
      if (!file.type.match(pattern)) {
        this.toastrService.error('Invalid Image Format!');
        return;
      }
      reader.onloadend = this._handleReaderLoaded.bind(this);
      reader.readAsDataURL(file);
  }

  _handleReaderLoaded(e): any {
    const reader = e.target;
    const base64result = reader.result.substr(reader.result.indexOf(',') + 1);
    this.ShopImage = base64result;
    console.log(this.ShopImage);
  }

  onSubmit(): any {
    // tslint:disable-next-line: no-debugger
    //debugger;
    this.submitted = true;
    if (this.SellerForm.invalid) {
         return;
     }
    const datas = this.adminProfileService.GetCustomer();
 // if (id !== null){
    const shopId = +this.activeRoute.snapshot.paramMap.get('id');
    if (shopId !== null && shopId !== 0) {
      this.SellerFormSubmit = this.formBuilder.group({
        userGroupId: [this.UserGroupId],
        userFullName: [this.SellerForm.get('SellerName').value],
        userEmail: [this.SellerForm.get('SellerEmail').value],
        userMobile: [this.SellerForm.get('SellerMobile').value],
        userLoginName: [this.SellerForm.get('UserName').value],
        sellerAddress: [this.SellerForm.get('SellerAddress').value],
        shopName: [this.SellerForm.get('ShopName').value],
        shopDescription: [this.SellerForm.get('ShopDescription').value],
        shopLocation: [this.SellerForm.get('ShopLocation').value],
        shopImageName: [this.SellerForm.get('ShopImageName').value],
        shopImage: [this.ShopImage],
        UPDATEBY: [localStorage.getItem('userId')],
        sellerId: shopId,
      });
      console.log(this.SellerForm.value);
      this.sellerService.updateSeller(this.SellerFormSubmit.value)
        .subscribe(res => {
          console.log(res);
          this.toastrService.success(res.message, 'Data Save Successfully.');
          this.sellerForm();
          this.router.navigate(['/seller-list']);
        },
        error => {
          console.log(error);
          this.toastrService.error(error, 'Error!');
        });
    }
    else {
      this.SellerFormSubmit = this.formBuilder.group({
        userGroupId: [this.UserGroupId],
        userFullName: [this.SellerForm.get('SellerName').value],
        userLoginName: [this.SellerForm.get('UserName').value],
        userEmail: [this.SellerForm.get('SellerEmail').value],
        userMobile: [this.SellerForm.get('SellerMobile').value],
        sellerAddress: [this.SellerForm.get('SellerAddress').value],
        shopName: [this.SellerForm.get('ShopName').value],
        shopDescription: [this.SellerForm.get('ShopDescription').value],
        shopLocation: [this.SellerForm.get('ShopLocation').value],
        shopImageName: [this.SellerForm.get('ShopImageName').value],
        userPassword: this.encryptObj.encryptData(this.SellerForm.get('UserPassword').value),
        shopImage: [this.ShopImage],
        createdBy: [datas.userGroupId],
      });
      console.log(this.SellerFormSubmit.value);
      this.sellerService.SellerRegistration(this.SellerFormSubmit.value)
        .subscribe(res => {
          console.log(res);
          this.toastrService.success(res.message, 'Data Save Successfully.');
          this.sellerForm();
          this.router.navigate(['/seller-list']);
        },
        error => {
          console.log(error);
          this.toastrService.error(error, 'Error!');
        });
    }
 // }

}
}
