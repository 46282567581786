import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Common } from '../common/common';

@Injectable({
  providedIn: 'root'
})
export class PurchaseDetailsService {

constructor(private http: HttpClient) { }

  // tslint:disable-next-line: typedef
  public AddPurchaseDetails(data: any[]) {
    return this.http.post<any>(Common.baseUrl + '/Purchase/InsertPurchase', data);
  }

  // tslint:disable-next-line: typedef
  public UpdatePurchaseDetails(data: any) {
    return this.http.post<any>(Common.baseUrl + '/Purchase/UpdatePurchase/', data);
  }
  // tslint:disable-next-line: typedef
  public getPurchaseList() {
    return this.http.get<any>(Common.baseUrl + '/Purchase/GetPurchaseList' );
  }

  // tslint:disable-next-line: typedef
  public getPurchaseById(data: any) {
    return this.http.get<any>(Common.baseUrl + '/Purchase/GetPurchase/' + data);
  }

  // tslint:disable-next-line: typedef
  public getPurchaseDetailsById(data: any) {
    return this.http.get<any>(Common.baseUrl + '/Purchase/PurchaseDetailsById/' + data);
  }

   // tslint:disable-next-line: typedef
   public DeletePurchaseById(data: string) {
    return this.http.delete<any>(Common.baseUrl + '/Purchase/DeletePurchase/' + data);
  }

}
