import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { SlideService } from '../../../services/Slide/Slide.service';
import { Common } from '../../../services/common/common';

@Component({
  selector: 'app-slide',
  templateUrl: './slide.component.html',
  styleUrls: ['./slide.component.scss']
})
export class SlideComponent implements OnInit {

  slides: any[] = [];
  image: string = Common.baseUrl + '/images/';
  constructor(private http: HttpClient, private router: Router, private slideService: SlideService) { }

  ngOnInit(): void {
    this.slideService.getSlideList().subscribe((data: any[]) => {
      console.log(data);
      this.slides = data;
      if (data.length === 0) {
        (document.getElementById('tr_with_data') as HTMLTableElement).style.display = 'none';
      }
      else{
        (document.getElementById('tr_with_No_data') as HTMLTableElement).style.display = 'none';
      }
    });
  }
  edit(slideId): any{
    this.router.navigate(['/slide-add/' + slideId]);
  }
  // tslint:disable-next-line: member-ordering
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      // Authorization: 'my-auth-token'
    })
  };

  delete(id: number): any{
    const endPoints = '?slideId=' + id;
    this.http.delete(Common.baseUrl + '/slide/DeleteSlide' + endPoints).subscribe((res: any) => {
        if (res.message === 'Success'){
          (document.getElementById('tr_' + id) as HTMLTableRowElement).remove();
        }
      });
  }
}