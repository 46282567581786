<div class="card m-3">
    <h5 class="card-header">Angular 9 Reactive Form Validation</h5>
    <div class="card-body">
        <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
            <div class="form-row">
                <div class="form-group col">
                    <label>Title</label>
                    <select formControlName="title" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.title.errors }">
                        <option value=""></option>
                        <option value="Mr">Mr</option>
                        <option value="Mrs">Mrs</option>
                        <option value="Miss">Miss</option>
                        <option value="Ms">Ms</option>
                    </select>
                    <div *ngIf="submitted && f.title.errors" class="invalid-feedback">
                        <div *ngIf="f.title.errors.required">Title is required</div>
                    </div>
                </div>
                <div class="form-group col-5">
                    <label>First Name</label>
                    <!-- <input type="text" formControlName="firstName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors && f.firstName.touched && f.firstName.invalid }" /> -->
                    <!-- <input 
                    formControlName="firstName"
                    id="productName" 
                    type="text"
                    placeholder="Enter product name" 
                    class="form-control">
                    <div *ngIf="f.firstName.touched && f.firstName.invalid" class="alert alert-danger">
                        <div *ngIf="f.firstName.errors.required">First Name is required</div>
                        <div *ngIf="f.ProductName.errors.minlength">Name should be 3 character.</div>
                        <div *ngIf="f.ProductName.errors.pattern">Only character allowed.</div>
                    </div> -->

                    <input 
                    formControlName="firstName"
                    id="firstName" 
                    type="text"
                    placeholder="Enter product name" 
                    class="form-control">
                  <div *ngIf="f.firstName.touched && f.firstName.invalid" class="alert alert-danger">
                      <div *ngIf="f.firstName.errors.required">Name is required.</div>
                      <div *ngIf="f.firstName.errors.minlength">Name should be 3 character.</div>
                      <div *ngIf="f.firstName.errors.pattern">Only character allowed.</div>
                  </div>

                    <!-- <div *ngIf="f.ProductName.touched && f.ProductName.invalid" class="alert alert-danger">
                        <div *ngIf="f.ProductName.errors.required">Name is required.</div>
                        <div *ngIf="f.ProductName.errors.minlength">Name should be 3 character.</div>
                        <div *ngIf="f.ProductName.errors.pattern">Only character allowed.</div>
                    </div> -->
                </div>
                <div class="form-group col-5">
                    <label>Last Name</label>
                    <input type="text" formControlName="lastName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
                    <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                        <div *ngIf="f.lastName.errors.required">Last Name is required</div>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label>Email</label>
                <input type="text" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">Email is required</div>
                    <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col">
                    <label>Password</label>
                    <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                        <div *ngIf="f.password.errors.required">Password is required</div>
                        <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
                    </div>
                </div>
                <div class="form-group col">
                    <label>Confirm Password</label>
                    <input type="password" formControlName="confirmPassword" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" />
                    <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                        <div *ngIf="f.confirmPassword.errors.required">Confirm Password is required</div>
                        <div *ngIf="f.confirmPassword.errors.mustMatch">Passwords must match</div>
                    </div>
                </div>
            </div>
            <div class="form-group form-check">
                <input type="checkbox" formControlName="acceptTerms" id="acceptTerms" class="form-check-input" [ngClass]="{ 'is-invalid': submitted && f.acceptTerms.errors }" />
                <label for="acceptTerms" class="form-check-label">Accept Terms & Conditions</label>
                <div *ngIf="submitted && f.acceptTerms.errors" class="invalid-feedback">Accept Ts & Cs is required</div>
            </div>
            <div class="text-center">
                <button class="btn btn-primary mr-1">Register</button>
                <button class="btn btn-secondary" type="reset" (click)="onReset()">Cancel</button>
            </div>
        </form>
    </div>
</div>

