import { Component, OnInit,IterableDiffers } from '@angular/core';
import { BranchService } from '../../../services/branch/branch.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-branch-list',
  templateUrl: './branch-list.component.html',
  styleUrls: ['./branch-list.component.scss']
})
export class BranchListComponent implements OnInit {

  branchData: any;
  public iterableDiffer = null;
  userGroupType: any;

 // data: Array<any>;

  constructor(private branchService: BranchService, private toastrService: ToastrService,
              private router: Router,private iterableDiffers: IterableDiffers)
              {
                this.iterableDiffer = iterableDiffers.find([]).create(null);
                //debugger;
                
               // this.data = new Array<any>();
                this.branchService.getBranchList()
                  .subscribe(
                    (data: any) => {
                      this.branchData = data;
                      // console.log(this.branchData);
                    },
                    error => {console.log(error); }
                    );
              }

              dtOptions: any = {};
ngOnInit() {
  this.userGroupType = localStorage.getItem('userGroupType');
}
ngDoCheck() {
  const lookup = {};
  //debugger;
  const items = this.branchData.ProductArray;
  const changes = this.iterableDiffer.diff(items);
  if (changes) {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
    lengthMenu : [5, 10, 25],
      processing: true
    };
  }
}

  edit(id: any): any{
    // tslint:disable-next-line: no-debugger
    //debugger;
    this.router.navigate(['/branch-add/' + id]);
  }


  delete(id: any): any{
  // tslint:disable-next-line: no-debugger
  //debugger;
  this.branchService.DeleteBranch(id).subscribe(success => {
    this.toastrService.success(success.message, 'Data Delete Successfully.');
    this.branchService.getBranchList()
    .subscribe(
      (data: any) => {
        this.branchData = data;
        // console.log(this.branchData);
      },
      error => {console.log(error); }
      );
    });
  }

}
