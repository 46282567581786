import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Common } from '../common/common';
@Injectable({
  providedIn: 'root'
})
export class RegistrationService {
  reqHeader = new HttpHeaders({'No-Auth': 'True'});
  url = '/Api/dragonMart/dragonproject/Registration';
  constructor(private http: HttpClient) { }
  public createRegistration(data) {
    return this.http.post<any>(Common.baseUrl + this.url, data, {headers: this.reqHeader});
  }

  public registrationService(data){
    return this.http.post<any>(Common.baseUrl + '/Customer/CreateUsers', data, {headers: this.reqHeader});
  }
  public getUserGroup(){
    return this.http.get<any>(Common.baseUrl + '/Auth/GetUserGroupList', {headers: this.reqHeader});
  }
  // public registrationService(data) {
  //   return this.http.post<any>(Common.baseUrl + '/Agent/Registration', data)
  //     .pipe(map((result: any) => {
  //       if (result) {
  //         return result;
  //       }
  //     }));
  // }
}
