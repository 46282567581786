import { Component, ElementRef, IterableDiffers, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BranchService } from '../../services/branch/branch.service';
import { ToastrService } from 'ngx-toastr';
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';
import { UserService } from '../../services/user/user.service';

@Component({
  selector: 'app-chart',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [DatePipe]
})
export class HomeComponent implements OnInit {
  data: Array<any>;
  branchData: Array<any>;

  constructor(private customerService: UserService, private branchService: BranchService, private toastrService: ToastrService, 
    private router: Router, private activeRoute: ActivatedRoute) {
    
      this.data = new Array<any>();
    this.branchService.getBranchList().subscribe((data: any) => {
      this.data = data;
    },
    error => {
      console.log(error); 
    });
    
  }

  ngOnInit(): void { }

  loadChart(id: any): any{
    if(id != null) {
      this.router.navigate(['/chart/' + id]);
    }
    else{
      this.toastrService.error('No Data Found', 'Error!');
    }
  }

}
