
    <div class="app-title">
        <div>
          <h1><i class="fa fa-th-list"></i> Seller</h1>
          <!-- <p>Supplier list</p> -->
        </div>
        <ul class="app-breadcrumb breadcrumb">
          <li class="breadcrumb-item"><i class="fa fa-home fa-lg"></i></li>
          <li class="breadcrumb-item">Seller</li>
          <li class="breadcrumb-item active"><a routerLink="/selller">Add Seller</a></li>
        </ul>
      </div>
      <div class="row">
          <div class="col-md-12">
              <div class="tile">
                
                <div class="tile-title-w-btn">
                  <h3 class="title">Seller List</h3>
                  
                  <p><a class="btn btn-primary icon-btn" routerLink="/seller"><i class="fa fa-plus"></i>Add Seller</a></p>
                </div>
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Seller Name</th>
                        <th>Shop Name</th>
                        <th>Shop Location</th>
                        <th>Mobile</th>
                        <th>Email</th>
                        <th>Photo</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let seller of data ; let i = index" id="tr_{{seller.sellerId}}">
                      <!-- <tr *ngFor="let seller of data ; let i = index" id="tr_{{seller}}"> -->
                        <td>{{i+1}}</td>
                        <td>{{seller.userFullName}}</td>
                        <td>{{seller.shopName}}</td>
                        <td>{{seller.shopAddress}}</td>
                        <td>{{seller.userMobile}}</td>
                        <td>{{seller.userEmail}}</td>
                        <td><img src="{{seller.shopImageName}}" width="100px" height="100px"/> </td>
                        <td>
                          
                          <li class="list-inline-item" (click)="edit(seller.sellerId)">
                          
                            <button  class="btn btn-success btn-sm rounded-0" type="button" data-toggle="tooltip" data-placement="top" title="Edit"><i class="fa fa-edit"></i></button>
                        </li>
                        <li class="list-inline-item" (click)="delete(seller.sellerId)">
                            <button  class="btn btn-danger btn-sm rounded-0" type="button" data-toggle="tooltip" data-placement="top" title="Delete"><i class="fa fa-trash"></i></button>
                        </li>
                        </td>
                      </tr>
  
                      
                    </tbody>
                  </table>
                </div>
                
              </div>
            </div>
          
      </div>
  