import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Common } from '../common/common';

@Injectable({
  providedIn: 'root'
})
export class SupplierService {

constructor(private http: HttpClient) { }

// tslint:disable-next-line: typedef
public getSupplierList() {
  return this.http.get<any>(Common.baseUrl + '/Supplier/GetSupplier/');
}

}
