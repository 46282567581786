import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { ProductService } from '../../../services/product/product.service';
import { ShopService } from '../../../services/shop/shop.service';
import { SupplierService } from '../../../services/supplier/supplier.service';
import { ColorService } from '../../../services/color/color.service';
import { PurchaseDetailsService } from '../../../services/purchaseDetails/purchaseDetails.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-purchase-details',
  templateUrl: './purchase-details.component.html',
  styleUrls: ['./purchase-details.component.scss']
})
export class PurchaseDetailsComponent implements OnInit {
  productData: Array<any>;
  shopData: Array<any>;
  supplierData: Array<any>;
  colorData: Array<any>;
  addPurchaseForm: FormGroup;
  addDetailsForm: FormGroup;
  addPurchaseDetailsFormSubmit: FormGroup;
  ColorName: any;
  ProductName: any;
  detailsValue = [];
  totalPurchaseValue: any[] = [];
  totalPrice: any;
  totalPurchaseAmount: any;
  totalDueAmount: any;
  purchaseData: any[];
  colorSelectedId = -1;
  productSelectedId = -1;
  purchaseDetailsData: any[];
  purchaseDetailsId: Array<any>;
  constructor(private formBuilder: FormBuilder, private productService: ProductService,
              private shopService: ShopService, private supplierService: SupplierService,
              private colorService: ColorService, private parchaseDetailsService: PurchaseDetailsService,
              private toastrService: ToastrService, private router: Router, private activeRoute: ActivatedRoute ) {
    this.getProductList();
    this.getShopList();
    this.getSupplierList();
    this.getColorList();
  }

  ngOnInit(): void {
    const id = this.activeRoute.snapshot.paramMap.get('id');
    if (id !== null){
      this.loadPurchaseDetails(id);
      }
    this.purchaseForm();
  }

   // tslint:disable-next-line: typedef
   loadPurchaseDetails(purchaseId){
  // tslint:disable-next-line: no-debugger
  //debugger;
  this.parchaseDetailsService.getPurchaseById(purchaseId).subscribe((data: any[]) => {
    console.log(data);
    this.purchaseData = data;
    this.SetData(this.purchaseData);
    });
  this.parchaseDetailsService.getPurchaseDetailsById(purchaseId).subscribe((data: any[]) => {
    this.purchaseDetailsData = data;
    // this.purchaseDetailsId = this.purchaseDetailsData.forEach(res => this.purchaseDetailsData = res.purchaseDetailsId);
    this.detailsFormArray.clear();
    this.purchaseDetailsId = new Array<any>();
    data.forEach(e => {
      console.log(e);
      this.colorSelectedId = e.colorId;
      this.productSelectedId = e.productId;
      this.purchaseDetailsId = e.purchaseDetailId,
      console.log(this.purchaseDetailsId);
      this.detailsFormArray.push(this.initPurchaseRowsEdit(e.purchaseDetailId, e.productId, e.colorId, e.stockQuantity,
         e.unitPrice, e.totalPrice, e.reorderPoint));
    });
    });
  }

// tslint:disable-next-line: typedef
SetData(data) {
  const date = new Date(data.purchaseDate);
  const day = date.getDate();
  const monthIndex = date.getMonth() + 1;
  const year = date.getFullYear();
  const dateString = year + '-0' + monthIndex + '-' + day;
  this.addPurchaseForm.patchValue({
    InvoiceNo: data.invoiceNo,
    TotalAmount: data.totalAmount,
    PaidAmount: data.paidAmount,
    // DueAmount: 0,
    // ShopId: data.brandImageName,
    SupplierId: data.supplierId,
    PurchaseDate: dateString,
    DueAmount: data.totalAmount - data.paidAmount
  });
}


  // tslint:disable-next-line: typedef
  getProductList(){
    this.productData = new Array<any>();
    this.productService.getProductList()
                  .subscribe(
                    (data: any) => {
                      this.productData = data;
                      // console.log(this.productData);
                    },
                    error => {console.log(error); }
                    );
  }

  // tslint:disable-next-line: typedef
  getShopList(){
    this.shopData = new Array<any>();
    this.shopService.getShopList()
                  .subscribe(
                    (data: any) => {
                      this.shopData = data;
                      // console.log(this.shopData);
                    },
                    error => {console.log(error); }
                    );
  }

  // tslint:disable-next-line: typedef
  getSupplierList(){
    this.supplierData = new Array<any>();
    this.supplierService.getSupplierList()
                  .subscribe(
                    (data: any) => {
                      this.supplierData = data;
                      // console.log(this.supplierData);
                    },
                    error => {console.log(error); }
                    );
  }

  // tslint:disable-next-line: typedef
  getColorList(){
    this.colorData = new Array<any>();
    this.colorService.getColorList()
                  .subscribe(
                    (data: any) => {
                      this.colorData = data;
                      // console.log(this.colorData);
                    },
                    error => {console.log(error); }
                    );
  }

  // tslint:disable-next-line: typedef
  onSelectProduct(e) {
    this.ProductName = e.target.value;
  }

  // tslint:disable-next-line: typedef
  onSelectColor(e) {
    this.ColorName = e.target.value;
  }

  // tslint:disable-next-line: typedef
  get f(){
    return this.addPurchaseForm.controls;
  }

   // tslint:disable-next-line: typedef
   purchaseForm() {
    this.addPurchaseForm = this.formBuilder.group({
      // [Math.floor(100000 + Math.random() * 900000)].toString()
      InvoiceNo: ['', [Validators.required, Validators.pattern('^[+]?[0-9a-zA-Z .-]*$'), Validators.maxLength(50)]],
      TotalAmount: [''],
      PaidAmount: ['', [Validators.required, Validators.pattern('^[+]?[0-9]*$')]],
      DueAmount: [''],
      ShopId: ['', [Validators.required]],
      SupplierId: ['', [Validators.required]],
      PurchaseDate: [''],
      purchaseRows: this.formBuilder.array([this.initPurchaseRowsEdit(0, 0, 0, 0, 0, 0, 0)])
    });
  }

   // tslint:disable-next-line: typedef
   detailsForm() {
    this.addDetailsForm = this.formBuilder.group({
      ProductId: ['', [Validators.required]],
      ColorId: ['', [Validators.required]],
      StockQuantiy: ['', [Validators.required]],
      UnitPrice: ['', [Validators.required]],
      TotalPrice: ['', [Validators.required]],
      ReorderPoint: ['', [Validators.required]],
    });
  }

  // tslint:disable-next-line: typedef
  get detailsFormArray() {
    return this.addPurchaseForm.get('purchaseRows') as FormArray;
  }

  // tslint:disable-next-line: typedef
  initPurchaseRowsEdit(PurchaseDetailsId, ProductId, ColorId, StockQuantiy, UnitPrice, TotalPrice, ReorderPoint) {
    return this.formBuilder.group({
      PurchaseDetailsId: [PurchaseDetailsId],
      ProductId: [ProductId],
      ColorId: [ColorId],
      StockQuantiy: [StockQuantiy, [Validators.required, Validators.pattern(('^[+]?[0-9]*$'))]],
      UnitPrice: [UnitPrice, [Validators.required, Validators.pattern(('^[+]?[0-9]*$'))]],
      TotalPrice: [TotalPrice],
      ReorderPoint: [ReorderPoint],
    });
  }

  // tslint:disable-next-line: typedef
  addNewRow() {
    this.detailsFormArray.push(this.initPurchaseRowsEdit(0, 0, 0, 0.00, 0.00, 0, 0));
  }
  // tslint:disable-next-line: typedef
  deleteRow(index: number) {
    this.detailsFormArray.removeAt(index);
  }
  // tslint:disable-next-line: typedef
  purchaseDetailsRowsValue(){
    // tslint:disable-next-line: no-debugger
    // debugger;
    // this.detailsValue = new Array<any>();
    const list = this.addPurchaseForm.value.purchaseRows;
    // console.log(list);

    // tslint:disable-next-line: forin
    for (const i in list) {
      this.detailsValue.push(
        {
          // purchaseNo: [this.addPurchaseForm.get('PurchaseNo').value],
          purchaseDetailId: list[i].PurchaseDetailsId,
          productId: +list[i].ProductId,
          colorId: +list[i].ColorId,
          stockQuantity: parseFloat(list[i].StockQuantiy),
          unitPrice: parseFloat(list[i].UnitPrice),
          totalPrice:  parseFloat(this.totalPurchaseValue[i]),
          reorderPoint:  +list[i].ReorderPoint,
        });
      }
    return this.detailsValue;
  }


  // tslint:disable-next-line: typedef
  onSubmit() {
    // tslint:disable-next-line: no-debugger
    //debugger;
    const purchaseId = +this.activeRoute.snapshot.paramMap.get('id');
    if (purchaseId !== null && purchaseId !== 0) {
      this.addPurchaseDetailsFormSubmit = this.formBuilder.group({
        purchaseId,
        createBy: ['1010560'],
        supplierId: [+this.addPurchaseForm.get('SupplierId').value],
        shopId: [+this.addPurchaseForm.get('ShopId').value],
        invoiceNo: [+this.addPurchaseForm.get('InvoiceNo').value],
        purchaseDate: [this.addPurchaseForm.get('PurchaseDate').value],
        totalAmount: [this.totalPurchaseAmount],
        paidAmount: [parseFloat(this.addPurchaseForm.get('PaidAmount').value)],
        // dueAmount: [parseFloat(this.addPurchaseForm.get('DueAmount').value)],
        purchaseList: [this.purchaseDetailsRowsValue()]
      });
      console.log(this.totalPurchaseValue);
      console.log(this.addPurchaseDetailsFormSubmit.value);
      this.parchaseDetailsService.UpdatePurchaseDetails(this.addPurchaseDetailsFormSubmit.value)
        .subscribe(res => {
          console.log(res);
          this.toastrService.success(res.message, 'Data Update Successfully.');
          this.purchaseForm();
          this.router.navigate(['/purchase-list']);
        },
        error => {
          console.log(error);
          this.toastrService.error(error, 'Error!');
        });
    }
    else {
      this.addPurchaseDetailsFormSubmit = this.formBuilder.group({
        createBy: ['1010560'],
        supplierId: [+this.addPurchaseForm.get('SupplierId').value],
        shopId: [+this.addPurchaseForm.get('ShopId').value],
        invoiceNo: [+this.addPurchaseForm.get('InvoiceNo').value],
        purchaseDate: [this.addPurchaseForm.get('PurchaseDate').value],
        totalAmount: [this.totalPurchaseAmount],
        paidAmount: [parseFloat(this.addPurchaseForm.get('PaidAmount').value)],
        // dueAmount: [parseFloat(this.addPurchaseForm.get('DueAmount').value)],
        purchaseList: [this.purchaseDetailsRowsValue()]
      });
      console.log(this.totalPurchaseValue);

      console.log(this.addPurchaseDetailsFormSubmit.value);
      this.parchaseDetailsService.AddPurchaseDetails(this.addPurchaseDetailsFormSubmit.value)
        .subscribe(res => {
          console.log(res);
          this.toastrService.success(res.message, 'Data Save Successfully.');
          this.purchaseForm();
          this.router.navigate(['/purchase-list']);
        },
        error => {
          console.log(error);
          this.toastrService.error(error, 'Error!');
        });
    }

  }

  // tslint:disable-next-line: typedef
  getItemPrice(event){
    // tslint:disable-next-line: no-debugger
    //debugger;
    let idNum = -1;
    switch (event.target.id.substr(0, 1)){
      case 's':
        idNum = event.target.id.substr(12, event.target.id.length - 12);
        this.calculateTotal(idNum);
        break;

      case 'u':
        idNum = event.target.id.substr(9, event.target.id.length - 9);
        this.calculateTotal(idNum);
        break;

      default :
        idNum = -1;
    }
  }

  // tslint:disable-next-line: typedef
  getDueAmount(event){
    // tslint:disable-next-line: no-debugger
    //debugger;
    let idNum = -1;
    switch (event.target.id.substr(0, 1)){
      case 't':
        idNum = event.target.id.substr(11, event.target.id.length - 11);
        this.calculateDueAmount(idNum);
        break;

      case 'p':
        idNum = event.target.id.substr(10, event.target.id.length - 10);
        this.calculateDueAmount(idNum);
        break;

      default :
        idNum = -1;
    }
  }
  // tslint:disable-next-line: typedef
  calculateTotal(id: number){
    console.log(id);
    let unitPrice = (document.getElementById('unitPrice' + id) as HTMLInputElement).value.trim();
    let stockQuantiy = (document.getElementById('stockQuantiy' + id) as HTMLInputElement).value.trim();
    unitPrice = unitPrice != null ? unitPrice : '0';
    stockQuantiy = stockQuantiy != null ? stockQuantiy : '0';
    this.totalPrice = (parseFloat(unitPrice) * parseFloat(stockQuantiy)).toFixed(2);
    (document.getElementById('totalPrice' + id) as HTMLInputElement).value  = this.totalPrice;
    this.totalPurchaseValue.length > id ? this.totalPurchaseValue[id] = this.totalPrice :
     this.totalPurchaseValue.push(this.totalPrice);
    this.getPurchaseTotal();
    }

    // tslint:disable-next-line: typedef
    calculateDueAmount(id: number){
      console.log(id);
      let totalAmount = (document.getElementById('totalAmount' + id) as HTMLInputElement).value.trim();
      let paidAmount = (document.getElementById('paidAmount' + id) as HTMLInputElement).value.trim();
      totalAmount = totalAmount != null ? totalAmount : '0';
      paidAmount = paidAmount != null ? paidAmount : '0';
      this.totalDueAmount = (parseFloat(totalAmount) - parseFloat(paidAmount)).toFixed(2);
      (document.getElementById('dueAmount' + id) as HTMLInputElement).value  = this.totalDueAmount;
      }

  // tslint:disable-next-line: typedef
  getPurchaseTotal() {
    // console.log(this.totalPurchaseValue);
    let sum = 0;
    this.totalPurchaseValue.forEach(a => sum += parseFloat(a));
    this.totalPurchaseAmount = sum;
    console.log(this.totalPurchaseValue);
    (document.getElementById('totalAmount') as HTMLInputElement).value  = sum.toFixed(2).toString();
  }
  // tslint:disable-next-line: typedef
  setSelectionRange(e){
     // alert(e.target.value.length);
     (document.getElementById(e.target.id) as HTMLInputElement).select();
  }
}
