import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Common } from './../common/common';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private http: HttpClient) { }  
  public getOrderListdata(data) {
    return this.http.post(Common.baseUrl + '/AdminOrderSection/GetAllOrderbyStatus', data);
  }
  public getPendingOrderdetails(data: any) {
    return this.http.post<any>(Common.baseUrl + '/AdminOrderSection/GetOrderDetailsbyId/', data );
  }
  public getPendingOrderbyid(data) {
    return this.http.post(Common.baseUrl + '/AdminOrderSection/GetOrderId', data );
  }
  public submitorderchkout(data) {
    return this.http.post(Common.baseUrl + '/AdminOrderSection/UpdateCustomerOrderStatus', data);
  }
  // public deleteTask(data){
  //   return this.http.post<any>(Common.baseUrl + '/ERP/TaskManagement/TaskList/DeleteTaskById', data);
  // }
}
