import { Component, ElementRef, IterableDiffers, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BranchService } from '../../services/branch/branch.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../services/user/user.service';
import { Chart, registerables } from 'chart.js';
import { DashboardService } from '../../services/dashboard/dashboard.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit {
  data: Array<any>;
  customerData: Array<any>;

  totalAgeGroup: number = 0;
  totalBillingRange: number = 0;
  totalServiceReview: number = 0;
  totalCategorySelect: number = 0;
  totalGetToKnow: number = 0;
  totalSeeOnline: number = 0;
  totalAssociateOffer: number = 0;
  totalReceiveCommercial: number = 0;

  ageGroupChart:any;
  billingRangeChart:any;
  experienceChart:any;
  interestedCategoryChart:any;
  gotToKnowChart:any;
  seeOnlineChart:any;
  associateOfferChart:any;
  receiveCommercialChart:any;

  ageGroupConfig:any;
  billingRangeConfig:any;
  experienceConfig:any;
  interestedCategoryConfig:any;
  gotToKnowConfig:any;
  seeOnlineConfig:any;
  associateOfferConfig:any;
  receiveCommercialConfig:any;

  branchId: any;
  branchData: Array<any>;
  userGroupType: any;

  public iterableDiffer = null;

  @ViewChild('TABLE', { static: false }) TABLE: ElementRef;
  title = 'Excel';
  datepipe: any;
  customerDetail: any;
  dtOptions: { pagingType: string; pageLength: number; lengthMenu: number[]; processing: boolean; };
  ExportTOExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.TABLE.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'CustomerList.xlsx');
  }

  constructor(private branchService: BranchService, private toastrService: ToastrService, private router: Router,private customerService: UserService, 
    private dashboardService: DashboardService, private activeRoute: ActivatedRoute, private iterableDiffers: IterableDiffers) {
      this.iterableDiffer = iterableDiffers.find([]).create(null);
      this.branchId = +this.activeRoute.snapshot.paramMap.get('id');
      this.initialData();
      this.data = new Array<any>();
      this.branchData = new Array<any>();

      this.branchService.getBranchList().subscribe((data: any) => {
        this.data = data;
        this.branchData = this.data.filter(b => b.branchId == this.branchId);
        
      },
      error => {
        console.log(error); 
      });
  }

  ngOnInit(): void {
    this.userGroupType = localStorage.getItem('userGroupType');
    this.ageGroupChart = document.getElementById('ageGroup');
    this.billingRangeChart = document.getElementById('billingRange');
    this.experienceChart = document.getElementById('experience');
    this.interestedCategoryChart = document.getElementById('interestCategory');
    this.gotToKnowChart = document.getElementById('gotToKnow');
    this.seeOnlineChart = document.getElementById('seeOnline');
    this.associateOfferChart = document.getElementById('associateOffer');
    this.receiveCommercialChart = document.getElementById('receiveCommercial');

    Chart.register(...registerables);
    this.loadCustomerById(this.branchId);
  }

  initialData(): void {

    let branchId = +this.activeRoute.snapshot.paramMap.get('id');

    this.dashboardService.GetAgeSummaryForChart(branchId).subscribe((data: any) => {
      data.forEach(element => {
        this.totalAgeGroup += parseInt(element.total);
      });
      this.loadAgeGroupChart(data)
    },
    error => {console.log(error); }
    );
    
    this.dashboardService.GetBillingSummaryForChart(branchId).subscribe((data: any) => {
        data.forEach(element => {
          this.totalBillingRange += parseInt(element.total);
        });

        this.loadBillingRangeChart(data);
      },
      error => {console.log(error); }
    );

    this.dashboardService.GetExperienceSummaryForChart(branchId).subscribe((data: any) => {
      data.forEach(element => {
        this.totalServiceReview += parseInt(element.total);
      });

      this.loadExperienceChart(data);
      },
      error => {console.log(error); }
    );

    this.dashboardService.GetInterestedCategorySummaryForChart(branchId).subscribe((data: any) => {
      data.forEach(element => {
        this.totalCategorySelect += parseInt(element.total);
      });
      
      this.loadInterestedCategoryChart(data);
      },
      error => {console.log(error); }
    );

    this.dashboardService.GetGotToKnowSummaryForChart(branchId).subscribe((data: any) => {
      data.forEach(element => {
        this.totalGetToKnow += parseInt(element.total);
      });

        this.loadGotToKnowChart(data);
      },
      error => {console.log(error); }
    );

    this.dashboardService.GetSeeOnlineChart(branchId).subscribe((data: any) => {
      data.forEach(element => {
        this.totalSeeOnline += parseInt(element.total);
      });

      this.loadSeeOnlineChart(data);
      },
      error => {console.log(error); }
    );

    this.dashboardService.GetAssociateOfferChart(branchId).subscribe((data: any) => {
      data.forEach(element => {
        this.totalAssociateOffer += parseInt(element.total);
      });

      this.loadAssociateOfferChart(data);
      },
      error => {console.log(error); }
    );
    
    this.dashboardService.GetReceiveCommercialChart(branchId).subscribe((data: any) => {
      data.forEach(element => {
        this.totalReceiveCommercial += parseInt(element.total);
      });

      this.loadReceiveCommercialChart(data);
      },
      error => {console.log(error); }
    );
  
  }

  loadAgeGroupChart(rawData: any) {
    let dataSet = [];
    let labels = [];
    rawData.forEach(element => {
      //  let val = (parseInt(element.total) / this.totalAgeGroup * 100);
      //  console.log(val);
      dataSet.push(element.total);
      labels.push(element.title);
    });

    const data = {
      labels: labels,
      datasets: [{
        label: 'My First Dataset',
        data: dataSet,
        // backgroundColor: [
        //   'rgba(255, 99, 132, 1.5)',
        //   'rgba(255, 159, 64, 1.5)',
        //   'rgba(255, 205, 86, 1.5)',
        //   'rgba(75, 192, 192, 1.5)',
        //   'rgba(54, 162, 235, 1.5)',
        //   'rgba(153, 102, 255, 1.5)',
        //   'rgba(201, 203, 207, 1.5)'
        // ],
        backgroundColor: ['#CB4335', '#1F618D', '#F1C40F', '#27AE60', '#884EA0', '#D35400'],
        hoverOffset: 4
      }]
    };

    this.ageGroupConfig = {
      type: 'pie',
      data: data,
      options: {
        plugins: {
          title: {
            display: true,
            text: 'Age Group',
            font: {
              size: '40'
            }
          }
          // ,
          // datalabels: {
          //   anchor: 'end',
          //   align: 'top',
          //   formatter: Math.round,
          //   font: {
          //     weight: 'bold',
          //     size: '15'
          //   },
          //   color: '#000000'
          // }
        }
      },
    }

    new Chart(this.ageGroupChart, this.ageGroupConfig);
  }
  
  loadBillingRangeChart(rawData: any): void {
    let dataSet = [];
    let labels = [];
    rawData.forEach(element => {
      dataSet.push(element.total);
      labels.push(element.title);
    });

    const data = {
      labels: labels,
      datasets: [{
        label: 'My First Dataset',
        data: dataSet,
        // backgroundColor: [
        //   'rgba(255, 99, 132, 1.5)',
        //   'rgba(255, 159, 64, 1.5)',
        //   'rgba(255, 205, 86, 1.5)',
        //   'rgba(75, 192, 192, 1.5)',
        //   'rgba(54, 162, 235, 1.5)',
        //   'rgba(153, 102, 255, 1.5)',
        //   'rgba(201, 203, 207, 1.5)'
        // ],
        backgroundColor: ['#CB4335', '#1F618D', '#F1C40F', '#27AE60', '#884EA0', '#D35400'],
        hoverOffset: 4
      }]
    };

    this.billingRangeConfig = {
      type: 'pie',
      data: data,
      options: {
        plugins: {
          title: {
            display: true,
            text: 'Billing cycle',
            font: {
              size: '40'
            }
          },
          datalabels: {
            anchor: 'end',
            align: 'top',
            formatter: Math.round,
            font: {
              weight: 'bold',
              size: '15'
            },
            color: '#000000'
          }
        }
      }
    }

    new Chart(this.billingRangeChart, this.billingRangeConfig);
  }

  loadExperienceChart(rawData: any): void {
    let dataSet = [];
    let labels = [];
    rawData.forEach(element => {
      dataSet.push(element.total);
      labels.push(element.title);
    });

    const data = {
      labels: labels,
      datasets: [{
        label: 'My First Dataset',
        data: dataSet,
        // backgroundColor: [
        //   'rgba(255, 99, 132, 1.5)',
        //   'rgba(255, 159, 64, 1.5)',
        //   'rgba(255, 205, 86, 1.5)',
        //   'rgba(75, 192, 192, 1.5)',
        //   'rgba(54, 162, 235, 1.5)',
        //   'rgba(153, 102, 255, 1.5)',
        //   'rgba(201, 203, 207, 1.5)'
        // ],
        backgroundColor: ['#CB4335', '#1F618D', '#F1C40F', '#27AE60', '#884EA0', '#D35400'],
        hoverOffset: 4
      }]
    };

    this.experienceConfig = {
      type: 'pie',
      data: data,
      options: {
        plugins: {
          title: {
            display: true,
            text: 'Service Review',
            font: {
              size: '40'
            }
          },
          datalabels: {
            anchor: 'end',
            align: 'top',
            formatter: Math.round,
            font: {
              weight: 'bold',
              size: '15'
            },
            color: '#000000'
          }
        }
      }
    }

    new Chart(this.experienceChart, this.experienceConfig);
  }

  loadInterestedCategoryChart(rawData: any): void {
    let dataSet = [];
    let labels = [];
    rawData.forEach(element => {
      dataSet.push(element.total);
      labels.push(element.title);
    });

    const data = {
      labels: labels,
      datasets: [{
        label: 'My First Dataset',
        data: dataSet,
        // backgroundColor: [
        //   'rgba(255, 99, 132, 1.5)',
        //   'rgba(255, 159, 64, 1.5)',
        //   'rgba(255, 205, 86, 1.5)',
        //   'rgba(75, 192, 192, 1.5)',
        //   'rgba(54, 162, 235, 1.5)',
        //   'rgba(153, 102, 255, 1.5)',
        //   'rgba(201, 203, 207, 1.5)'
        // ],
        backgroundColor: ['#CB4335', '#1F618D', '#F1C40F', '#27AE60', '#884EA0', '#D35400'],
        hoverOffset: 4
      }]
    };

    this.interestedCategoryConfig = {
      type: 'pie',
      data: data,
      options: {
        plugins: {
          title: {
            display: true,
            text: 'Categories Selection',
            font: {
              size: '40'
            }
          },
          datalabels: {
            anchor: 'hide',
            align: 'top',
            formatter: Math.round,
            font: {
              weight: 'bold',
              size: '15'
            },
            color: '#000000'
          }
        }
      }
    }

    new Chart(this.interestedCategoryChart, this.interestedCategoryConfig);
  }

  loadGotToKnowChart(rawData: any): void {
    let dataSet = [];
    let labels = [];
    rawData.forEach(element => {
      dataSet.push(element.total);
      labels.push(element.title);
    });

    const data = {
      labels: labels,
      datasets: [{
        label: 'My First Dataset',
        data: dataSet,
        // backgroundColor: [
        //   'rgba(255, 99, 132, 1.5)',
        //   'rgba(255, 159, 64, 1.5)',
        //   'rgba(255, 205, 86, 1.5)',
        //   'rgba(75, 192, 192, 1.5)',
        //   'rgba(54, 162, 235, 1.5)',
        //   'rgba(153, 102, 255, 1.5)',
        //   'rgba(201, 203, 207, 1.5)'
        // ],
        backgroundColor: ['#CB4335', '#1F618D', '#F1C40F', '#27AE60', '#884EA0', '#D35400'],
        hoverOffset: 4
      }]
    };

    this.gotToKnowConfig = {
      type: 'pie',
      data: data,
      options: {
        plugins: {
          title: {
            display: true,
            text: 'Get to know about us',
            font: {
              size: '40'
            }
          },
          datalabels: {
            anchor: 'end',
            align: 'top',
            formatter: Math.round,
            font: {
              weight: 'bold',
              size: '15'
            },
            color: '#000000'
          }
        }
      }
    }

    new Chart(this.gotToKnowChart, this.gotToKnowConfig);
  }

  loadSeeOnlineChart(rawData: any): void {
        
    let dataSet = [];
    let labels = [];
    rawData.forEach(element => {
      dataSet.push(element.total);
      labels.push(element.title);
    });

    const data = {
      labels: labels,
      datasets: [{
        label: 'My First Dataset',
        data: dataSet,
        // backgroundColor: [
        //   'rgba(255, 99, 132, 1.5)',
        //   'rgba(255, 159, 64, 1.5)',
        //   'rgba(255, 205, 86, 1.5)',
        //   'rgba(75, 192, 192, 1.5)',
        //   'rgba(54, 162, 235, 1.5)',
        //   'rgba(153, 102, 255, 1.5)',
        //   'rgba(201, 203, 207, 1.5)'
        // ],
        backgroundColor: ['#CB4335', '#1F618D', '#F1C40F', '#27AE60', '#884EA0', '#D35400'],
        hoverOffset: 4
      }]
    };

    this.seeOnlineConfig = {
      type: 'pie',
      data: data,
      options: {
        plugins: {
          title: {
            display: true,
            text: 'See product from',
            font: {
              size: '40'
            }
          },
          datalabels: {
            anchor: 'end',
            align: 'top',
            formatter: Math.round,
            font: {
              weight: 'bold',
              size: '15'
            },
            color: '#000000'
          }
        }
      }
    }

    new Chart(this.seeOnlineChart, this.seeOnlineConfig);
  }

  loadAssociateOfferChart(rawData: any): void {
    let dataSet = [];
    let labels = [];
    rawData.forEach(element => {
      dataSet.push(element.total);
      labels.push(element.title);
    });

    const data = {
      labels: labels,
      datasets: [{
        label: 'My First Dataset',
        data: dataSet,
        // backgroundColor: [
        //   'rgba(255, 99, 132, 1.5)',
        //   'rgba(255, 159, 64, 1.5)',
        //   'rgba(255, 205, 86, 1.5)',
        //   'rgba(75, 192, 192, 1.5)',
        //   'rgba(54, 162, 235, 1.5)',
        //   'rgba(153, 102, 255, 1.5)',
        //   'rgba(201, 203, 207, 1.5)'
        // ],
        backgroundColor: ['#CB4335', '#1F618D', '#F1C40F', '#27AE60', '#884EA0', '#D35400'],
        hoverOffset: 4
      }]
    };

    this.associateOfferConfig = {
      type: 'pie',
      data: data,
      options: {
        plugins: {
          title: {
            display: true,
            text: 'Staff assistancy offer',
            font: {
              size: '40'
            }
          },
          datalabels: {
            anchor: 'end',
            align: 'top',
            formatter: Math.round,
            font: {
              weight: 'bold',
              size: '15'
            },
            color: '#000000'
          }
        }
      }
    }

    new Chart(this.associateOfferChart, this.associateOfferConfig);
  }

  loadReceiveCommercialChart(rawData: any): void {
    let dataSet = [];
    let labels = [];
    rawData.forEach(element => {
      dataSet.push(element.total);
      labels.push(element.title);
    });

    const data = {
      labels: labels,
      datasets: [{
        label: 'My First Dataset',
        data: dataSet,
        // backgroundColor: [
        //   'rgba(255, 99, 132, 1.5)',
        //   'rgba(255, 159, 64, 1.5)',
        //   'rgba(255, 205, 86, 1.5)',
        //   'rgba(75, 192, 192, 1.5)',
        //   'rgba(54, 162, 235, 1.5)',
        //   'rgba(153, 102, 255, 1.5)',
        //   'rgba(201, 203, 207, 1.5)'
        // ],
        backgroundColor: ['#CB4335', '#1F618D', '#F1C40F', '#27AE60', '#884EA0', '#D35400'],
        hoverOffset: 4
      }]
    };

    this.receiveCommercialConfig = {
      type: 'pie',
      data: data,
      options: {
        plugins: {
          title: {
            display: true,
            text: 'Receive commercial',
            font: {
              size: '40'
            }
          },
          datalabels: {
            anchor: 'hide',
            align: 'top',
            formatter: Math.round,
            font: {
              weight: 'bold',
              size: '15'
            },
            color: '#000000'
          }
        }
      }
    }

    new Chart(this.receiveCommercialChart, this.receiveCommercialConfig);
  }

  ngDoCheck() {
    if (this.customerDetail) {
      const lookup = {};
      const items = this.customerDetail.ProductArray;
      const changes = this.iterableDiffer.diff(items);
      if (changes) {
        this.dtOptions = {
          pagingType: 'full_numbers',
          pageLength: 5,
        lengthMenu : [5, 10, 25],
          processing: true
        };
      }
    }
  }

  loadCustomerById(branchId: any): any{
    this.customerService.GetCustomerDetailForReport(branchId).subscribe((data: any) => {
        this.customerDetail = data;
        if(this.customerDetail == null) {
          this.toastrService.error('No Data Found', 'Error!');
        }
      },
      error => {console.log(error); }
    );
   }

  loadCustomer(): any{
    let branchId = +this.activeRoute.snapshot.paramMap.get('id');
    var formdate = ((document.getElementById("FormDate") as HTMLInputElement).value);
    var todate = ((document.getElementById("ToDate") as HTMLInputElement).value);

    // var nFormdate = this.datepipe.transform(formdate, 'dd/MM/yyyy');
    // var nFodate = this.datepipe.transform(todate, 'dd/MM/yyyy');

    this.customerService.getCustomerListById(branchId)
    .subscribe(
      (data: any) => {
        if(formdate == "" && todate == ""){
          this.customerDetail = data;
          if(this.customerDetail == null) {
            this.toastrService.error('No Data Found', 'Error!');
          }          
        }
        else {
            var table = $('#customerTable').DataTable();
            table.clear().draw();
            $('#customerTable').DataTable().clear();
            $('#customerTable').DataTable().destroy();
            data.forEach(item => item.createdDate = item.createdDate.split(" "));

            this.customerDetail = data; // data.filter(item => item.createdDate[0] >= nFormdate && item.createdDate[0] <= nFodate);
        }
      },
      error => {console.log(error); }
    );
   }

   getCustomerByParams(): any{
    let branchId = +this.activeRoute.snapshot.paramMap.get('id');
    var fromdate = ((document.getElementById("FormDate") as HTMLInputElement).value);
    var todate = ((document.getElementById("ToDate") as HTMLInputElement).value);

    // var nFormdate = this.datepipe.transform(formdate, 'dd/MM/yyyy');
    // var nFodate = this.datepipe.transform(todate, 'dd/MM/yyyy');

    const params = {branchId, fromdate, todate};

    this.customerService.getCustomerByParams(params).subscribe((data: any) => {
      
        if(fromdate == "" && todate == ""){
          this.customerDetail = data;
          if(this.customerDetail == null) {
            this.toastrService.error('No Data Found', 'Error!');
          }          
        }
        else {
            // var table = $('#customerTable').DataTable();
            // table.clear().draw();
            $('#customerTable').DataTable().clear();
            $('#customerTable').DataTable().destroy();
            //data.forEach(item => item.createdDate = item.createdDate.split(" "));

            this.customerDetail = data; // data.filter(item => item.createdDate[0] >= nFormdate && item.createdDate[0] <= nFodate);
        }
      },
      error => {console.log(error); }
    );

    this.chartInitialize();

    this.dashboardService.getBranchChartDataByParams(params).subscribe((data: any) => {
      // Total Age Group Chart
      this.totalAgeGroup = 0;
      data.ageGroup.forEach(element => {
        this.totalAgeGroup += parseInt(element.total);
      });
      this.loadAgeGroupChart(data.ageGroup)

      // Billing Summary Chart
      this.totalBillingRange = 0;
      data.billingRange.forEach(element => {
        this.totalBillingRange += parseInt(element.total);
      });
      this.loadBillingRangeChart(data.billingRange);
  
      // Experience Summary Chart
      this.totalServiceReview = 0;
      data.experienceSummary.forEach(element => {
        this.totalServiceReview += parseInt(element.total);
      });
      this.loadExperienceChart(data.experienceSummary);
  
      // Interested Category Chart
      this.totalCategorySelect = 0;
      data.interestedCategory.forEach(element => {
        this.totalCategorySelect += parseInt(element.total);
      });      
      this.loadInterestedCategoryChart(data.interestedCategory);
  
      // Got To Know Summary Chart
      this.totalGetToKnow = 0;
      data.gotToKnowingFrom.forEach(element => {
        this.totalGetToKnow += parseInt(element.total);
      });
      this.loadGotToKnowChart(data.gotToKnowingFrom);
  
      // See Online Chart
      this.totalSeeOnline = 0;
      data.seeProductFrom.forEach(element => {
        this.totalSeeOnline += parseInt(element.total);
      });
      this.loadSeeOnlineChart(data.seeProductFrom);
  
      // Associate Offer Chart
      this.totalAssociateOffer = 0;
      data.associateOffer.forEach(element => {
        this.totalAssociateOffer += parseInt(element.total);
      });
      this.loadAssociateOfferChart(data.associateOffer);
      
      // Receive Commercial Via Chart
      this.totalReceiveCommercial = 0;
      data.receiveCommercial.forEach(element => {
        this.totalReceiveCommercial += parseInt(element.total);
      });
      this.loadReceiveCommercialChart(data.receiveCommercial);
    },
    error => {console.log(error); }
    );
   }

   chartInitialize() {
    this.ageGroupChart = document.getElementById('ageGroup');
    this.billingRangeChart = document.getElementById('billingRange');
    this.experienceChart = document.getElementById('experience');
    this.interestedCategoryChart = document.getElementById('interestCategory');
    this.gotToKnowChart = document.getElementById('gotToKnow');
    this.seeOnlineChart = document.getElementById('seeOnline');
    this.associateOfferChart = document.getElementById('associateOffer');
    this.receiveCommercialChart = document.getElementById('receiveCommercial');
    
    var ageGroupChartExist = Chart.getChart("ageGroup"); // <canvas> id
    if (ageGroupChartExist != undefined)  
      ageGroupChartExist.destroy(); 

    var billingRangeChartExist = Chart.getChart("billingRange"); // <canvas> id
    if (billingRangeChartExist != undefined)  
      billingRangeChartExist.destroy(); 

    var experienceChartExist = Chart.getChart("experience"); // <canvas> id
    if (experienceChartExist != undefined)  
      experienceChartExist.destroy(); 
    
    var interestCategoryChartExist = Chart.getChart("interestCategory"); // <canvas> id
    if (interestCategoryChartExist != undefined)  
    interestCategoryChartExist.destroy(); 

    var gotToKnowChartExist = Chart.getChart("gotToKnow"); // <canvas> id
    if (gotToKnowChartExist != undefined)  
      gotToKnowChartExist.destroy(); 

    var seeOnlineChartExist = Chart.getChart("seeOnline"); // <canvas> id
    if (seeOnlineChartExist != undefined)  
      seeOnlineChartExist.destroy(); 
      
    var associateOfferChartExist = Chart.getChart("associateOffer"); // <canvas> id
    if (associateOfferChartExist != undefined)  
      associateOfferChartExist.destroy(); 

    var receiveCommercialChartExist = Chart.getChart("receiveCommercial"); // <canvas> id
    if (receiveCommercialChartExist != undefined)  
      receiveCommercialChartExist.destroy(); 
  }
}
