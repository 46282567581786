
    <section class="material-half-bg">
      <div class="cover"></div>
    </section>
    <section class="login-content">
      <div>
        <img src="/assets/images/miniso-logo.jpg" alt="Miniso Logo" style="width: 60px; margin-top: 20px;">
      </div>
      <div>
        <h1 style="color: white;">MINISO E-CUSTOMER CARD </h1>
      </div>
      <div class="login-box form-module account">
        <!-- <div [hidden]="isToolTipHidden" class="toggle" (click)="toggle()"><i class="fa {{regIcon}}"></i>
          <div class="regtooltip">{{toolTip}}</div>
        </div> -->
        <form [formGroup]="loginForm" [hidden]="isLoginHidden" (ngSubmit)="onSubmit()" class="login-form">
          <h3 class="login-head"><i class="fa fa-lg fa-fw fa-user"></i>SIGN IN</h3>
          <div class="form-group">
            <label class="control-label">USERNAME</label>
            <input class="form-control" type="text" placeholder="Enter Username" autofocus
            name="uname" required formControlName="username">
            <i class="fa fa-user"></i>
            <p *ngIf="loginForm.get('username').hasError('required') && loginForm.get('username').touched">
              <!-- <p [class.d-none]="username.valid || username.untouched"> -->
              <small class="text-danger">Username is <strong>required</strong></small> <br>
            </p>
          </div>
          <div class="form-group">
            <label class="control-label">PASSWORD</label>
            <input class="form-control" type="password" placeholder="Enter Password" pattern="^.{6,25}$"
            name="password" required formControlName="password">
            <i class="fa fa-key"></i>
            <p *ngIf="loginForm.get('password').hasError('required') && loginForm.get('password').touched">
              <!-- <p [class.d-none]="password.valid || password.untouched"> -->
              <small class="text-danger">Password is <strong>required</strong></small>
            </p>
          </div>
          <div class="form-group">
            <div class="utility">
              <div class="animated-checkbox">
                <label>
                  <input type="checkbox" hidden><span class="label-text">Stay Signed in</span>
                </label>
              </div>
              <p class="semibold-text mb-2"><a href="javascript:void(0)" (click)="forgotPassword()" data-toggle="flip">Forgot Password ?</a></p>
            </div>
          </div>
          <div class="form-group btn-container">
            <button style="background-color: #EB1C24 !important;" type="submit" class="btn btn-primary btn-block"><i class="fa fa-sign-in fa-lg fa-fw"></i>SIGN IN</button>
          </div>
        </form>
       <div [hidden]="isRegHidden">
          <app-adminreg></app-adminreg>
        </div>

        <form [hidden]="isForgotPassHidden">
          <h3 class="login-head"><i class="fa fa-lg fa-fw fa-lock"></i>Forgot Password ?</h3>
          <div class="form-group">
            <label class="control-label">EMAIL</label>
            <input class="form-control" type="text" placeholder="Email"/>
            <i class="fa fa-envelope"></i>
          </div>
          <div class="form-group btn-container">
            <button class="btn btn-primary btn-block"><i class="fa fa-unlock fa-lg fa-fw"></i>RESET</button>
          </div>
          <div class="form-group mt-3">
            <p class="semibold-text mb-0"><a href="javascript:void(0)" (click)="backToLogin()" data-toggle="flip"><i class="fa fa-angle-left fa-fw"></i> Back to Login</a></p>
          </div>
        </form>
      </div>

      <!-- <div class="cta"><a href="javascript:void(0)">Forgot your password?</a></div> -->
    </section>
