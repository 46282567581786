import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { MustMatch } from './../../../../_helpers/must-match.validator';

@Component({
  selector: 'app-user-add',
  templateUrl: './user-add.component.html',
  styleUrls: ['./user-add.component.scss']
})
export class UserAddComponent implements OnInit {

  addUserForm: FormGroup;
  addUserFormSubmit: FormGroup;
  submitted = false;

  constructor(private formBuilder: FormBuilder, private http: HttpClient, 
    private toastrService: ToastrService) { }

  ngOnInit(): void {
    this.userForm();
  }

  userForm() {
    this.addUserForm = this.formBuilder.group({
      UserName: new FormControl('', [Validators.required, Validators.pattern('^[+]?[a-zA-Z .-]*$'), Validators.minLength(3)]),
      Email: new FormControl('', [Validators.required,Validators.email]),
      Designation: new FormControl('', [Validators.required]),
      Mobileno: new FormControl('', [Validators.required]),
      Password: new FormControl('', [Validators.required,Validators.minLength(6)]),
     // ConfirmPassword: new FormControl(['', Validators.required]),
      Address: new FormControl('', [Validators.required]),
      UserRole: new FormControl('', [Validators.required])

      // email: ['', [Validators.required, Validators.email]],
      //       password: ['', [Validators.required, Validators.minLength(6)]],
      //       confirmPassword: ['', Validators.required],
    });
  }
  get f(){
    return this.addUserForm.controls;
  }
  onSubmit(){
    // tslint:disable-next-line: no-debugger
    //debugger;
    this.addUserFormSubmit = this.formBuilder.group({
      UserName: [this.addUserForm.get('UserName').value],
      Email: [this.addUserForm.get('Email').value],
      Designation: [this.addUserForm.get('Designation').value],
      Mobileno: [this.addUserForm.get('Mobileno').value],
      Password: [this.addUserForm.get('Password').value],
      UserRole: [this.addUserForm.get('UserRole').value]

      
      
    });

    if (this.addUserForm.get('UserName').hasError('required')) {
      this.toastrService.error('Please Input Product Name', 'Error!');
      return;
  }
    console.log(this.addUserForm.value);
    this.http.post('http://localhost:8001/upload.php', this.addUserForm.value)
      .subscribe(res => {
        console.log(res);
        alert('Uploaded Successfully.');
      });
  }

}
