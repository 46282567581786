import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Common } from '../common/common';

@Injectable({
  providedIn: 'root'
})
export class BranchService {
  reqHeader = new HttpHeaders({'No-Auth': 'True'});
  constructor(private http: HttpClient) { }

  public AddBranch(data: any) {
    return this.http.post<any>(Common.baseUrl + '/Branch/InsertBranch', data);
  }

  public UpdateBranch(data: any) {
    return this.http.post<any>(Common.baseUrl + '/Branch/UpdateBranch/', data);
  }

  public getBranchListWithCustomerCount() {
    return this.http.get<any>(Common.baseUrl + '/Branch/GetBranchListWithCustomerCount/', {headers: this.reqHeader});
  }

  public getBranchList() {
    return this.http.get<any>(Common.baseUrl + '/Branch/GetBranchList/', {headers: this.reqHeader});
  }

  public getAllBranchList() {
    return this.http.get<any>(Common.baseUrl + '/Branch/GetAllBranchList/', {headers: this.reqHeader});
  }

  public getBranchById(data: any) {
    return this.http.get<any>(Common.baseUrl + '/Branch/GetBranchById/' + data, {headers: this.reqHeader});
  }

  public DeleteBranch(data: string) {
    return this.http.delete<any>(Common.baseUrl + '/Branch/DeleteBranch/' + data);
  }

  public getBranchListWithCustomerCountByParams(data: any) {
    return this.http.post<any>(Common.baseUrl + '/Branch/getBranchListWithCustomerCountByParams/', data );
  }

}
