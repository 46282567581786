<div>
  <div class="row">
    <div class="col-md-12">
      <div class="tile">
        <div class="tile-title-w-btn mt-4">
          <h1><i class="fa fa-th-list"></i> Branch Admin List</h1>
              <p *ngIf="userGroupType == 'SU'">
                <a class="btn btn-primary icon-btn" routerLink="/superadmin-reg"><i class="fa fa-plus"></i>Add Admin Branch</a>
              </p>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
      <div class="col-md-12">
          <div class="tile">
              <table class="table table-striped table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions">
              <thead>
                <tr>
                  <th>Serial</th>
                  <th>User Name</th>
                  <th>User Group</th>
                  <th>Password</th>
                  <th>Branch Name</th>
                  <th>Branch Status</th>
                  <th *ngIf="userGroupType == 'SU'">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of branchAdminData; let i = index" id="tr_{{item.userId}}">
                  <td>{{i+1}}</td>
                  <td>{{item.userLoginName}}</td>
                  <td>{{item.userGroupTitle}}</td>
                  <td>{{item.userPassword}}</td>
                  <td>{{item.branchName}}</td>
                  <td>{{item.userActive}}</td>
                  <td *ngIf="userGroupType == 'SU'">
                    <button class="btn btn-success btn-sm mr-2" (click)="edit(item.userId)"><i class="fa fa-edit"></i></button>
                    <!-- <button class="btn btn-danger btn-sm" (click)="delete(item.branchId)"><i class="fa fa-trash"></i></button> -->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        </div>
