import { ToastrService } from 'ngx-toastr';
import { Component, IterableDiffers, OnInit } from '@angular/core';
import { UserService } from 'src/app/view/services/user/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-branch-admin-list',
  templateUrl: './branch-admin-list.component.html',
  styleUrls: ['./branch-admin-list.component.scss']
})
export class BranchAdminListComponent implements OnInit {

  branchAdminData: any;
  public iterableDiffer = null;
  userGroupType: any;
  
 // data: Array<any>;

  constructor(private userService: UserService, private toastrService: ToastrService,
              private router: Router,private iterableDiffers: IterableDiffers)
              {
                this.iterableDiffer = iterableDiffers.find([]).create(null);
                //debugger;

               // this.data = new Array<any>();
                this.userService.getBranchAdminList()
                  .subscribe(
                    (data: any) => {
                      this.branchAdminData = data;
                      // console.log(this.branchAdminData);
                    },
                    error => {console.log(error); }
                    );
              }

              dtOptions: any = {};
ngOnInit() {
  this.userGroupType = localStorage.getItem('userGroupType');
}
ngDoCheck() {
  const lookup = {};
  //debugger;
  const items = this.branchAdminData.ProductArray;
  const changes = this.iterableDiffer.diff(items);
  if (changes) {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
    lengthMenu : [5, 10, 25],
      processing: true
    };
  }
}

  edit(id: any): any{
    // tslint:disable-next-line: no-debugger
    //debugger;
    this.router.navigate(['/superadmin-reg/' + id]);
  }


  // delete(id: any): any{
  // debugger;
  // this.branchService.DeleteBranch(id).subscribe(success => {
  //   this.toastrService.success(success.message, 'Data Delete Successfully.');
  //   this.branchService.getBranchList()
  //   .subscribe(
  //     (data: any) => {
  //       this.branchData = data;
  //       console.log(this.branchData);
  //     },
  //     error => {console.log(error); }
  //     );
  //   });
  // }

}
