import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RegistrationService } from '../services/user/registration';
import { AuthService } from '../services/auth/auth.service';
import { EncryptionDescryptionService } from '../services/auth/encryption-descryption.service';
import { MustMatch } from '../_helpers/must-match.validator';

@Component({
  selector: 'app-adminreg',
  templateUrl: './adminreg.component.html',
  styleUrls: ['./adminreg.component.scss']
})
export class AdminregComponent implements OnInit {
  gotoUrl = '';
  userGroup: any[] = [];
  groupId = '0';
  isAdminReg = true;
  constructor(private fb: FormBuilder,
              private router: Router,
              private toastr: ToastrService,
              private encryptObj: EncryptionDescryptionService,
              private activatedRoute: ActivatedRoute,
              private reg: RegistrationService,
              private authService: AuthService) {
                  if (authService.logedIn()){
                    this.router.navigate(['/admin-reg']);
                  }
               }

signupForm: FormGroup;
loginFormEncrypt: FormGroup;

ngOnInit(): void {
this.reg.getUserGroup().subscribe((data: any) => {
  this.userGroup = data;
  //console.log(this.userGroup);
  this.groupId = (this.router.url === '/login') ? data.filter(s => s.userGroupTitle === 'Seller')[0]?.userGroupId : '0';
  this.isAdminReg =  (this.router.url === '/login') ? false : true;
});
this.activatedRoute.queryParams.subscribe(params => {

  this.gotoUrl = params.returnUrl === undefined ? '' : params.returnUrl;
});
this.signupForm = this.fb.group({
userFullName: ['', [Validators.required]],
userLoginName: ['', [Validators.required]],
userMobile: ['', [Validators.required]],
userEmail: [''],
userPassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(15)]],
confirmPassword: ['', [Validators.required]],
userGroup: ['', [Validators.required]]
}, {
  validator: MustMatch('userPassword', 'confirmPassword')
});
}
// tslint:disable-next-line: typedef
onSubmit() {
  if (this.signupForm.get('userPassword').value !== this.signupForm.get('confirmPassword').value){
    this.toastr.error('Password And Confirm Password Not Match!', 'Error!');
    return;
  }
  if (this.signupForm.invalid) {
  this.toastr.error('Carefully fill the from', 'Error!');
  return;
  }
  this.loginFormEncrypt = this.fb.group({
    userFullName: this.encryptObj.encryptData(this.signupForm.get('userFullName').value),
    userLoginName: this.encryptObj.encryptData(this.signupForm.get('userLoginName').value),
    userMobile: this.encryptObj.encryptData(this.signupForm.get('userMobile').value),
    userEmail: this.encryptObj.encryptData(this.signupForm.get('userEmail').value),
    userPassword: this.encryptObj.encryptData(this.signupForm.get('userPassword').value),
    UserGroupId: this.signupForm.get('userGroup') != null ? this.signupForm.get('userGroup').value : ""
  });
  //console.log(this.loginFormEncrypt.value);

  this.authService.signup(this.loginFormEncrypt.value)
  .subscribe(data => {
    // console.log(data);
    // console.log(this.gotoUrl);
    if (this.gotoUrl === null){
      this.router.navigate(['/login']);
    }
    this.router.navigate([this.gotoUrl]);
  },
  error => {
  this.toastr.error(error, 'Login Failed');
  console.log(error);
  });
  }
  // tslint:disable-next-line: typedef
  gotoSignUp(){
    this.router.navigate(['/login'], { queryParams: { returnUrl: this.gotoUrl }});
  }
}
