<div>
    <div class="tile">
      <div class="tile-title-w-btn mt-4">
        <h1><i class="fa fa-edit"></i> Branch</h1>
      </div>
    </div>
  </div>
  
  <div class="row">
    <!-- <div>
      <p>Form value: {{BrandForm.value | json}}</p>
      <p>Form status: {{BrandForm.status | json}}</p>
    </div> -->
    <div class="col-md-12">
      <div class="tile">
        <div class="tile-body">
          <form [formGroup]="BranchForm" (ngSubmit)="onSubmit()" class="form-horizontal">
            <div class="form-group row">
              <div class="col-md-4">
                <label class="control-label">Branch Name</label>
                <input 
                    formControlName="BranchName"
                    id="BranchName" 
                    type="text"
                    placeholder="Enter Branch Name" 
                    class="form-control">
                    <div *ngIf="f.BranchName.touched && f.BranchName.invalid" class="alert alert-danger">
                      <div *ngIf="f.BranchName.errors.required">Name is required.</div>
                      <div *ngIf="f.BranchName.errors.maxLength">Name maximum 150 character.</div>
                      <div *ngIf="f.BranchName.errors.pattern">Only character & number allowed.</div>
                  </div>
              </div>
              <div class="col-md-4">
                <label class="control-label">Branch Status</label>
                <select id="BranchStatus" class="form-control"  formControlName="BranchStatus">
                  <option selected value="">Select Status </option>
                  <option value="A">Active</option>
                  <option value="I">Inactive</option>
                </select>
                <div *ngIf="f.BranchStatus.touched && f.BranchStatus.invalid" class="alert alert-danger">
                  <div *ngIf="f.BranchStatus.errors.required">Name is required.</div>
                </div>
              </div>
            </div>

            <div class="button-align">
              <button class="btn btn-primary btn-lg" type="submit">Submit</button>
            </div>
            
          </form>
        </div>
      </div>
    </div>
    
  </div>