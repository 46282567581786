<header class="app-header">
  <!-- Sidebar toggle button-->
  <a class="app-sidebar__toggle" href="javascript:void(0)" data-toggle="sidebar"
  aria-label="Hide Sidebar" (click) = "toggleClass($event,'toggled')"></a>
  <img src="/assets/images/miniso-logo.jpg" alt="Miniso Logo" style="width: 33px; height: 31px; margin: 10px;">
  <a class="app-header__logo" routerLink="/{{logoRoute}}" >MINISO E-CUSTOMER CARD</a>

<div class="app-nav" style=" margin-left: 455px;">
  <form [formGroup]="SearchForm" (ngSubmit)="onSubmit()" class="form-horizontal">
    <div class="row">
      <div class="col-md-5" style="padding-top: 2%;">
        <select class="form-control"  formControlName="iSerchField">
          <option selected value="">Select Search </option>
          <option value="Name">Name</option>
          <option value="MobileNo">MobileNo</option>
          <option value="UserEmail">UserEmail</option>
          <option value="Gender">Gender</option>
        </select>
      </div>
      <div class="app-search col-md-6">
        <input class="app-search__input" type="text" placeholder="Search" formControlName="iSearchId">
        <button class="app-search__button" type="submit"><i class="fa fa-search"></i></button>
      </div>
    </div>
  </form>
  <ul class="app-nav">
    <li class="dropdown" dropdown>
      <a class="app-nav__item text-light"
      data-toggle="dropdown-toggle" aria-label="Open Profile Menu" dropdownToggle>
        <i class="fa fa-user fa-lg"></i>
      </a>
      <ul class="dropdown-menu settings-menu dropdown-menu-right" id="userPanel" *dropdownMenu>
        <li><a class="dropdown-item" href="javascript:void(0)" *ngIf="isLogedIn()" (click)="logout()"><i class="fa fa-sign-out fa-lg" aria-hidden="true"></i>Log Out</a></li>
      </ul>
    </li>
  </ul>
</div>
</header>
