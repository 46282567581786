
    <div class="app-title">
        <div>
          <h1><i class="fa fa-th-list"></i> Stock Management</h1>
          <!-- <p>Basic bootstrap tables</p> -->
        </div>
        <ul class="app-breadcrumb breadcrumb">
          <li class="breadcrumb-item"><i class="fa fa-home fa-lg"></i></li>
          <li class="breadcrumb-item">Stock</li>
          <!-- <li class="breadcrumb-item active"><a href="javascript:;">Add Product</a></li> -->

        </ul>
      </div>
      <div class="row">
          <div class="col-md-12">
              <div class="tile">
               
                <div class="tile-title-w-btn">
                  <h3 class="title">Stock List</h3>
                  <!-- <p><a class="btn btn-primary icon-btn" routerLink="/productadd"><i class="fa fa-plus"></i>Add Product</a></p> -->
                </div>
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Category</th>
                      <th>Purchase Price</th>
                      <th>Sell Price</th>
                      <th>Total Sell</th>
                      <th>Stock</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>Napa</td>
                      <td>Medicine</td>
                      <td>50</td>
                      <td>80</td>
                      <td>1000</td>
                      <td>50</td>
                    </tr>
                    <tr>
                        <td>1</td>
                        <td>Rolex Watch</td>
                        <td>Watch</td>
                        <td>1050</td>
                        <td>1500</td>
                        <td>25000</td>
                        <td>5</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Medical Back pack</td>
                      <td>Medicine</td>
                      <td>1250</td>
                      <td>1450</td>
                      <td>20000</td>
                      <td>5</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>Sunflower Oil</td>
                      <td>Grocery</td>
                      <td>800</td>
                      <td>900</td>
                      <td>5500</td>
                      <td>4</td>
                    </tr>
                    
                  </tbody>
                </table>
              </div>
            </div>
            </div>    
  