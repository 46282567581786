import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Common } from '../common/common';

@Injectable({
  providedIn: 'root'
})
export class SlideService {

  constructor(private http: HttpClient) { }
  // tslint:disable-next-line: typedef
  public AddSlide(data: any) {
    return this.http.post<any>(Common.baseUrl + 'Slide/EntrySlide', data);
  }
  // tslint:disable-next-line: typedef
  public UpdateSlide(data: any) {
    return this.http.post<any>(Common.baseUrl + 'Slide/EditSlide/', data);
  }
  // tslint:disable-next-line: typedef
  public getSlideList() {
    return this.http.get<any>(Common.baseUrl + 'Slide/GetSlide');
  }

  // tslint:disable-next-line: typedef
  public getSlide(data: any) {
    return this.http.get<any>(Common.baseUrl + 'Slide/GetSlideById/' + data);
  }

  // tslint:disable-next-line: typedef
  public DeleteSlide(data: string) {
    return this.http.delete<any>(Common.baseUrl + 'Slide/DeleteSlide/' + data);
  }
}
