
    <div class="app-title">
        <div>
          <h1><i class="fa fa-th-list"></i> Shop list</h1>
        </div>
        <ul class="app-breadcrumb breadcrumb">
          <li class="breadcrumb-item"><i class="fa fa-home fa-lg"></i></li>
          <li class="breadcrumb-item">Shop list</li>
          <li class="breadcrumb-item active"><a routerLink="/shop-add">Add Shop</a></li>
        </ul>
      </div>
      <div class="row">
          <div class="col-md-12">
              <div class="tile">
                
                <div class="tile-title-w-btn">
                    <h3 class="title">Shop List</h3>
                    <p><a class="btn btn-primary icon-btn" routerLink="/shop-add"><i class="fa fa-plus"></i>Add Shop</a></p>
                  </div>
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Shop Name</th>
                        <th>Mobile</th>
                        <th>Email</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>Swapno</td>
                        <td>1234567889</td>
                        <td>test@gmail.com</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>Agora</td>
                        <td>017211545554</td>
                        <td>kacob@gmail.comt</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>Shan</td>
                        <td>011998256466</td>
                        <td>ales@twitter.com</td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>Pinar</td>
                        <td>017126464646</td>
                        <td>netr@fat.com</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          
      </div>
  