import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-preview-branches',
  templateUrl: './preview-branches.component.html',
  styleUrls: ['./preview-branches.component.scss']
})
export class PreviewBranchesComponent implements OnInit {

  @Input() item : any;

  constructor(private toastrService: ToastrService, 
              private router: Router) { }

  ngOnInit(): void {
  }

  viewCustomerReport(id: any): any{
    if(id != null) {
      this.router.navigate(['/chart/' + id]);
    }
    else{
      this.toastrService.error('No Data Found', 'Error!');
    }
  }

}
