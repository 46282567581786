import { ConnectionErrorComponent } from './view/services/auth/connection-error/connection-error.component';
import { BranchAdminListComponent } from './view/pages/app-admin/branch-admin-list/branch-admin-list.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserAddComponent } from './view/pages/app-user/user-add/user-add.component';
import { UserListComponent } from './view/pages/app-user/user-list/user-list.component';
import { AdminloginComponent } from './view/adminlogin/adminlogin.component';
import { AuthGuard } from './view/services/auth/auth.guard';
import { SuperAdminComponent } from './view/pages/app-admin/super-admin/super-admin.component';
import { MinisoRegistrationComponent } from './view/pages/miniso-registration/miniso-registration.component';
import { BranchAddComponent } from './view/pages/app-branch/branch-add/branch-add.component';
import { BranchListComponent } from './view/pages/app-branch/branch-list/branch-list.component';
import { CustomerDetailsComponent } from './view/pages/app-customer/customer-details/customer-details.component';
import { CommonLayoutComponent } from './view/_layout/common-layout/common-layout.component';
import { SearchResultComponent } from './view/pages/app-customer/search-result/search-result.component';
import { CustomerListComponent } from './view/pages/app-user/customer-list/customer-list.component';
import { SessionExpiredComponent } from './view/services/auth/session-expired/session-expired.component';
import { ChartComponent } from './view/_layout/chart/chart.component';
import { DashboardComponent } from './view/_layout/dashboard/dashboard.component';
import { HomeComponent } from './view/_layout/home/home.component';
import { CommonComponent } from './view/_layout/common/common.component';
import { OutletComponent } from './view/pages/app-outlet/outlet.component';
import { BadReviewListComponent } from './view/pages/app-user/badreview-list/badreview-list.component';
import { BranchReportComponent } from './view/pages/app-user/branch-report/branch-report.component';


const routes: Routes = [
  // { path: '', component: AdminloginComponent,  pathMatch: 'full' },
  { path: 'login', component: AdminloginComponent },
  { path: 'chart', component: HomeComponent },
  { path: 'chart/:id', component: ChartComponent },
  { path: 'branch/:id', component: ChartComponent },
  { path: 'home', component: HomeComponent },
  {
    path: '', component: CommonLayoutComponent,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    children: [
      { path: 'search-result', component: SearchResultComponent },
      { path: 'branch-add', component: BranchAddComponent },
      { path: 'branch-add/:id', component: BranchAddComponent },
      { path: 'branch-list', component: BranchListComponent },
      { path: 'branch-admin-list', component: BranchAdminListComponent },
      { path: 'customer-list', component: CustomerListComponent },
      { path: 'superadmin-reg', component: SuperAdminComponent },
      { path: 'superadmin-reg/:id', component: SuperAdminComponent },
      { path: 'user-add', component: UserAddComponent },
      { path: 'user-list', component: UserListComponent },
      { path: 'user-list/:id', component: UserListComponent },
      { path: 'badreview-list/:id', component: BadReviewListComponent },
      { path: 'customer-details/:id', component: CustomerDetailsComponent },
      { path: 'miniso-registration', component: MinisoRegistrationComponent },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'outlet', component: OutletComponent },
      { path: 'report', component: BranchReportComponent },
    ]
  },
  { path: 'sessionExpired', component: SessionExpiredComponent },
  { path: 'connectionError', component: ConnectionErrorComponent },
  // {path: '*', redirectTo: '/dashboard', pathMatch: 'full'},

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
