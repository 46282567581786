<app-header></app-header>
<app-side-menu></app-side-menu>

<main class="app-content">
    <!-- <div class="row">
        <div class="col-md-6 col-lg-6 col-xl-3" *ngFor="let item of data; let i = index" id="tr_{{item.branchId}}">
            <div class="widget-bg-color-icon card-box fadeInDown animated">
                <div class="bg-icon bg-icon-info pull-left" (click)="viewCustomerReport(item.branchId)">
                    <i class="md md-attach-money text-info"><span class="pointer" style="font-size: medium; font-style: normal;">{{item.branchName}}</span></i>
                </div>
                <div class="text-right">
                    <h3 class="text-dark"><b class="counter">{{item.noOfBranch}}</b></h3>
                    <p class="text-muted mb-0" style="font-size: smaller">Total Customer</p>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
    </div>     -->
    <div class="row">
        <div *ngFor="let item of data; let i = index" id="tr_{{item.branchId}}">
            <app-preview-branches [item]="item"></app-preview-branches>
        </div>
    </div>
    <div>
        <app-preview-branches-detail></app-preview-branches-detail>
    </div>
    <router-outlet></router-outlet>
</main>
<!-- <router-outlet></router-outlet> -->
