import { Component, HostListener } from '@angular/core';
import { AuthService } from './view/services/auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'miniso-admin';
  constructor(private authService: AuthService) { }

// @HostListener('window:beforeunload', ['$event'])
//  onWindowClose(event: any): void {
//    debugger;
//   this.authService.logout();

//    event.preventDefault();
//    event.returnValue = false;

// }
}
