<div class="testimonial" id="testimonial">
  <div class="container">

    <div class="row Reg_Sidebar_Form" id="RegFormDiv">
        <div class="col-md-6">
            <br>
            <div>
              <div class="row">
                <div class="square">
    
                </div>
                <div>
                  <h6>Give your information</h6>
                </div>
              </div>
            </div>
            <h4>Registration form : </h4>
            <form [formGroup]="RegForm" (ngSubmit)="onSubmit()">
              <div class="form-row">
                <div class="form-group col-md-10">
                  <input placeholder="Franchise Name" class="form-control InputBox" type="text" formControlName="FranchiseName">
                </div>
                <div class="Staric">
                  <span class="required">*</span>
                </div>
                <p *ngIf="RegForm.get('FranchiseName').hasError('required') && RegForm.value.touched">
                  <small class="text-danger">Please input Shop Name </small>
                </p>
              </div>
    
              <div class="form-row">
                <div class="form-group col-md-10">
                  <input placeholder="Contact Person Name" class="form-control InputBox" type="text"
                    formControlName="ContactPersonName">
                </div>
              </div>
    
              <div class="form-row">
                <div class="form-group col-md-10">
                  <input placeholder="Address" class="form-control InputBox" type="text" formControlName="Address">
                </div>
              </div>
              <div class="form-row">
                <span class="checkWhatsapp">Please select checkbox if whatsapp available</span>
              </div>
              <div class="form-row">
                <div class="form-group col-md-5">
                  <input placeholder="TelePhone LandLine" class="form-control InputBox" required="required" type="text"
                    formControlName="TelephoneLandline">
                </div>
                <div *ngIf="RegForm.get('TelephoneLandline').touched && RegForm.value.TelephoneLandline.invalid"
                  class="alert alert-danger">
                  <div *ngIf="RegForm.get('TelephoneLandline').hasError('pattern')">Please, Enter Mobile Number.</div>
                </div>
    
                <div class="form-group col-md-5 input-group">
                  <input placeholder="Mobile No" class="form-control InputBox" required="required" type="text"
                    formControlName="MobileNo">
                  <div class="input-group-append">
                    <span class="input-group-text" id="basic-addon2">
                      <input type="checkbox" value="Whatsapp" id="Whatsapp" formControlName="Whatsapp">
                    </span>
                  </div>
                </div>
    
                <div class="Staric">
                  <span class="required">*</span>
                </div>
    
                <div *ngIf="RegForm.get('MobileNo').touched && RegForm.value.MobileNo1.invalid" class="alert alert-danger">
                  <div *ngIf="RegForm.get('MobileNo').hasError('required')">Please, Enter Your Mobile Number.</div>
                  <div *ngIf="RegForm.get('MobileNo').hasError('pattern')">Please, Enter Mobile Number.</div>
                </div>
              </div>
    
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label>
                    <!-- <i>Read Terms & Condition </i> -->
                    <span class="termscond">Terms & Condition</span>
                  </label>
                  <p (click)="clicked()" style="color: green;"><a href="javascript:">Read More..</a></p>
                  <div *ngIf='visibility'>
                    <div class="col-md-10 AnyClass">
                      <ul class="list-group">
                        <li class="list-group-item d-flex justify-content-between align-items-center agree-item"><span
                            class="badge badge-primary ">1</span>We are responsible of all the marketing and announcements
                          to reach every customer in UAE</li>
                        <li class="list-group-item d-flex justify-content-between align-items-center agree-item"><span
                            class="badge badge-primary ">2</span>We don’t have any responsibility on the products quality or
                          warranty.</li>
                        <li class="list-group-item d-flex justify-content-between align-items-center agree-item"><span
                            class="badge badge-primary ">3</span>Shops responsible of all the products quality and should
                          provide all the products details and photos and videos for customers</li>
                        <li class="list-group-item d-flex justify-content-between align-items-center agree-item"><span
                            class="badge badge-primary ">4</span>Invoices generated from shop cannot be modified once
                          published only can be cancelled.</li>
                        <li class="list-group-item d-flex justify-content-between align-items-center agree-item"><span
                            class="badge badge-primary ">5</span>Customer feedback is very essentials and will be displayed
                          in the shop information</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row form-group col-md-12">
                <table>
                  <tr>
                    <td>
                      <input type="checkbox" value="" id="invalidCheck2" formControlName="AgreeCheckBox">
                    </td>
                    <td>
                      <small style="margin-left: 0.5rem;">I Agree With Terms & Condition </small>
                    </td>
                  </tr>
                </table>
              </div>
              <div class="form-row">
                <button type="submit" [disabled]="!RegForm.get('AgreeCheckBox').value"
                  class="btn btn-danger col-md-10">Submit</button>
              </div>
            </form>
          </div>
      <div class="col-md-6 regformBackImage">
        <div class="row">
          <div class="col-md-12" id="UpperList">
            <h1>
              We Are Launching In July
            </h1>
            <ul>
              <li>No Commission From Any Deal Sell As Much As You Can</li>
              <li>Registration Fees For Each Shop Monthly (100 AED) Or Yearly (1000 AED)</li>
              <li>Early Bird Register For Yearly Plan With 50% Discount(15 Days Guarantee With Fully Refund)</li>
              <li>We Will Be Live In July --- Register Before Launching And enjoy The Discount For The Full Year.</li>
              <li>We Are responsible Of All Marketing To Reach Every Home In UAE And Future Marketing Plan To Cover</li>
            </ul>
          </div>
          <div class="col-md-12" id="LowerLisy">
            <h1>
              Why Do you need UAE dragon:
            </h1>
            <ul>
              <li>Your Shop will be listed in the largest shop dictionary in UAE</li>
              <li>Your products will reach everyone in whole UAE</li>
              <li>Customers easily find and contact you directly without visiting your branch</li>
              <li>Customers will have online access to reach your shop</li>
              <li>You don’t need to worry about operation time anymore</li>
              <li>just be online and we will make sure that the customer can reach you</li>
            </ul>
          </div>

        </div>

      </div>
    </div>
  </div>
</div>