import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpRequest, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/internal/operators/map';
import { Observable } from 'rxjs';
import { Route } from '@angular/compiler/src/core';
import { Router } from '@angular/router';
import { Common } from '../../../services/common/common';
import { CategoryService } from '../../../services/category/category.service';



@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss']
})
export class CategoryListComponent implements OnInit {
  categoris: any[] = [];
  icon: string = Common.baseUrl + '/images/';
  constructor(private http: HttpClient, private router: Router,
              private categoryService: CategoryService) { }

  ngOnInit(): void {
    // this.http.get(Common.baseUrl + '/category/get').subscribe((data: any[]) => {
    //   console.log(data);
    //   this.categoris = data;
    // });
    this.categoryService.getCategoryList().subscribe((data: any[]) => {
      console.log(data);
      this.categoris = data;
      if (data.length === 0) {
        (document.getElementById('tr_with_data') as HTMLTableElement).style.display = 'none';
      }
      else{
        (document.getElementById('tr_with_No_data') as HTMLTableElement).style.display = 'none';
      }
    });
  }
  // tslint:disable-next-line: typedef
  edit(categoryId){
    this.router.navigate(['/category-add/' + categoryId]);
  }
  // tslint:disable-next-line: member-ordering
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      // Authorization: 'my-auth-token'
    })
  };
  // httpOptions.headers =
  // httpOptions.headers.set('Authorization', 'my-new-auth-token');
  // tslint:disable-next-line: typedef
  delete(id: number){
    const endPoints = '?categoryId=' + id;
    this.http.delete(Common.baseUrl + '/category/delete' + endPoints).subscribe((res: any) => {
        if (res.message === 'Success'){
          (document.getElementById('tr_' + id) as HTMLTableRowElement).remove();
        }
      });
  }
}
