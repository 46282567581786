<div>
  <div class="tile">
    <div class="tile-title-w-btn mt-4">
      <h1><i class="fa fa-th-list"></i> Customer List</h1>
    </div>
  </div>
</div>

<div class="row">
    <div class="col-md-12" #TABLE #table>
        <div class="tile">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>Serial</th>
                <th>Name</th>
                <th>Mobile No</th>
                <th>Email</th>
                <th>Gender</th>
                <!-- <th>I would be interested in receiving information about</th>
                <th>How did you get to know about us?</th>
                <th>Did you see the products online that you have purchased?</th>
                <th>I would like to receive commercials via-</th>
                <th>Did one or more associates offer to help you?</th>
                <th>Remarks</th> -->
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of searcReult; let i = index" id="tr_{{item.customerId}}">
                <td>{{i + 1}}</td>
                <td><a routerLink="/customer-details/{{item.customerId}}">{{item.name}}</a></td>
                <td>{{item.userMobile}}</td>
                <td>{{item.userEmail}}</td>
                <td>{{item.gender}}</td>
                <!-- <td>{{item.interestedInfoId}}</td>
                <td>{{item.socialInfoId}}</td>
                <td>{{item.isProductOnlineName}}</td>
                <td>{{item.rcvComVia}}</td>
                <td>{{item.associateOfferName}}</td>
                <td>{{item.remarks}}</td> -->
              </tr>
            </tbody>
          </table>
        </div>
      </div>
</div>
  