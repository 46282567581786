import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Common } from '../common/common';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private http: HttpClient) { }
  // tslint:disable-next-line: typedef
  public AddProduct(data: any) {
    return this.http.post<any>(Common.baseUrl + '/ProductList/InsertProduct', data);
  }
// tslint:disable-next-line: typedef
public UpdateProduct(data: any) {
  return this.http.post<any>(Common.baseUrl + '/ProductList/UpdateProduct', data);
}
  // tslint:disable-next-line: typedef
  public getProductList() {
    return this.http.get<any>(Common.baseUrl + '/ProductList/GetProductList/');
  }

  // tslint:disable-next-line: typedef
  public getProductById(data: any) {
    return this.http.get<any>(Common.baseUrl + '/ProductList/GetProductById/' + data);
  }

}
