import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shop-list-add-cat',
  templateUrl: './shop-list-add-cat.component.html',
  styleUrls: ['./shop-list-add-cat.component.scss']
})
export class ShopListAddCatComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
