import { Component, OnInit, Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { Common } from '../../../services/common/common';

@Component({
  selector: 'app-advertise-add',
  templateUrl: './advertise-add.component.html',
  styleUrls: ['./advertise-add.component.scss']
})
export class AdvertiseAddComponent implements OnInit {

  constructor(private formBuilder: FormBuilder, private http: HttpClient,
              private toastrService: ToastrService, private activatedRoute: ActivatedRoute) { }

// tslint:disable-next-line: typedef
get f(){
return this.entryForm.controls;
}
advertiseType: any[] = [];
selectedAdvertise = 'MP';
advertise: any[] = [];
entryForm: FormGroup;
formSubmit: FormGroup;
id = 0;
image: string = Common.baseUrl + '/images/';
imgData: any[] = [];
imgId = 0;
adLink: string[] = [];
file = '';
ngOnInit(): void {
// tslint:disable-next-line: no-unused-expression
const id = this.activatedRoute.snapshot.paramMap.get('id');
this.form();
this.loadslide(id);
this.selectedAdvertise = 'MP';
this.advertiseType = [
  { name: 'MP', title: 'Mega-Product'},
  { name: 'FP', title: 'Feacher-Product'}
];

}
loadData(advertiseId){
this.advertise.find(x => {
if (+x.id === +advertiseId){
this.id = x.id;
// document.getElementById('showImage').style.display = 'block';
const list = new DataTransfer();
const file = new File(['content'], x.img);
list.items.add(file);
const myFileList = list.files;
(document.getElementById('Title') as HTMLInputElement).value = x.title;
(document.getElementById('Description') as HTMLInputElement).value = x.description;
(document.getElementById('file') as HTMLInputElement).files = myFileList;
(document.getElementById('AdvertiseType') as HTMLSelectElement).value = this.selectedAdvertise;
(document.getElementById('Link') as HTMLInputElement).value = x.url;
// (document.getElementById('AdvertiseType') as HTMLInputElement).files = myFileList;
this.image += x.img;

this.entryForm.setValue({
Title: x.title,
Description: x.description,
AdvertiseType: x.selectedAdvertise,
Url: x.url,
Img: '',
// fileSource: x.icon64String,
file: '',
});
}
});
}
loadslide(advertiseId){
  this.http.get(Common.baseUrl + 'api/adlist/get').subscribe((data: any[]) => {
  this.advertise = data;
  if (advertiseId !== null){
      this.loadData(advertiseId);
    }
  });
}
// tslint:disable-next-line: typedef
form() {
this.entryForm = this.formBuilder.group({
Description: new FormControl(''),
Title: new FormControl(''),
AdvertiseType: new FormControl(this.selectedAdvertise),
file: new FormControl('', [Validators.required, Validators.minLength(1)]),
Img:  new FormControl(''),
Url: new FormControl(''),
IsActive: new FormControl(true)
// fileSource: new FormControl('', [Validators.required])
});
}
// [selected]="m.name === selectedAdvertise"
onSelectFile(event) {
if (event.target.files && event.target.files[0]) {
const filesAmount = event.target.files.length;
for (let i = 0; i < filesAmount; i++) {
      const reader = new FileReader();
      // tslint:disable-next-line: no-shadowed-variable
      reader.onload = (event: any) => {
        // console.log(event.target.result);
        this.imgData.push({id: this.imgId++, url: event.target.result});
      };
      reader.readAsDataURL(event.target.files[i]);
}
}
}
imgDelete(iId: number){
this.imgData.forEach( (item, index) => {
if (item.id === iId){
this.imgData.splice(index, 1);
}
});
}

// tslint:disable-next-line: typedef
onSubmit(){
const e = (document.getElementById('AdvertiseType')) as HTMLSelectElement;
  // const sel = e.selectedIndex;
  // const opt = e.options[sel];
const AdvertiseTypeValue = e.value;
this.imgData.forEach( (item, index) => {
  this.adLink.push(item.url);
});
(document.getElementById('btnSubmit')as HTMLButtonElement).classList.add('disabled');
// console.log(this.entryForm.value);
let url = 'api/adlist/entry';
if (this.id !== null && this.id !== 0){
url = 'api/adlist/edit';
this.formSubmit = this.formBuilder.group({
Id: this.id,
Description: [this.entryForm.get('Description').value],
Title: [this.entryForm.get('Title').value],
IsActive: (document.getElementById('isActive') as HTMLInputElement).checked ? true : false,
AdvertiseType: AdvertiseTypeValue,
// Icon: [this.entryForm.get('file').value]
Url: [this.entryForm.get('Url').value],
Img: [this.adLink.join('|')],
});
}
else{
this.formSubmit = this.formBuilder.group({
Description: [this.entryForm.get('Description').value],
Title: [this.entryForm.get('Title').value],
IsActive: [(document.getElementById('isActive') as HTMLInputElement).checked ? true : false],
AdvertiseType: AdvertiseTypeValue,
Img: [this.adLink.join('|')],
Url: [this.entryForm.get('Url').value]
// Img64String: [JSON.stringify(this.url)]
});
}
// const params = new HttpParams();
// params.append('Img', this.imgData.join('|'));
// params.append('Url', this.entryForm.get('Link').value);
// params.append('Title', this.entryForm.get('Title').value);
// params.append('Description', this.entryForm.get('Description').value);
// this.http.post(Common.baseUrl + url, { Slide: params })
this.http.post(Common.baseUrl + url, this.formSubmit.value)
.subscribe((res: any) => {
if (res.message === 'Success'){
this.loadslide(null);
// tslint:disable-next-line: radix
this.toastrService.success('Save Successfully', 'Success');
this.Clear();
}
else{
this.toastrService.error(res.message, 'Warnning!');
}
});
}
Clear(){
// (document.getElementById('file') as HTMLInputElement).value = '';
// (document.getElementById('file') as HTMLInputElement).setAttribute('value', 'test');
this.file = '1.jpg';
// const input = document.querySelector('input[type=file]') as HTMLInputElement;
// input.value = 'foo';
(document.getElementById('Description') as HTMLInputElement).value = '';
(document.getElementById('Title') as HTMLInputElement).value = '';
// (document.getElementById('showImage') as HTMLInputElement).style.display = 'none';
(document.getElementById('btnSubmit')as HTMLButtonElement).classList.remove('disabled');
}

}
