
  <div class="tile">
    <div class="tile-title-w-btn mt-4">
      <h1><i class="fa fa-list"></i> Customer Details</h1>
    </div>
  </div>

  <div class="row">
      <div class="col-md-12">
          <div class="tile">
            <div *ngFor="let custumerData of customerDetails; let i = index">
              <div class="col-12">
                  <div class="row">
                    <div class="col-md-3">
                        <h6>Name</h6>
                        <input class="form-control" type="text" value="{{custumerData.name}}" readonly>
                    </div>
                    <div class="col-md-3">
                        <h6>Phone No</h6>
                        <input class="form-control"  type="text" value="{{custumerData.userMobile}}" readonly>
                    </div>
                    <div class="col-md-3">
                        <h6>Email</h6>
                        <input class="form-control" type="text" value="{{custumerData.userEmail}}" readonly>
                    </div>
                    <div class="col-md-3">
                        <h6>Gender</h6>
                        <div>
                          <td *ngIf="custumerData.gender === 'M'">
                            <input type="checkbox"  [checked]="true" name="Gender" value="M" disabled> Male
                            <input type="checkbox"   name="Gender" value="F" disabled> Female
                          </td>
                          <td *ngIf="custumerData.gender === 'F'">
                            <input type="checkbox"  name="Gender" value="M" disabled> Male
                            <input type="checkbox" [checked]="true" name="Gender" value="F" disabled> Female
                          </td>
                          <td *ngIf="custumerData.gender === null || custumerData.gender === '' ">
                            <input type="checkbox"  name="Gender" value="M" disabled> Male
                            <input type="checkbox"  name="Gender" value="F" disabled> Female
                          </td>
                        </div>

                    </div>
                  </div>
              </div>
              <div class="col-12 mt-5">
                  <div class="row">
                    <div class="col-md-4">
                        <h4 class="title">Age Group</h4>
                        <div  *ngFor="let order of ageGroupData; let i = index">
                          <td *ngIf="custumerData.ageGroupId === i">
                            <input type="checkbox" [checked]="true" disabled>
                            {{ageGroupData[i].agE_GROUP_RANGE}}
                          </td>
                          <td *ngIf="custumerData.ageGroupId !== i">
                            <input type="checkbox" [checked]="false" disabled>
                            {{ageGroupData[i].agE_GROUP_RANGE}}
                          </td>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <h4 class="title">I would be interested in receving information about- </h4>
                          <div *ngFor="let item of custumerData.interestedInfoDtos">
                            <input type="checkbox" [checked]="item.isSelect" disabled>
                            {{item.interestedInfoName}}
                          </div>
                    </div>
                    <div class="col-md-4">
                        <h4 class="title">How did you get to know about us?</h4>

                          <div *ngFor="let item1 of custumerData.socialInfoDtos">
                              <input type="checkbox" [checked]="item1.isSelect" disabled>
                              {{item1.socialInfoName}}
                          </div>
                    </div>
                  </div>
              </div>
              <div class="col-12 mt-5">
                <div class="row">
                    <div class="col-md-4">
                        <h5 class="title">Did you see the products online that you have purchased?</h5>
                        <div>
                          <td *ngIf="custumerData.isProductOnline === true">
                            <input type="checkbox"  [checked]="true" name="Yes" value="true" disabled> Yes
                            <input type="checkbox"  name="No" value="false" disabled> No
                          </td>
                            <td *ngIf="custumerData.isProductOnline === false">
                              <input type="checkbox"  name="Yes" value="true" disabled> Yes
                              <input type="checkbox" [checked]="true" name="No" value="false" disabled> No
                            </td>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <h5 class="title">Did one or more associate offer to help you?</h5>
                        <div>
                          <td *ngIf="custumerData.associateOffer === true">
                            <input type="checkbox"  [checked]="true" name="Yes" value="true" disabled> Yes
                            <input type="checkbox"   name="No" value="false" disabled> No
                          </td>
                            <td *ngIf="custumerData.associateOffer === false">
                              <input type="checkbox"  name="Yes" value="true" disabled> Yes
                              <input type="checkbox" [checked]="true" name="No" value="false" disabled> No
                            </td>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <h5 class="title">I would like to receive commercial via?</h5>
                        <div>
                          <td *ngIf="custumerData.rcvComVia === 'Email,'">
                            <input type="checkbox"  [checked]="true" name="Email" value="Email" disabled> Email
                            <input type="checkbox" disabled> SMS
                            <!-- <input type="checkbox" disabled> Email,SMS -->
                          </td>
                          <td *ngIf="custumerData.rcvComVia === 'SMS,'">
                            <!-- <input type="checkbox"  name="Email" value="Email" disabled> Email -->
                            <input type="checkbox" [checked]="true" name="SMS" value="SMS" disabled> SMS
                          </td>
                          <td *ngIf="custumerData.rcvComVia === 'Email,SMS,'">
                            <input type="checkbox" [checked]="true" disabled> Email
                            <input type="checkbox" [checked]="true" disabled> SMS
                            <!-- <input type="checkbox" [checked]="true">Email,SMS -->
                          </td>
                          <td *ngIf="custumerData.rcvComVia === 'SMS,Email'">
                            <input type="checkbox" disabled> Email
                            <input type="checkbox" disabled> SMS
                          </td>
                          <td *ngIf="custumerData.rcvComVia === ''">
                            <input type="checkbox" disabled> Email
                            <input type="checkbox" disabled> SMS
                          </td>
                        </div>
                    </div>
                </div>
              </div>
              <div class="col-12 mt-5">
                <div class="row">
                  <div class="col-md-3">
                      <h6>Your Area</h6>
                      <input class="form-control" type="text" value="{{custumerData.city}}" readonly placeholder="example(banani)">
                  </div>
                  <div class="col-md-3">
                      <h6>Your Signature</h6>
                      <img src="{{custumerData.signatureUrl}}" alt="signature"height="150" width="200px" style="margin: 3px;">
                  </div>
                  <div class="col-md-3" style="display: none;">
                      <h6>Attended By</h6>
                      <input class="form-control" type="text" value="{{custumerData.attendedBy}}" readonly>
                  </div>
                  <div class="col-md-3">
                      <h6>Billing Range</h6>
                      <input class="form-control" type="text" value="{{custumerData.billingRange}}" readonly>
                  </div>
                </div>
              </div>
              <div class="col-md-12 mt-5">
                <div class="row">
                  <div class="col-md-8">
                    <div class="tile">
                      <table class="table table-striped table-bordered table-sm row-border hover">
                        <thead>
                          <tr>
                            <th>Overall Experence</th>
                            <th>Feedback Date</th>
                            <th>Outlet</th>
                            <th>Billing Range</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let customerFeedback of custumerData.customerFeedbackDtos let i = index">
                            <td>{{customerFeedback.overallExperienceName}}</td>
                            <td>{{customerFeedback.createdDate | date: 'dd/MM/yyyy hh:mm:ss'}}</td>
                            <td>{{customerFeedback.branchName}}</td>
                            <td>{{customerFeedback.billingRange}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                  </div>
                  <div class="col-md-4">
                    <div class="tile">
                      <table class="table table-striped table-bordered table-sm row-border hover">
                        <thead>
                          <tr>
                            <th>Remarks</th>
                            <th>Remarks Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let customerFeedback of custumerData.customerFeedbackDtos let i = index">
                            <td>{{customerFeedback.remarks}}</td>
                            <td>{{customerFeedback.createdDate | date: 'dd/MM/yyyy hh:mm:ss'}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  </div>



