import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { SellerService } from '../../../services/seller/seller.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { NgxSpinnerService } from 'ngx-spinner';
import { Common } from '../../../services/common/common';

@Component({
  selector: 'app-seller-list',
  templateUrl: './seller-list.component.html',
  styleUrls: ['./seller-list.component.scss']
})
export class SellerListComponent implements OnInit {

 // debugger;
  data: Array<any>;
  categoris: any[] = [];

  constructor(
    private sellerService: SellerService,
    private router: Router,
    private http: HttpClient,
    private SpinnerService: NgxSpinnerService
    ) {
       this.getSellerList();
   }

  ngOnInit(): void {
  }

  getSellerList(): any
  {
    // tslint:disable-next-line: no-debugger
    //debugger;
    this.data = new Array<any>();
    this.SpinnerService.show();
    this.sellerService.getSellerList()
   .subscribe(
     data => {
      this.data = data;
      this.data.forEach(item => item.shopImageName = Common.baseUrl + '/images/' + item.shopImageName);
      console.log(this.data);
      this.SpinnerService.hide();
     },
     error => {console.log(error);
               this.SpinnerService.hide();
    }
     );
  }

  edit(sellerId): any {
   // this.router.navigate(["/seller-add/" + seller_Id]);
    this.router.navigate(['/seller/' + sellerId]);
  }

  delete(sellerId): any {
        Swal.fire({
          title: 'Are you sure want to remove?',
          text: 'You will not be able to recover this file!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, keep it'
        }).then((result) => {
          if (result.value) {
             this.sellerService.deleteSeller(sellerId).subscribe(
              data => {
                 Swal.fire(
                  'Deleted!',
                  'Your imaginary file has been deleted.',
                  'success'
                );
                 this.getSellerList();
                 },
                 error => {console.log(error); }
               );

          } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire(
              'Cancelled',
              'Your imaginary file is safe :)',
              'error'
            );
          }
        });
    }
}
