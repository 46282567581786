import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BranchService } from '../../services/branch/branch.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../services/user/user.service';
import { Chart, registerables } from 'chart.js';
import { DashboardService } from '../../services/dashboard/dashboard.service';

@Component({
  selector: 'app-common',
  templateUrl: './common.component.html',
  styleUrls: ['./common.component.scss']
})
export class CommonComponent implements OnInit {
  data: Array<any>;
  customerData: Array<any>;

  ageGroupChart:any;
  billingRangeChart:any;
  experienceChart:any;
  interestedCategoryChart:any;
  gotToKnowChart:any;

  ageGroupConfig:any;
  billingRangeConfig:any;
  experienceConfig:any;
  interestedCategoryConfig:any;
  gotToKnowConfig:any;

  constructor(private branchService: BranchService, private toastrService: ToastrService, private router: Router) {
      
    this.data = new Array<any>();

    this.branchService.getBranchList().subscribe((data: any) => {
      this.data = data;
    },
    error => {
      console.log(error); 
    });
    
  }

  ngOnInit(): void {  }

  viewCustomerReport(id: any): any{
    //debugger;
    this.customerData = new Array<any>();
    if(id != null) {
      //this.customerData.filter(x => x.cmP_BRANCH_ID == id);
      this.router.navigate(['/user-list/' + id]);
    }
    else{
      this.toastrService.error('No Data Found', 'Error!');
    }
    // this.customerService.getCustomerListById(id)
    // .subscribe(
    //   (customerData: any) => {
    //     this.customerData = customerData;
        
    //     console.log(this.customerData);
    //   },
    //   error => {console.log(error); }
    // );
  }

}
