
    <div class="app-title">
        <div>
          <h1><i class="fa fa-edit"></i> Slide</h1>
        </div>
        <ul class="app-breadcrumb breadcrumb">
          <li class="breadcrumb-item"><i class="fa fa-home fa-lg"></i></li>
          <li class="breadcrumb-item">Slide</li>
          <li class="breadcrumb-item"><a routerLink="/slides">Slide List</a></li>
        </ul>
      </div>
      
      <div class="row">
        <div >
          <p>Form value: {{entryForm.value | json}}</p>
          <p>Form status: {{entryForm.status | json}}</p>
        </div>
        <div class="col-md-12">
          <div class="tile">
            <h3 class="tile-title">Add Slide</h3>
            <div class="tile-body">
              <form [formGroup]="entryForm" (ngSubmit)="onSubmit()" class="form-horizontal">
                <div class="form-group row">
                  <label class="control-label col-md-2">Title</label>
                  <div class="col-md-8">
                    <input type="text" 
                      formControlName="Title"
                      id="Title" 
                      name="Title"
                      placeholder="Enter Slide Title" 
                      class="form-control"/>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="control-label col-md-2">Description</label>
                  <div class="col-md-8">
                    <textarea 
                      formControlName="Description"
                      id="Description" 
                      type="text"
                      placeholder="Enter Slide Description" 
                      class="form-control"></textarea>
                  </div>
                </div>
  
           
                <div class="form-group row">
                    <label for="file" class="control-label col-md-2">Slide Image(s)</label>
                    <div class="col-md-12">
                    <input type='file' (change)="onSelectFile($event)" id="file"  class="form-control"
                    accept="image/*" multiple value={{file}}/>
                        <!-- <input 
                            formControlName="file"
                            id="file" 
                            type="file" 
                            class="form-control"
                            accept="image/*"
                            (change)="onFileChange($event)"/> -->
                        <div *ngIf="f.file.touched && f.file.invalid" class="alert alert-danger">
                            <div *ngIf="f.file.errors.required">Image is required.</div>
                        </div>
                    </div>
                    <div class="col-md-5"></div>
                </div>
                <div class="form-group row">
                    <label for="file" class="control-label col-md-2"></label>
                    
                    <div class="col-md-3" *ngFor='let d of imgData'>
                        <img [src]="d.url" height="100">
                        <span (click)="imgDelete(d.id)" style="color: red;position: absolute;top: -10px;"><i class="fa fa-times-circle"></i></span>
                    </div>
                </div>
               
                <div class="form-group row">
                    <label class="control-label col-md-2"> Is Active</label>
                    <div class="col-md-1">
                     <input type="checkbox" name="isActive" id="isActive" checked/>
                    </div>
                  <div class="col-7"></div>
                </div>
                <div class="row">
                <div class="col-6"></div>
                <div class="col-2">
                  <span class="btn btn-primary" (click)="Clear()">Clear</span>
                </div>
                <div class="col-2" style="float:right;position: relative;">
                  <button id="btnSubmit" class="btn btn-primary" type="submit">Submit</button>
                </div>
                <div class="col-2"></div>
              </div>
              </form>
            </div>
          </div>
        </div>
      </div>