import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Common } from '../common/common';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  public getCustomerList(data: any) {
    return this.http.post<any>(Common.baseUrl + '/Customer/GetCustomer/', data);
  }
  
  public getBranchAdminList() {
    return this.http.post<any>(Common.baseUrl + '/Customer/GetBranchAdmin/','');
  }

  public GetBranchAdminById(data: any) {
    return this.http.post<any>(Common.baseUrl + '/Customer/GetBranchAdminById/' + data,'');
  }

  public getCustomerListById(data: any) {
    return this.http.post<any>(Common.baseUrl + '/Customer/GetCustomerById/' + data,'');
  }

  public getCustomerByParams(data: any) {
    return this.http.post(Common.baseUrl + '/Customer/getCustomerByParams/', data);
    //return this.http.post<any>(Common.baseUrl + '/Customer/getCustomerByParams/' + data,'');
  }

  public GetCustomerDetailForReport(data: any) {
    return this.http.get<any>(Common.baseUrl + '/Customer/GetCustomerDetailForReport/' + data);
  }

  // tslint:disable-next-line: typedef
  public getBrandById(data: any) {
    return this.http.get<any>(Common.baseUrl + '/Brand/GetBrandById/' + data);
  }

  public getCustomerDetailsById(data: any) {
    return this.http.post<any>(Common.baseUrl + '/Customer/GetCustomerDetailsById/' + data,'');
  }

  public getAgeGroupList() {
    return this.http.post<any>(Common.baseUrl + '/Customer/GetAgeGroup/','');
  }

  public getSocialInfoList() {
    return this.http.post<any>(Common.baseUrl + '/Customer/GetSocialInfo/','');
  }

  public getInterestedInfoList() {
    return this.http.post<any>(Common.baseUrl + '/Customer/GetInterestedInfo/','');
  }

  public GetSearchDataById(data: any) {
    return this.http.post<any>(Common.baseUrl + '/Customer/GetSearchDataById/', data);
  }

  public getOutletDetail(data: any) {
    return this.http.post<any>(Common.baseUrl + '/Customer/GetOutletDetail/' + data,'');
  }

  public GetCustomerDetailForBadReviewReport(data: any) {
    return this.http.get<any>(Common.baseUrl + '/Customer/GetCustomerDetailForBadReviewReport/' + data);
  }

  public getCustomerCountByBranch(data: any) {
    return this.http.post(Common.baseUrl + '/Customer/getCustomerCountByBranch/', data);
  }

  public GetCustomerDetailForBadReviewReportByParams(data: any) {
    return this.http.post(Common.baseUrl + '/Customer/getCustomerDetailForBadReviewReportByParams/', data);
  }

}
