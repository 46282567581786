import { Component, OnInit, SecurityContext } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { BrandService } from '../../../services/brand/brand.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Common } from '../../../services/common/common';
import { find } from 'rxjs/operators';

@Component({
  selector: 'app-brand-add',
  templateUrl: './brand-add.component.html',
  styleUrls: ['./brand-add.component.scss']
})
export class BrandAddComponent implements OnInit {
  BrandForm: FormGroup;
  BrandFormSubmit: FormGroup;
  brand: Array<any>;
  id = 0;
  bandImage: any;
  images = [];
  brandImage: any;
  BrandImage: any;
  currentField: any;
  brandImagePath: any;


  constructor(private formBuilder: FormBuilder, private brandService: BrandService,
              private toastrService: ToastrService, private activeRoute: ActivatedRoute,
              private router: Router, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    const id = +this.activeRoute.snapshot.paramMap.get('id');
    if (id !== null){
      this.loadBrand(id);
      }
    this.brandFrom();
  }
 loadBrand(brandId): any{
   // tslint:disable-next-line: no-debugger
   //debugger;
   this.brand = new Array<any>();
   this.brandService.getBrandList().subscribe((data: any[]) => {
    this.brand = data;
    console.log(data);
    this.brand.forEach(item => item.brandImageName = Common.baseUrl + '/images/' + item.brandImageName);
    if (brandId !== null) {
      this.SetData(brandId);
    }
  });
  }

  SetData(brandId): any {
    this.brand.find(x => {
      if (+x.brandId === +brandId ) {
        this.id = x.brandId;
        document.getElementById('showImage').style.display = 'block';

        const list = new DataTransfer();
        const file = new File(['content'], x.brandImageName);
        list.items.add(file);
        const myFileList = list.files;

        (document.getElementById('BrandName') as HTMLInputElement).value = x.brandName;
        // console.log((document.getElementById('Parent') as HTMLSelectElement).value);
        (document.getElementById('file') as HTMLInputElement).files = myFileList;
        this.brandImagePath = x.brandImageName;

        this.BrandForm.setValue({
          BrandName: x.brandName,
          SortOrder: x.sortOrder,
          BrandStatus: x.brandStatus,
          fileSource: x.brandImageName,
          file: x.brandImageName,
          brandImagePath: x.brandImageName,
        });

       }
    });

  // this.images.push(this.brandImagePath);
  // console.log(this.brandImagePath);
  }


  brandFrom(): any {
    this.BrandForm = this.formBuilder.group({
      BrandName: new FormControl('', [Validators.required, Validators.pattern('^[+]?[0-9a-zA-Z .-]*$'), Validators.maxLength(150)]),
      SortOrder: new FormControl('', [Validators.required, Validators.pattern('^[+]?[0-9]*$')]),
      BrandStatus: new FormControl(true, [Validators.required]),
      file: new FormControl('', [Validators.required]),
      fileSource: new FormControl('', [Validators.required]),
      BrandImageName: [''],
      BrandImage: [''],
      brandImagePath: ['']
    });
  }

  get f(): any{
    return this.BrandForm.controls;
  }

  onFileChange(event, field: any): any {
    this.currentField = field;
    if (event.target.files && event.target.files[0]) {
        const filesAmount = event.target.files.length;
        for (let i = 0; i < filesAmount; i++) {
            const reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]); // read file as data url
            const file = event.target.files[0];
            reader.onloadend = (e) => {
              console.log(event.target.result);
              if (this.currentField === 1) {
                // this.brandImagePath = e.target.result as string;
                this.brandImagePath = event.target.result;
                document.getElementById('showImage').style.display = 'block';
                this.BrandForm.patchValue({
                  BrandImageName: file.name,
                  fileSource: this.brandImagePath
                });
                this.handleChanges(event.target.files[0]);

              }
              this.images.push(event.target.result as string);

              this.BrandForm.patchValue({
                  fileSource: this.images
                });
            };
        }
    }
  }

  handleChanges(files: any): any
  {
    const file = files;
    const pattern = /image-*/;
    const reader = new FileReader();
    if (!file.type.match(pattern)) {
      this.toastrService.error('Invalid Image Format!');
      return;
    }
    reader.onloadend = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }


  _handleReaderLoaded(e): any {
    const reader = e.target;
    const base64result = reader.result.substr(reader.result.indexOf(',') + 1);
    this.BrandImage = base64result;
  }

  onSubmit(): any{
    // tslint:disable-next-line: no-debugger
    //debugger;
    const brandId = +this.activeRoute.snapshot.paramMap.get('id');
    if (brandId !== null && brandId !== 0) {
      this.BrandFormSubmit = this.formBuilder.group({
        BrandId: brandId,
        BrandName: [this.BrandForm.get('BrandName').value],
        SortOrder: [this.BrandForm.get('SortOrder').value],
        BrandStatus: [this.BrandForm.get('BrandStatus').value],
        BrandImageName: [this.BrandForm.get('BrandImageName').value],
        BrandImage: [this.BrandImage],
      });
      if (this.BrandForm.get('BrandName').hasError('required')) {
        this.toastrService.error('Please Input Brand Name', 'Error!');
        return;
      }

      if (this.BrandForm.get('SortOrder').hasError('required')) {
        this.toastrService.error('Please Input Sort Order', 'Error!');
        return;
      }

      if (this.BrandForm.get('BrandStatus').hasError('required')) {
        this.toastrService.error('Please Input Brand Status', 'Error!');
        return;
      }

      if (this.BrandForm.get('file').hasError('required')) {
        this.toastrService.error('Please Input Brand Image', 'Error!');
        return;
      }

      this.brandService.UpdateBrand(this.BrandFormSubmit.value)
        .subscribe(res => {
          console.log(res);
          this.toastrService.success(res.message, 'Data Save Successfully.');
          this.brandFrom();
          this.router.navigate(['/brand-list']);
        },
        error => {
          console.log(error);
          this.toastrService.error(error, 'Error!');
        });
    }
    else {
      this.BrandFormSubmit = this.formBuilder.group({
        BrandName: [this.BrandForm.get('BrandName').value],
        SortOrder: [this.BrandForm.get('SortOrder').value],
        BrandStatus: [this.BrandForm.get('BrandStatus').value],
        BrandImageName: [this.BrandForm.get('BrandImageName').value],
        BrandImage: [this.BrandImage],
      });
      if (this.BrandForm.get('BrandName').hasError('required')) {
        this.toastrService.error('Please Input Brand Name', 'Error!');
        return;
      }

      if (this.BrandForm.get('SortOrder').hasError('required')) {
        this.toastrService.error('Please Input Sort Order', 'Error!');
        return;
      }

      if (this.BrandForm.get('BrandStatus').hasError('required')) {
        this.toastrService.error('Please Input Brand Status', 'Error!');
        return;
      }

      if (this.BrandForm.get('file').hasError('required')) {
        this.toastrService.error('Please Input Brand Image', 'Error!');
        return;
      }

      this.brandService.AddBrand(this.BrandFormSubmit.value)
        .subscribe(res => {
          console.log(res);
          this.toastrService.success(res.message, 'Data Save Successfully.');
          this.brandFrom();
          this.router.navigate(['/brand-list']);
        },
        error => {
          console.log(error);
          this.toastrService.error(error, 'Error!');
        });
    }
  }

}
