<app-header></app-header>
<app-side-menu></app-side-menu>
<main class="app-content">
  <div class="row">
    <div class="col-md-3 col-sm-12 d-flex pb-sm-2">
        <div class="card custom-card-size" style="cursor: pointer;">
            <div class="card-body card-margin-right text-center">
                <p class="card-text m-0 text-left" style="font-size:larger; color:red; font-weight: bolder;">Current Month Customers</p>
                <hr/>
                <h5 class="card-title mb-0 mt-2 font-size-40" style="color:green; font-weight:bolder;">{{currentMonth}}</h5>
                <p class="card-text font-size-14">Total Customer</p>
            </div>
            <!-- <img class="r-arrow" src="assets/icons/right-arrow1.svg" alt=""> -->
        </div>
    </div>
    <div class="col-md-3 col-sm-12 d-flex pb-sm-2">
        <div class="card custom-card-size" style="cursor: pointer;">
            <div class="card-body card-margin-right text-center">
                <p class="card-text m-0 text-left" style="font-size:larger; color:red; font-weight: bolder;">Today's New Customers</p>
                <hr/>
                <h5 class="card-title mb-0 mt-2 font-size-40" style="color:green; font-weight:bolder;">{{todayNewCustomer}}</h5>
                <p class="card-text font-size-14">Total Customer</p>
            </div>
            <!-- <img class="r-arrow" src="assets/icons/right-arrow1.svg" alt=""> -->
        </div>
    </div>
    <div class="col-md-3 col-sm-12 d-flex pb-sm-2">
        <div class="card custom-card-size" style="cursor: pointer;">
            <div class="card-body card-margin-right text-center">
                <p class="card-text m-0 text-left" style="font-size:larger; color:red; font-weight: bolder;">Today's Existing Customers</p>
                <hr/>
                <h5 class="card-title mb-0 mt-2 font-size-40" style="color:green; font-weight:bolder;">{{todayExistingCustomer}}</h5>
                <p class="card-text font-size-14">Total Customer</p>
            </div>
            <!-- <img class="r-arrow" src="assets/icons/right-arrow1.svg" alt=""> -->
        </div>
    </div>
    <div class="col-md-3 col-sm-12 d-flex pb-sm-2">
        <div class="card custom-card-size" style="cursor: pointer;">
            <div class="card-body card-margin-right text-center">
                <p class="card-text m-0 text-left" style="font-size:larger; color:red; font-weight: bolder;">Current Month Bad Review</p>
                <hr/>
                <h5 class="card-title mb-0 mt-2 font-size-40" style="color:green; font-weight:bolder;">{{currentMonthBadReview}}</h5>
                <p class="card-text font-size-14">Total Customer</p>
            </div>
            <!-- <img class="r-arrow" src="assets/icons/right-arrow1.svg" alt=""> -->
        </div>
    </div>
  </div>
  <router-outlet></router-outlet>
</main>