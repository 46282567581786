
    <div class="app-title">
        <div>
          <h1><i class="fa fa-th-list"></i> Purchase</h1>
          <!-- <p>Supplier list</p> -->
        </div>
        <ul class="app-breadcrumb breadcrumb">
          <li class="breadcrumb-item"><i class="fa fa-home fa-lg"></i></li>
          <li class="breadcrumb-item">Purchase</li>
          <li class="breadcrumb-item active"><a routerLink="/purchase-add">Add Purchase</a></li>
        </ul>
      </div>
      <div class="row">
          <div class="col-md-12">
              <div class="tile">
                
                <div class="tile-title-w-btn">
                  <h3 class="title">Purchase List</h3>
                  
                  <p><a class="btn btn-primary icon-btn" routerLink="/purchase-add"><i class="fa fa-plus"></i>Add Purchase</a></p>
                </div>
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Supplier Name</th>
                        <th>Invoice Number</th>
                        <th>Total Amount</th>
                        <th>Paid Amount</th>
                        <th>Purchase Date</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let purchase of data ; let i = index" id="tr_{{purchase.purchaseId}}">
                        <td>{{i+1}}</td>
                        <td>{{purchase.supplierName}}</td>
                        <td>{{purchase.invoiceNo}}</td>
                        <td>{{purchase.totalAmount}}</td>
                        <td>{{purchase.paidAmount}}</td>
                        <td>{{purchase.purchaseDate}}</td>
                        <td>
                          <button class="btn btn-success btn-sm mr-2" (click)="edit(purchase.purchaseId)"><i class="fa fa-edit"></i></button>
                          <button class="btn btn-danger btn-sm" (click)="delete(purchase.purchaseId)"><i class="fa fa-trash"></i></button>
                        </td>
                      </tr>
  
                      
                    </tbody>
                  </table>
                </div>
                
              </div>
            </div>
          
      </div>
  