import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Common } from '../common/common';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient, private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): import('rxjs').Observable<HttpEvent<any>> {
    //debugger;
    if (req.headers.get('No-Auth') === 'True') {
        return next.handle(req.clone());
    }
    if (localStorage.getItem('token') != null) {
    const clonedreq = req.clone({
    headers: req.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    });
    return next.handle(clonedreq)
    .pipe(
      catchError(error => {
        if(error instanceof HttpErrorResponse) {
          if(error.status === 401) {
            return throwError(error.statusText);
          }
          const applicationError = error.headers.get('Application-Error');
          if(applicationError) {
            console.log(applicationError);
            return throwError(applicationError);
          }
          const serverError = error.error;
          let modalStateErrors = '';
          if (serverError && typeof serverError === 'object') {
            for (const key in serverError) {
             if(serverError[key]) {
               modalStateErrors += serverError[key] + '\n';
             }

            }
          }
          return throwError(modalStateErrors || serverError || 'Server Error')
        }
      })
    );
  }
  else {
    this.router.navigateByUrl('/login');
  }
}

  public authenticate(loginData: any)
  {
    const reqHeader = new HttpHeaders({'No-Auth': 'True'});
    //console.log(loginData);
    return this.http.post<any>(Common.baseUrl + '/Auth/login', loginData, {headers: reqHeader} )
    .pipe(map(user => {
      if (user && user.token) {
          // store user details in local storage to keep user logged in
          localStorage.setItem('token', user.token);
          localStorage.setItem('username', user.username);
          localStorage.setItem('branchId', user.branchId);
          localStorage.setItem('userGroupType', user.userGroupType);
          //console.log(user.token);
      }
      else {
        this.router.navigateByUrl('/login');
        //console.log(user);
      }
  }));
  }
  public signup(data: any)
  {
    //debugger;
    const reqHeader = new HttpHeaders({'No-Auth': 'True'});
    return this.http.post<any>(Common.baseUrl + '/Customer/CreateBranchAdmin', data, {headers: reqHeader} )
    .pipe(map(user => {
      if (user && user.token) {
          // store user details in local storage to keep user logged in
          localStorage.setItem('token', user.token);
          localStorage.setItem('username', user.username);
          localStorage.setItem('branchId', user.BranchId);
          localStorage.setItem('userGroupType', user.userGroupType);
      }
      else {
        console.log(user);
      }
    }));

  }

  public adminUpdate(data: any)
  {
    //debugger;
    const reqHeader = new HttpHeaders({'No-Auth': 'True'});
    return this.http.post<any>(Common.baseUrl + '/Customer/UpdateBranchAdmin', data, {headers: reqHeader} )
    .pipe(map(user => {
      if (user && user.token) {
          // store user details in local storage to keep user logged in
          localStorage.setItem('token', user.token);
          localStorage.setItem('username', user.username);
          localStorage.setItem('branchId', user.BranchId);
          localStorage.setItem('userGroupType', user.userGroupType);
      }
      else {
        console.log(user);
      }
    }));

  }

  logedIn(){
    if (localStorage.getItem('token')) {
      return true;
    }
    return false;
  }
  logout() {
    //debugger;
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('branchId');
    localStorage.removeItem('userGroupType');
  }

  sessionExpired() {
    localStorage.clear();
    this.router.navigate(['/sessionExpired']);
    return true;
  }

  connectionOut() {
    this.router.navigate(['/connectionError']);
    return true;
  }

  getToken() {
    return localStorage.getItem('token');
    //console.log(localStorage.getItem('token'));
    
  }
}
