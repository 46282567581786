import { Component, OnInit, SecurityContext } from '@angular/core';
import { BrandService } from '../../../services/brand/brand.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl, SafeHtml  } from '@angular/platform-browser';
import { map } from 'rxjs/operators';
import { Common } from '../../../services/common/common';

@Component({
  selector: 'app-brand-list',
  templateUrl: './brand-list.component.html',
  styleUrls: ['./brand-list.component.scss']
})
export class BrandListComponent implements OnInit {
  data: Array<any>;
  brandImagePath: any;

  constructor(private brandService: BrandService, private toastrService: ToastrService,
              private router: Router, private sanitizer: DomSanitizer)
              {
                // tslint:disable-next-line: no-debugger
                //debugger;
                this.data = new Array<any>();
                this.brandService.getBrandList()
                  .subscribe(
                    (data: any) => {
                      this.data = data;
                      this.data.forEach(item => item.brandImageName = Common.baseUrl + '/images/' + item.brandImageName);
                      // console.log(this.data);
                    },
                    error => {console.log(error); }
                    );
              }

ngOnInit(): void {
}

  edit(id: any): any{
    // tslint:disable-next-line: no-debugger
    //debugger;
    this.router.navigate(['/brand-add/' + id]);
  }


  delete(id: any): any{
  // tslint:disable-next-line: no-debugger
  //debugger;
  this.brandService.DeleteBrand(id).subscribe(success => {
    this.toastrService.success(success.message, 'Data Delete Successfully.');
    this.brandService.getBrandList()
    .subscribe(
      (data: any) => {
        this.data = data;
        // tslint:disable-next-line: max-line-length
        this.data.forEach(item => item.brandImage = this.sanitizer.sanitize(SecurityContext.HTML, this.sanitizer.bypassSecurityTrustHtml(`data:image/jpeg;base64,${item.brandImage}`)));
        // console.log(this.data);
      },
      error => {console.log(error); }
      );
    });
  }
}
