<app-header></app-header>
<app-side-menu></app-side-menu>
<main class="app-content">
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4 d-flex" *ngFor="let item of data; let i = index" id="tr_{{item.branchId}}">
        <div class="card custom-card-size" style="cursor: pointer;" (click)="loadChart(item.branchId)">
            <div class="card-body card-margin-right text-center">
                <p class="card-text m-0 text-left" style="font-size:larger; color:red; font-weight: bolder;">{{item.branchName}}</p>
                <h5 class="card-title mb-0 mt-2 font-size-40" style="color:green; font-weight:bolder;">{{item.noOfBranch}}</h5>
                <p class="card-text font-size-14">Total Customer</p>
            </div>
            <img class="r-arrow" src="assets/icons/right-arrow1.svg" alt="">
        </div>
    </div>
    <!-- <div class="col-sm-12 col-md-6 col-lg-6 col-xl-7">
        <div class="row p-3">
            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4 d-flex" *ngFor="let item of data; let i = index" id="tr_{{item.branchId}}">
                <div class="card custom-card-size" style="cursor: pointer;" (click)="loadChart(item.branchId)">
                    <div class="card-body card-margin-right text-center">
                        <p class="card-text m-0 text-left" style="font-size:larger; color:red; font-weight: bolder;">{{item.branchName}}</p>
                        <h5 class="card-title mb-0 mt-2 font-size-40" style="color:green; font-weight:bolder;">{{item.noOfBranch}}</h5>
                        <p class="card-text font-size-14">Total Customer</p>
                    </div>
                    <img class="r-arrow" src="assets/icons/right-arrow1.svg" alt="">
                </div>
            </div>
        </div>
    </div> -->
  </div>
  <router-outlet></router-outlet>
</main>