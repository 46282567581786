import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user/user.service';

@Component({
  selector: 'app-chart',
  templateUrl: './outlet.component.html',
  styleUrls: ['./outlet.component.scss']
})
export class OutletComponent implements OnInit {
  currentMonth: any;
  todayNewCustomer: any;
  todayExistingCustomer: any;
  currentMonthBadReview: any;

  constructor(private customerService: UserService) { }

  ngOnInit(): void {
    const branchId = localStorage.getItem('branchId');

    this.customerService.getOutletDetail(branchId).subscribe((data: any) => {
      this.currentMonth = data.currentMonth;
      this.todayNewCustomer = data.todayNewCustomer;
      this.todayExistingCustomer = data.todayExistingCustomer;
      this.currentMonthBadReview = data.currentMonthBadReview;
    },
    error => {
      console.log(error); 
    });

  }

}
