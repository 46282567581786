
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Common } from './../common/common';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class SellerService {

  constructor(private http: HttpClient) { }

  public SellerRegistration(data): any{
    return this.http.post<any>(Common.baseUrl + '/Seller/InsertSeller/', data);
  }
  public getSellerList(): any{
    return this.http.get<any>(Common.baseUrl + '/Seller/GetSeller' );
  }
  public getSeller(data: any): any {
    return this.http.get<any>(Common.baseUrl + '/Seller/GetSeller/' + data );
  }
  public deleteSeller(data: string): any{
    return this.http.get<any>(Common.baseUrl + '/Seller/DeleteSeller/' + data );
  }
  public updateSeller(data): any{
    return this.http.post<any>(Common.baseUrl + '/Seller/UpdateSeller', data);
  }

  public Loginseller(loginData: any): any
  {
    return this.http.post<any>(Common.baseUrl + '/SellerAuth/loginSellerAuth', loginData )
    .pipe(map(user => {
      if (user) {
          localStorage.setItem('userId', user.userId);
      }
      else {
        console.log(user);
      }
  }));
  }

}
