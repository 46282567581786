<app-header></app-header>
<app-side-menu></app-side-menu>

<div class="row ml-5 mt-3">
    <div class="col-md-12">
        <div class="row">
            <div class="col-md-3">
              <input id="FormDate" type="date" class="form-control">
            </div>
            <div class="col-md-3">
              <input id="ToDate" type="date" class="form-control">
            </div>
            <div class="col-md-2">
              <button (click)="getDashboardValueByParams()" class="btn btn-primary">Search</button>
            </div>
            <div class="col-md-4 text-right">
                <button class="btn btn-info">Total Customer : {{totalCustomer}}</button>
            </div>
          </div>
    </div>
</div>

<main class="app-content">
    <!-- <div class="row">
        <div class="col-md-6 col-lg-6 col-xl-3" *ngFor="let item of data; let i = index" id="tr_{{item.branchId}}">
            <div class="widget-bg-color-icon card-box fadeInDown animated">
                <div class="bg-icon bg-icon-info pull-left" (click)="viewCustomerReport(item.branchId)">
                    <i class="md md-attach-money text-info"><span class="pointer" style="font-size: medium; font-style: normal;">{{item.branchName}}</span></i>
                </div>
                <div class="text-right">
                    <h3 class="text-dark"><b class="counter">{{item.noOfBranch}}</b></h3>
                    <p class="text-muted mb-0" style="font-size: smaller">Total Customer</p>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
    </div> -->

    <div class="row">
        <div class="col-sm-12 col-md-4 col-lg-3 col-xl-3 d-flex mb-1" *ngFor="let item of data; let i = index" id="tr_{{item.branchId}}">
            <div class="card custom-card-size" style="cursor: pointer;" *ngIf="logedInBranch == 0" (click)="viewCustomerReport(item.branchId)">
                <div class="card-body card-margin-right text-center">
                    <p class="card-text m-0 text-left" style="font-size:larger; color:red; font-weight: bolder;">{{item.branchName}}</p>
                    <h5 class="card-title mb-0 mt-2 font-size-40" style="color:green; font-weight:bolder;">{{item.noOfBranch}}<span title="bad review" style="font-size:20px; color:red;margin-left:15px;background-color:black;border-radius:50%;padding:5px;" (click)="viewBadReviewCustomerDetail(item.branchId)">{{item.badFeedback}}</span></h5>
                    <p class="card-text font-size-14">Total Customer</p>
                </div>
                <img class="r-arrow" src="assets/icons/right-arrow1.svg" alt="">
            </div>
        </div>
        <div class="col-sm-12 d-flex" *ngFor="let item of data; let i = index" id="tr_{{item.branchId}}">
            <div class="card custom-card-size" style="cursor: pointer;" *ngIf="item.branchId == logedInBranch" (click)="viewCustomerReport(item.branchId)">
                <div class="card-body card-margin-right text-center">
                    <p class="card-text m-0 text-left" style="font-size:larger; color:red; font-weight: bolder;">{{item.branchName}}</p>
                    <h5 class="card-title mb-0 mt-2 font-size-40" style="color:green; font-weight:bolder;">{{item.noOfBranch}}<span title="bad review" style="font-size:20px; color:red;margin-left:15px;background-color:black;border-radius:50%;padding:5px;">{{item.badFeedback}}</span></h5>
                    <p class="card-text font-size-14">Total Customer</p>
                </div>
                <img class="r-arrow" src="assets/icons/right-arrow1.svg" alt="">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div class="mb-2">
                <div class="card">
                    <div class="card-body text-center" style="cursor: pointer;">
                        <canvas id="ageGroup"></canvas>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div class="mb-2">
                <div class="card">
                    <div class="card-body text-center" style="cursor: pointer;">
                        <canvas id="interestCategory"></canvas>
                    </div>
                </div>
            </div>
            <div class="mb-2">
                <div class="card">
                    <div class="card-body text-center" style="cursor: pointer;">
                        <canvas id="billingRange"></canvas>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
            <div class="card">
                <div class="card-body text-center" style="cursor: pointer;">
                    <canvas id="gotToKnow"></canvas>
                </div>
            </div>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
            <div class="card">
                <div class="card-body text-center" style="cursor: pointer;">
                    <canvas id="seeOnline"></canvas>
                </div>
            </div>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
            <div class="card">
                <div class="card-body text-center" style="cursor: pointer;">
                    <canvas id="experience"></canvas>
                </div>
            </div>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
            <div class="card">
                <div class="card-body text-center" style="cursor: pointer;">
                    <canvas id="receiveCommercial"></canvas>
                </div>
            </div>
        </div>
    </div>




           
            <!-- 
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6 mb-2">
                <div class="card">
                    <div class="card-header"></div>
                    <div class="card-body text-center" style="cursor: pointer;">
                        <canvas id="receiveCommercial"></canvas>
                    </div>
                    <div class="card-footer"></div>
                </div>
            </div> -->
    <router-outlet></router-outlet>
</main>
<!-- <router-outlet></router-outlet> -->
