
    <div class="app-title">
        <div>
          <h1><i class="fa fa-edit"></i> Product</h1>
          <!-- <p>Sample forms</p> -->
        </div>
        <ul class="app-breadcrumb breadcrumb">
          <li class="breadcrumb-item"><i class="fa fa-home fa-lg"></i></li>
          <li class="breadcrumb-item">Product</li>
          <li class="breadcrumb-item"><a routerLink="/product-list">Product List</a></li>
        </ul>
      </div>
      
      <div class="row">
        <div>
          <p>Form value: {{addProductForm.value | json}}</p>
          <p>Form status: {{addProductForm.status | json}}</p>
        </div>
        <div class="col-md-12">
          <div class="tile">
            <h3 class="tile-title">Add Product</h3>
            <div class="tile-body">
              <form [formGroup]="addProductForm" (ngSubmit)="onSubmit()" class="form-horizontal">
                
                <div class="form-group row">
                  <div class="col-md-4">
                    <label class="control-label">Select Shop</label>
                      <select class="form-control" formControlName="ShopId">
                        <option selected value="">Select Shop </option>
                        <option *ngFor='let shopItem of shopData' value="{{shopItem.seller_Id}}">{{shopItem.seller_Name}}</option>
                        </select>
                        <div *ngIf="f.ShopId.touched && f.ShopId.invalid" class="alert alert-danger">
                          <div *ngIf="f.ShopId.errors.required">Category Name is required.</div>
                        </div>
                  </div>
                  <div class="col-md-4">
                    <label class="control-label">Select Category</label>
                      <select class="form-control" formControlName="CategoryId">
                        <option selected value="">Select Category </option>
                        <option *ngFor='let catItem of categoryData' value="{{catItem.id}}">{{catItem.name}}</option>
                      </select>
                      <div *ngIf="f.CategoryId.touched && f.CategoryId.invalid" class="alert alert-danger">
                        <div *ngIf="f.CategoryId.errors.required">Category Name is required.</div>
                      </div>
                  </div>
                  <div class="col-md-4">
                    <label for="ddlBrand" class="control-label">Select Brand</label>
                    <select class="form-control" formControlName="BrandId">
                      <option selected value="">Select Brand </option>
                      <option *ngFor='let brandItem of brandData' value="{{brandItem.brandId}}">{{brandItem.brandName}}</option>
                    </select>
                    <div *ngIf="f.BrandId.touched && f.BrandId.invalid" class="alert alert-danger">
                      <div *ngIf="f.BrandId.errors.required">Brand Name is required.</div>
                    </div>
                  </div>
                  
                </div>
                  
                <div class="form-group row">
                  <div class="col-md-6">
                    <label class="control-label">Product Name</label>
                    <input 
                      formControlName="ProductName"
                      id="productName" 
                      type="text"
                      placeholder="Enter product name" 
                      class="form-control">
                    <div *ngIf="f.ProductName.touched && f.ProductName.invalid" class="alert alert-danger">
                        <div *ngIf="f.ProductName.errors.required">Name is required.</div>
                        <div *ngIf="f.ProductName.errors.maxLength">Name maximum 150 character.</div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label class="control-label">Product Description</label>
                    <textarea class="form-control" rows="3" id="productDescription" formControlName="ProductDescription" placeholder ="Enter Product Description "></textarea>
                    <div *ngIf="f.ProductDescription.touched && f.ProductDescription.invalid" class="alert alert-danger">
                      <div *ngIf="f.ProductDescription.errors.required">Description is required.</div>
                      <div *ngIf="f.ProductDescription.errors.maxLength">Description maximum 1500 character.</div>
                   </div>
                  </div>
                </div>
  
                <div class="form-group row">
                  <div class="col-md-4">
                    <label class="control-label">Stock Quantity</label>
                    <input class="form-control" id="regularPrice" formControlName="StockQuantity" 
                    type="number" placeholder="Enter Regular Price" readonly>
                    <!-- <div *ngIf="f.RegularPrice.touched && f.RegularPrice.invalid" class="alert alert-danger">
                      <div *ngIf="f.RegularPrice.errors.required">Stock Quantity is required.</div>
                    </div> -->
                  </div>
  
                  <div class="col-md-4">
                    <label class="control-label">Regular Price</label>
                    <input class="form-control" id="regularPrice" formControlName="RegularPrice" 
                    type="number" placeholder="Enter Regular Price">
                    <!-- <div *ngIf="f.RegularPrice.touched && f.RegularPrice.invalid" class="alert alert-danger">
                      <div *ngIf="f.RegularPrice.errors.required">Stock Quantity is required.</div>
                    </div> -->
                  </div>
  
                  <div class="col-md-4">
                    <label class="control-label">Sell Price</label>
                    <input class="form-control" id="sellPrice" formControlName="SellPrice" 
                    type="number" placeholder="Enter Sell Price">
                    <!-- <div *ngIf="f.SellPrice.touched && f.SellPrice.invalid" class="alert alert-danger">
                      <div *ngIf="f.SellPrice.errors.required">Sell Price is required.</div>
                    </div> -->
                  </div>
                </div>
  
                <div class="form-group row">
                    <div class="col-md-12">
                      <label for="file" class="control-label col-md-2">Photo Upload</label>
                      <input 
                        formControlName="file"
                        id="file" 
                        type="file" 
                        class="form-control"
                        multiple="" 
                        (change)="onFileChange($event)">
                      <div *ngIf="f.file.touched && f.file.invalid" class="alert alert-danger">
                          <div *ngIf="f.file.errors.required">File is required.</div>
                      </div>
                    </div>
                </div>
                
                <div class="form-group row">
                  <label for="file" class="control-label"></label>
                  
                  <div class="col-md-2" *ngFor='let d of imgData'>
                      <img [src]="d" height="100">
                      <span (click)="imgDelete(d.id)" style="color: red;position: absolute;top: -10px;"><i class="fa fa-trash"></i></span>
                  </div>
              </div>
                <div class="col-12">
                  <img *ngFor='let url of images'  [src]="url" height="150" width="200px" style="margin: 3px;"> <br/>
                </div>
                  
                <div class="button-align">
                  <button class="btn btn-primary btn-lg" type="submit">Update</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        
      </div>
    
  
      <!-- <p>Form value: {{addProductForm.value | json}}</p>
      <p>Form status: {{addProductForm.status | json}}</p> -->
