import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BranchService } from '../../services/branch/branch.service';
import { ToastrService } from 'ngx-toastr';
import { DashboardService } from '../../services/dashboard/dashboard.service';
import { Chart, registerables } from 'chart.js';
import { element } from 'protractor';

@Component({
  selector: 'app-chart',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  data: Array<any>;
  customerData: Array<any>;
  totalCustomer: number = 0;

  ageGroupChart:any;
  billingRangeChart:any;
  experienceChart:any;
  interestedCategoryChart:any;
  gotToKnowChart:any;
  seeOnlineChart:any;
  associateOfferChart:any;
  receiveCommercialChart:any;

  ageGroupConfig:any;
  billingRangeConfig:any;
  experienceConfig:any;
  interestedCategoryConfig:any;
  gotToKnowConfig:any;
  seeOnlineConfig:any;
  associateOfferConfig:any;
  receiveCommercialConfig:any;
  logedInBranch: any;
  userGroupType: string;

  constructor(private dashboardService: DashboardService, private branchService: BranchService, private toastrService: ToastrService, private router: Router) {
    this.chartInitialize();

    this.data = new Array<any>();
    this.branchService.getBranchListWithCustomerCount().subscribe((data: any) => {
      this.data = data;
      this.totalCustomer = 0;
      this.data.forEach(element => {
        this.totalCustomer += element.noOfBranch;
      });

      // console.log(this.data);
    },
    error => {
      console.log(error); 
    });
    
  }

  ngOnInit(): void {

    this.logedInBranch = localStorage.getItem('branchId');

    this.chartInitialize();

    Chart.register(...registerables);
    this.initialData();
   }

   chartInitialize() {
    this.ageGroupChart = document.getElementById('ageGroup');
    this.billingRangeChart = document.getElementById('billingRange');
    this.experienceChart = document.getElementById('experience');
    this.interestedCategoryChart = document.getElementById('interestCategory');
    this.gotToKnowChart = document.getElementById('gotToKnow');
    this.seeOnlineChart = document.getElementById('seeOnline');
    this.associateOfferChart = document.getElementById('associateOffer');
    this.receiveCommercialChart = document.getElementById('receiveCommercial');
    
    var ageGroupChartExist = Chart.getChart("ageGroup"); // <canvas> id
    if (ageGroupChartExist != undefined)  
      ageGroupChartExist.destroy(); 

    var billingRangeChartExist = Chart.getChart("billingRange"); // <canvas> id
    if (billingRangeChartExist != undefined)  
      billingRangeChartExist.destroy(); 

    var experienceChartExist = Chart.getChart("experience"); // <canvas> id
    if (experienceChartExist != undefined)  
      experienceChartExist.destroy(); 
    
    var interestCategoryChartExist = Chart.getChart("interestCategory"); // <canvas> id
    if (interestCategoryChartExist != undefined)  
    interestCategoryChartExist.destroy(); 

    var gotToKnowChartExist = Chart.getChart("gotToKnow"); // <canvas> id
    if (gotToKnowChartExist != undefined)  
      gotToKnowChartExist.destroy(); 

    var seeOnlineChartExist = Chart.getChart("seeOnline"); // <canvas> id
    if (seeOnlineChartExist != undefined)  
      seeOnlineChartExist.destroy(); 
      
    var associateOfferChartExist = Chart.getChart("associateOffer"); // <canvas> id
    if (associateOfferChartExist != undefined)  
      associateOfferChartExist.destroy(); 

    var receiveCommercialChartExist = Chart.getChart("receiveCommercial"); // <canvas> id
    if (receiveCommercialChartExist != undefined)  
      receiveCommercialChartExist.destroy(); 
  }

   initialData(): void {

    this.dashboardService.getDashboardChartData(this.logedInBranch).subscribe((data: any) => {
      this.loadAgeGroupChart(data.ageGroup)
      this.loadInterestedCategoryChart(data.interestedCategory);
      this.loadGotToKnowChart(data.knowingFrom);
      this.loadSeeOnlineChart(data.seeProductFrom);
      this.loadReceiveCommercialChart(data.receiveCommercial);
      this.loadBillingRangeChart(data.billingRange);
      this.loadExperienceChart(data.gender);
    },
    error => {console.log(error); }
    );
  
  }

  viewCustomerReport(id: any): any{

    this.userGroupType = localStorage.getItem('userGroupType');

    if (this.userGroupType !== 'F') {
      if(id != null) {
        this.router.navigate(['/user-list/' + id]);
      }
      else{
        this.toastrService.error('No Data Found', 'Error!');
      }
    }
    
  }

  loadChart(id: any): any{
    if(id != null) {
      this.router.navigate(['/chart/' + id]);
    }
    else{
      this.toastrService.error('No Data Found', 'Error!');
    }
  }

  loadAgeGroupChart(rawData: any) {
    let dataSet = [];
    let labels = [];
    rawData.forEach(element => {
      //  let val = (parseInt(element.total) / this.totalAgeGroup * 100);
      //  console.log(val);
      dataSet.push(element.total);
      labels.push(element.title);
    });

    const data = {
      labels: labels,
      datasets: [{
        label: 'My First Dataset',
        data: dataSet,
        // backgroundColor: [
        //   'rgba(255, 99, 132, 1.5)',
        //   'rgba(255, 159, 64, 1.5)',
        //   'rgba(255, 205, 86, 1.5)',
        //   'rgba(75, 192, 192, 1.5)',
        //   'rgba(54, 162, 235, 1.5)',
        //   'rgba(153, 102, 255, 1.5)',
        //   'rgba(201, 203, 207, 1.5)'
        // ],
        backgroundColor: ['#CB4335', '#1F618D', '#F1C40F', '#27AE60', '#884EA0', '#D35400'],
        hoverOffset: 4
      }]
    };

    this.ageGroupConfig = {
      type: 'pie',
      data: data,
      options: {
        plugins: {
          title: {
            display: true,
            text: 'Age Group',
            font: {
              size: '40'
            }
          }
          // ,
          // datalabels: {
          //   anchor: 'end',
          //   align: 'top',
          //   formatter: Math.round,
          //   font: {
          //     weight: 'bold',
          //     size: '15'
          //   },
          //   color: '#000000'
          // }
        }
      },
    }

    new Chart(this.ageGroupChart, this.ageGroupConfig);
  }
  
  loadBillingRangeChart(rawData: any): void {
    let dataSet = [];
    let labels = [];
    rawData.forEach(element => {
      dataSet.push(element.total);
      labels.push(element.title);
    });

    const data = {
      labels: labels,
      datasets: [{
        label: 'My First Dataset',
        data: dataSet,
        // backgroundColor: [
        //   'rgba(255, 99, 132, 1.5)',
        //   'rgba(255, 159, 64, 1.5)',
        //   'rgba(255, 205, 86, 1.5)',
        //   'rgba(75, 192, 192, 1.5)',
        //   'rgba(54, 162, 235, 1.5)',
        //   'rgba(153, 102, 255, 1.5)',
        //   'rgba(201, 203, 207, 1.5)'
        // ],
        backgroundColor: ['#CB4335', '#1F618D', '#F1C40F', '#27AE60', '#884EA0', '#D35400'],
        hoverOffset: 4
      }]
    };

    this.billingRangeConfig = {
      type: 'bar',
      data: data,
      options: {
        plugins: {
          title: {
            display: true,
            text: 'Billing cycle',
            font: {
              size: '40'
            }
          },
          datalabels: {
            anchor: 'end',
            align: 'top',
            formatter: Math.round,
            font: {
              weight: 'bold',
              size: '15'
            },
            color: '#000000'
          }
        }
      }
    }

    new Chart(this.billingRangeChart, this.billingRangeConfig);
  }

  loadExperienceChart(rawData: any): void {
    let dataSet = [];
    let labels = [];
    rawData.forEach(element => {
      dataSet.push(element.total);
      labels.push(element.title);
    });

    const data = {
      labels: labels,
      datasets: [{
        label: 'My First Dataset',
        data: dataSet,
        // backgroundColor: [
        //   'rgba(255, 99, 132, 1.5)',
        //   'rgba(255, 159, 64, 1.5)',
        //   'rgba(255, 205, 86, 1.5)',
        //   'rgba(75, 192, 192, 1.5)',
        //   'rgba(54, 162, 235, 1.5)',
        //   'rgba(153, 102, 255, 1.5)',
        //   'rgba(201, 203, 207, 1.5)'
        // ],
        backgroundColor: ['#CB4335', '#1F618D', '#F1C40F', '#27AE60', '#884EA0', '#D35400'],
        hoverOffset: 4
      }]
    };

    this.experienceConfig = {
      type: 'pie',
      data: data,
      options: {
        plugins: {
          title: {
            display: true,
            text: 'Gender',
            font: {
              size: '40'
            }
          },
          datalabels: {
            anchor: 'end',
            align: 'top',
            formatter: Math.round,
            font: {
              weight: 'bold',
              size: '15'
            },
            color: '#000000'
          }
        }
      }
    }

    new Chart(this.experienceChart, this.experienceConfig);
  }

  loadInterestedCategoryChart(rawData: any): void {
    let dataSet = [];
    let labels = [];
    rawData.forEach(element => {
      dataSet.push(element.total);
      labels.push(element.title);
    });

    const data = {
      labels: labels,
      datasets: [{
        label: 'My First Dataset',
        data: dataSet,
        // backgroundColor: [
        //   'rgba(255, 99, 132, 1.5)',
        //   'rgba(255, 159, 64, 1.5)',
        //   'rgba(255, 205, 86, 1.5)',
        //   'rgba(75, 192, 192, 1.5)',
        //   'rgba(54, 162, 235, 1.5)',
        //   'rgba(153, 102, 255, 1.5)',
        //   'rgba(201, 203, 207, 1.5)'
        // ],
        backgroundColor: ['#CB4335', '#1F618D', '#F1C40F', '#27AE60', '#884EA0', '#D35400'],
        hoverOffset: 4
      }]
    };

    this.interestedCategoryConfig = {
      type: 'line',
      data: data,
      options: {
        plugins: {
          title: {
            display: true,
            text: 'Categories Selection',
            font: {
              size: '40'
            }
          },
          datalabels: {
            anchor: 'hide',
            align: 'top',
            formatter: Math.round,
            font: {
              weight: 'bold',
              size: '15'
            },
            color: '#000000'
          }
        }
      }
    }

    new Chart(this.interestedCategoryChart, this.interestedCategoryConfig);
  }

  loadGotToKnowChart(rawData: any): void {
    let dataSet = [];
    let labels = [];
    rawData.forEach(element => {
      dataSet.push(element.total);
      labels.push(element.title);
    });

    const data = {
      labels: labels,
      datasets: [{
        label: 'My First Dataset',
        data: dataSet,
        // backgroundColor: [
        //   'rgba(255, 99, 132, 1.5)',
        //   'rgba(255, 159, 64, 1.5)',
        //   'rgba(255, 205, 86, 1.5)',
        //   'rgba(75, 192, 192, 1.5)',
        //   'rgba(54, 162, 235, 1.5)',
        //   'rgba(153, 102, 255, 1.5)',
        //   'rgba(201, 203, 207, 1.5)'
        // ],
        backgroundColor: ['#CB4335', '#1F618D', '#F1C40F', '#27AE60', '#884EA0', '#D35400'],
        hoverOffset: 4
      }]
    };

    this.gotToKnowConfig = {
      type: 'pie',
      data: data,
      options: {
        plugins: {
          title: {
            display: true,
            text: 'Get to know about us',
            font: {
              size: '40'
            }
          },
          datalabels: {
            anchor: 'end',
            align: 'top',
            formatter: Math.round,
            font: {
              weight: 'bold',
              size: '15'
            },
            color: '#000000'
          }
        }
      }
    }

    new Chart(this.gotToKnowChart, this.gotToKnowConfig);
  }

  loadSeeOnlineChart(rawData: any): void {
   
    let dataSet = [];
    let labels = [];
    rawData.forEach(element => {
      dataSet.push(element.total);
      labels.push(element.title);
    });

    const data = {
      labels: labels,
      datasets: [{
        label: 'My First Dataset',
        data: dataSet,
        // backgroundColor: [
        //   'rgba(255, 99, 132, 1.5)',
        //   'rgba(255, 159, 64, 1.5)',
        //   'rgba(255, 205, 86, 1.5)',
        //   'rgba(75, 192, 192, 1.5)',
        //   'rgba(54, 162, 235, 1.5)',
        //   'rgba(153, 102, 255, 1.5)',
        //   'rgba(201, 203, 207, 1.5)'
        // ],
        backgroundColor: ['#CB4335', '#1F618D', '#F1C40F', '#27AE60', '#884EA0', '#D35400'],
        hoverOffset: 4
      }]
    };

    this.seeOnlineConfig = {
      type: 'pie',
      data: data,
      options: {
        plugins: {
          title: {
            display: true,
            text: 'See product from',
            font: {
              size: '40'
            }
          },
          datalabels: {
            anchor: 'end',
            align: 'top',
            formatter: Math.round,
            font: {
              weight: 'bold',
              size: '15'
            },
            color: '#000000'
          }
        }
      }
    }

    new Chart(this.seeOnlineChart, this.seeOnlineConfig);
  }

  loadAssociateOfferChart(rawData: any): void {
    let dataSet = [];
    let labels = [];
    rawData.forEach(element => {
      dataSet.push(element.total);
      labels.push(element.title);
    });

    const data = {
      labels: labels,
      datasets: [{
        label: 'My First Dataset',
        data: dataSet,
        // backgroundColor: [
        //   'rgba(255, 99, 132, 1.5)',
        //   'rgba(255, 159, 64, 1.5)',
        //   'rgba(255, 205, 86, 1.5)',
        //   'rgba(75, 192, 192, 1.5)',
        //   'rgba(54, 162, 235, 1.5)',
        //   'rgba(153, 102, 255, 1.5)',
        //   'rgba(201, 203, 207, 1.5)'
        // ],
        backgroundColor: ['#CB4335', '#1F618D', '#F1C40F', '#27AE60', '#884EA0', '#D35400'],
        hoverOffset: 4
      }]
    };

    this.associateOfferConfig = {
      type: 'pie',
      data: data,
      options: {
        plugins: {
          title: {
            display: true,
            text: 'More associate offer',
            font: {
              size: '40'
            }
          },
          datalabels: {
            anchor: 'end',
            align: 'top',
            formatter: Math.round,
            font: {
              weight: 'bold',
              size: '15'
            },
            color: '#000000'
          }
        }
      }
    }

    new Chart(this.associateOfferChart, this.associateOfferConfig);
  }

  loadReceiveCommercialChart(rawData: any): void {
    let dataSet = [];
    let labels = [];
    rawData.forEach(element => {
      dataSet.push(element.total);
      labels.push(element.title);
    });

    const data = {
      labels: labels,
      datasets: [{
        label: 'My First Dataset',
        data: dataSet,
        // backgroundColor: [
        //   'rgba(255, 99, 132, 1.5)',
        //   'rgba(255, 159, 64, 1.5)',
        //   'rgba(255, 205, 86, 1.5)',
        //   'rgba(75, 192, 192, 1.5)',
        //   'rgba(54, 162, 235, 1.5)',
        //   'rgba(153, 102, 255, 1.5)',
        //   'rgba(201, 203, 207, 1.5)'
        // ],
        backgroundColor: ['#CB4335', '#1F618D', '#F1C40F', '#27AE60', '#884EA0', '#D35400'],
        hoverOffset: 4
      }]
    };

    this.receiveCommercialConfig = {
      type: 'pie',
      data: data,
      options: {
        plugins: {
          title: {
            display: true,
            text: 'Receive commercial',
            font: {
              size: '40'
            }
          },
          datalabels: {
            anchor: 'hide',
            align: 'top',
            formatter: Math.round,
            font: {
              weight: 'bold',
              size: '15'
            },
            color: '#000000'
          }
        }
      }
    }

    new Chart(this.receiveCommercialChart, this.receiveCommercialConfig);
  }

  viewBadReviewCustomerDetail(id: any): any{

    this.userGroupType = localStorage.getItem('userGroupType');

    if (this.userGroupType !== 'F') {
      if(id != null) {
        this.router.navigate(['/badreview-list/' + id]);
      }
      else{
        this.toastrService.error('No Data Found', 'Error!');
      }
    }
    
  }

  getDashboardValueByParams(): any {
    // let branchId = +this.activeRoute.snapshot.paramMap.get('id');
    var fromdate = ((document.getElementById("FormDate") as HTMLInputElement).value);
    var todate = ((document.getElementById("ToDate") as HTMLInputElement).value);

    const params = {fromdate, todate};

    if(fromdate != "" && todate != "") {

      this.chartInitialize();

      this.data = new Array<any>();
      this.branchService.getBranchListWithCustomerCountByParams(params).subscribe((data: any) => {
        this.data = data;
        this.totalCustomer = 0;
        this.data.forEach(element => {
          this.totalCustomer += element.noOfBranch;
        });
      },
      error => {
        console.log(error); 
      });

      this.dashboardService.getDashboardChartDataByParams(params).subscribe((data: any) => {
        this.loadAgeGroupChart(data.ageGroup)
        this.loadInterestedCategoryChart(data.interestedCategory);
        this.loadGotToKnowChart(data.knowingFrom);
        this.loadSeeOnlineChart(data.seeProductFrom);
        this.loadReceiveCommercialChart(data.receiveCommercial);
        this.loadBillingRangeChart(data.billingRange);
        this.loadExperienceChart(data.gender);
      },
      error => {console.log(error); }
      );

    }

    
  }

}
