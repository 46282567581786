import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { BranchService } from '../../../services/branch/branch.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { Common } from '../../../services/common/common';

@Component({
  selector: 'app-branch-add',
  templateUrl: './branch-add.component.html',
  styleUrls: ['./branch-add.component.scss']
})
export class BranchAddComponent implements OnInit {
  BranchForm: FormGroup;
  BranchFormSubmit: FormGroup;
  branch: Array<any>;
  id = 0;


  constructor(private formBuilder: FormBuilder, private branchService: BranchService,
              private toastrService: ToastrService, private activeRoute: ActivatedRoute,
              private router: Router) { }

  ngOnInit(): void {
    const id = +this.activeRoute.snapshot.paramMap.get('id');
    if (id !== null){
      this.loadBranch(id);
      }
    this.branchForm();
  }
  loadBranch(brandId): any{
   // tslint:disable-next-line: no-debugger
   //debugger;
   this.branch = new Array<any>();
   this.branchService.getBranchList().subscribe((data: any[]) => {
    this.branch = data;
    // console.log(data);
    if (brandId !== null) {
      this.SetData(brandId);
    }
  });
  }

  SetData(branchId): any {
    this.branch.find(x => {
      if (+x.branchId === +branchId ) {
        this.id = x.brandId;

        this.BranchForm.setValue({
          BranchName: x.branchName,
          BranchStatus: x.branchStatus,
        });

       }
    });
  }


  branchForm(): any {
    this.BranchForm = this.formBuilder.group({
      BranchName: new FormControl('', [Validators.required, Validators.pattern('^[+]?[0-9a-zA-Z .-]*$'), Validators.maxLength(150)]),
      BranchStatus: new FormControl(true, [Validators.required]),
    });
  }

  get f(): any{
    return this.BranchForm.controls;
  }

  onSubmit(): any{
    // tslint:disable-next-line: no-debugger
    debugger;
    const branchId = +this.activeRoute.snapshot.paramMap.get('id');
    if (branchId !== null && branchId !== 0) {
      this.BranchFormSubmit = this.formBuilder.group({
        BranchId: branchId,
        BranchName: [this.BranchForm.get('BranchName').value],
        BranchStatus: [this.BranchForm.get('BranchStatus').value],
      });
      if (this.BranchForm.get('BranchName').hasError('required')) {
        this.toastrService.error('Please Input Branch Name', 'Error!');
        return;
      }

      if (this.BranchForm.get('BranchStatus').hasError('required')) {
        this.toastrService.error('Please Input Branch Status', 'Error!');
        return;
      }


      this.branchService.UpdateBranch(this.BranchFormSubmit.value)
        .subscribe(res => {
          // console.log(res);
          this.toastrService.success(res.message, 'Data Update Successfully.');
          this.branchForm();
          this.router.navigate(['/branch-list']);
        },
        error => {
          console.log(error);
          this.toastrService.error(error, 'Error!');
        });
    }
    else {
      this.BranchFormSubmit = this.formBuilder.group({
        BranchName: [this.BranchForm.get('BranchName').value],
        BranchStatus: [this.BranchForm.get('BranchStatus').value],
      });
      if (this.BranchForm.get('BranchName').hasError('required')) {
        this.toastrService.error('Please Input Branch Name', 'Error!');
        return;
      }

      if (this.BranchForm.get('BranchStatus').hasError('required')) {
        this.toastrService.error('Please Input Branch Status', 'Error!');
        return;
      }


      this.branchService.AddBranch(this.BranchFormSubmit.value)
        .subscribe(res => {
          debugger;
          this.toastrService.success(res.message, 'Data Save Successfully.');
          this.branchForm();
          this.router.navigate(['/branch-list']);
        },
        error => {
          debugger;
          console.log(error);
          this.toastrService.error(error, 'Error!');
        });
    }
  }

}
