<div class="app-title">
    <div>
      <h1><i class="fa fa-edit"></i>Seller</h1>
      <!-- <p>Sample forms</p> -->
    </div>
    <ul class="app-breadcrumb breadcrumb">
      <li class="breadcrumb-item"><i class="fa fa-home fa-lg"></i></li>
      <li class="breadcrumb-item">Seller</li>
      <li class="breadcrumb-item"><a routerLink="/seller-list">Seller List</a></li>
    </ul>
  </div>
  
  <div class="row">
    <div>
      <p>Form value: {{SellerForm.value | json}}</p>
      <p>Form status: {{SellerForm.status | json}}</p>
    </div>
    <div class="col-md-12">
      <div class="tile">
        <h3 class="tile-title"> Seller</h3>
        <div class="tile-body">
          <form [formGroup]="SellerForm" (ngSubmit)="onSubmit()" class="form-horizontal">

            <div class="form-group row">
              <div class="col-md-4">
                <label class="control-label">Seller Full Name</label>
                  <input name="" class="form-control" id="SellerName"  formControlName="SellerName" 
                  [ngClass]="{ 'is-invalid': submitted && f.SellerName.errors }" placeholder="Enter Seller Name" type="text" >  
                  <div *ngIf="submitted && f.SellerName.errors" class="invalid-feedback">
                      <div *ngIf="f.SellerName.errors.required">Name is required</div>
                  </div>
              </div>

              <div class="col-md-4">
                <label class="control-label">Email</label>
                <input name="" id="SellerEmail" class="form-control" formControlName="SellerEmail" 
                [ngClass]="{ 'is-invalid': submitted && f.SellerEmail.errors }" placeholder="Enter Seller Email" type="email">
                <p *ngIf="SellerForm.get('SellerEmail').hasError('required') && SellerForm.get('SellerEmail').touched">
                  <small class="text-danger">Email Address is <strong>required</strong></small>
                </p>
                <p *ngIf="SellerEmail.errors?.pattern" class="text-danger">Please provide a valid email address</p>
                <span class="highlight"></span><span class="bar"></span> 
              </div>

              <div class="col-md-4">
                <label class="control-label">Mobile</label>
                <input name="" id="SellerMobile" class="form-control" formControlName="SellerMobile" 
                [ngClass]="{ 'is-invalid': submitted && f.SellerMobile.errors }" placeholder="Enter Seller Mobile No" type="text">
                <p *ngIf="SellerForm.get('SellerMobile').hasError('required') && SellerForm.get('SellerMobile').touched">
                  <small class="text-danger">Mobile Number is <strong>required</strong></small>
                </p>
                <p *ngIf="SellerMobile.errors?.pattern" class="text-danger">Please provide a valid mobile number</p>
                <span class="highlight"></span><span class="bar"></span>

                <!-- <div *ngIf="submitted && f.SellerMobile.errors" class="invalid-feedback">
                    <div *ngIf="f.SellerMobile.errors.required">Mobile No is required</div>
                    <div *ngIf="f.SellerMobile.errors.pattern">Mobile must be a Proper Number</div>
                </div>  -->
            
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-6">
                <label class="control-label">User Name</label>
                <input class="form-control" id="userName" formControlName="UserName" 
                [ngClass]="{ 'is-invalid': submitted && f.ShopName.errors }" placeholder="Enter user name">
                <div *ngIf="submitted && f.UserName.errors" class="invalid-feedback">
                    <div *ngIf="f.UserName.errors.required">User name is required</div>
                </div>  
              </div>
              <div class="col-md-6">
                <label class="control-label">Seller Address</label>
                <textarea class="form-control" rows="2" id="ShopAddress" formControlName="SellerAddress" 
                [ngClass]="{ 'is-invalid': submitted && f.SellerAddress.errors }" placeholder="Enter your address"></textarea>
                <div *ngIf="submitted && f.SellerAddress.errors" class="invalid-feedback">
                    <div *ngIf="f.SellerAddress.errors.required">Address is required</div>
                </div>  
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label class="control-label">Shop Name</label>
                <input class="form-control" type="text" placeholder="Enter ShopName" autofocus
                name="shopname" required formControlName="ShopName">
                <p *ngIf="SellerForm.get('ShopName').hasError('required') && SellerForm.get('ShopName').touched">
                  <small class="text-danger">Shop Name is <strong>required</strong></small> <br>
                </p>
              </div>
              <div class="col-md-6">
                <label class="control-label">Shop Location</label>
                <textarea class="form-control" id="ShopLocation" formControlName="ShopLocation" 
                [ngClass]="{ 'is-invalid': submitted && f.ShopLocation.errors }" placeholder="Enter your address"></textarea>
                <div *ngIf="submitted && f.ShopLocation.errors" class="invalid-feedback">
                    <div *ngIf="f.ShopLocation.errors.required">Shop Location is required</div>
                </div>  
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-12">
                <label class="control-label">Shop Description</label>
                <!-- <textarea class="form-control" rows="4" id="Shop_Address" formControlName="Seller_Description" [ngClass]="{ 'is-invalid': submitted && f.Seller_Description.errors }" placeholder="Enter Seller Dewscription"></textarea> -->
                <!-- <div *ngIf="submitted && f.Seller_Description.errors" class="invalid-feedback">
                    <div *ngIf="f.Seller_Description.errors.required">Seller Description is required</div>
                </div>   -->
                <ckeditor [editor]="Editor"
                  formControlName="ShopDescription"
                id="ShopDescription"
                name="ShopDescription"
                type="divarea"
                >
                </ckeditor>
                <!-- <ckeditor [editor]="Editor" data="<p>Hello, csharp corner!</p><br/><br/> <b>This is demo for ckeditor 5 with angular 8</br>"></ckeditor>  -->
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label class="control-label">Password</label>
                <input class="form-control" type="password" placeholder="Enter Password" pattern="^.{6,14}$"
                name="userPassword" required formControlName="UserPassword">
                <!-- <i class="fa fa-key md6"></i> -->
                <p *ngIf="SellerForm.get('UserPassword').hasError('required') && SellerForm.get('UserPassword').touched">
                  <small class="text-danger">Password is <strong>required</strong></small>
                </p>
                <p
                *ngIf="SellerForm.get('UserPassword').hasError('minlength')
                  && SellerForm.get('UserPassword').touched">
                  <small class="text-danger">Password must be at least 4 characters</small>
              </p>
              <p
                *ngIf="SellerForm.get('UserPassword').hasError('maxlength')
                  && SellerForm.get('UserPassword').touched">
                  <small class="text-danger">Password can not exceed 12 characters</small>
              </p>
              <span class="highlight"></span><span class="bar"></span>
              </div>
              <div class="col-md-6">
                <label class="control-label">Confirm Password</label>
                <input class="form-control" type="password" placeholder="Enter Password" pattern="^.{6,14}$"
                name="confirmPassword" required formControlName="ConfirmPassword" 
                [ngClass]="{'is-invalid': SellerForm.get('ConfirmPassword').errors
                && SellerForm.get('ConfirmPassword').touched
                || SellerForm.get('ConfirmPassword').touched
                && SellerForm.hasError('mismatch')}">
                <p *ngIf="SellerForm.get('ConfirmPassword').hasError('required') && SellerForm.get('ConfirmPassword').touched">
                  <small class="text-danger">Confirm Password is <strong>required</strong></small>
                </p>
                <p
                  *ngIf="SellerForm.hasError('mismatch')
                  && SellerForm.get('ConfirmPassword').touched">
                  <small class="text-danger">Password can not matched</small>
                </p>
               <span class="highlight"></span><span class="bar"></span>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-12">
                <label class="control-label">Shop Image</label>
                <input 
                      formControlName="file"
                      id="file" 
                      type="file" 
                      class="form-control"
                      (change)="onFileChange($event, 1)">
                    <div *ngIf="f.file.touched && f.file.invalid" class="alert alert-danger">
                        <div *ngIf="f.file.errors.required">File is required.</div>
                    </div>
              </div>
              <div class="col-12">
                <img [src]="ShopImagePath" height="150" width="200px" style="margin: 3px;">
              </div>
            </div>

            <button class="btn btn-primary" [disabled]="!SellerForm.valid"  type="submit">Submit</button>
          </form>
        </div>
       
      </div>
    </div>
    
  </div>