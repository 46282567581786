<form [formGroup]="signupForm" (ngSubmit)="onSubmit()" class="login-form reg-form account">
<h3 class="login-head"><i class="fa fa-lg fa-fw fa-address-book"></i>SIGN UP</h3>
<div class="row"  *ngIf="isAdminReg" >
<div class="col-12">
  <label class="control-label">Select a user group</label>
  <select name="userGroup" id="userGroup"  class="form-control" required formControlName="userGroup" [(ngModel)]="groupId">
    <option value="0">Select a user group</option>
    <option *ngFor='let u of userGroup' value="{{u.userGroupId}}">{{u.userGroupTitle}}</option>
  </select>
</div>
</div>



          <div class="row">
          <div class="col-md-6">
            <label class="control-label">User Name</label>
            <input class="form-control" type="text" placeholder="Enter Username" autofocus
            name="uname" required formControlName="userLoginName">
            <i class="fa fa-user-secret md6"></i>
            <p *ngIf="signupForm.get('userLoginName').hasError('required') && signupForm.get('userLoginName').touched">
              <small class="text-danger">Username is <strong>required</strong></small> <br>
            </p>
        </div>
        <div class="col-md-6">
          <label class="control-label">Full Name</label>
          <input class="form-control" type="text" placeholder="Enter Full Name"
          name="FullName" required formControlName="userFullName">
          <i class="fa fa-user md6"></i>
          <p *ngIf="signupForm.get('userFullName').hasError('required') && signupForm.get('userFullName').touched">
            <small class="text-danger">Full is <strong>required</strong></small>
          </p>
        </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <label class="control-label">Password</label>
            <input class="form-control" type="password" placeholder="Enter Password" pattern="^.{6,14}$"
            name="userPassword" required formControlName="userPassword">
            <i class="fa fa-key md6"></i>
            <p *ngIf="signupForm.get('userPassword').hasError('required') && signupForm.get('userPassword').touched">
              <small class="text-danger">Password is <strong>required</strong></small>
            </p>
          </div>
          <div class="col-md-6">
            <label class="control-label">Confirm Password</label>
            <input class="form-control" type="password" placeholder="Enter Password" pattern="^.{6,14}$"
            name="confirmPassword" required formControlName="confirmPassword">
            <i class="fa fa-key md6"></i>
            <p *ngIf="signupForm.get('confirmPassword').hasError('required') && signupForm.get('confirmPassword').touched">
              <small class="text-danger">Password is <strong>required</strong></small>
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <label class="control-label">Contact Number</label>
            <input class="form-control" type="tel" placeholder="Enter Mobile Number" minlength="11" maxlength="11"
            name="Mobile" required formControlName="userMobile">
            <i class="fa fa-phone md6"></i>
            <p *ngIf="signupForm.get('userMobile').hasError('required') && signupForm.get('userMobile').touched">
              <small class="text-danger">Mobile number is <strong>required</strong></small>
            </p>
          </div>
          <div class="col-md-6">
              <label class="control-label">Email</label>
              <input class="form-control" type="email" placeholder="Enter Email" 
              name="email" formControlName="userEmail">
              <i class="fa fa-envelope md6"></i>
              <!-- <p *ngIf="signupForm.get('userEmail').hasError('required') && signupForm.get('userEmail').touched">
                <small class="text-danger">Email is <strong>required</strong></small>
              </p> -->
            </div>
          </div>
          
          <div class="form-group btn-container">
            <button type="submit" class="btn btn-block"><i class="fa fa-handshake-o"></i>SIGN UP</button>
          </div>
        </form>
             <!-- <div class="form-group">
            <label class="control-label">Shop Address</label>
            <textarea class="form-control" placeholder="Enter Shop Address" pattern="^.{6,14}$"
            name="Mobile" required formControlName="password"></textarea>
            <i class="fas fa-map-marked-alt"></i>
            <p *ngIf="signupForm.get('password').hasError('required') && signupForm.get('password').touched">
              <small class="text-danger">Password is <strong>required</strong></small>
            </p>
          </div>
          <div class="form-group">
            <label class="control-label">Brif of Your Shop</label>
            <textarea class="form-control" placeholder="Enter Brif of Your Shop" pattern="^.{6,14}$"
            name="Mobile" required formControlName="password"></textarea>
            <i class="fa fa-tags"></i>
            <p *ngIf="signupForm.get('password').hasError('required') && signupForm.get('password').touched">
              <small class="text-danger">Password is <strong>required</strong></small>
            </p>
          </div>
          <div class="row">
          <div class="col-md-12">
          <label class="control-label">Shop Image</label>
          <div class="profile-img" style="border: 1px solid #ddd; padding: 0px 180px;">
            <img id="profileImg" [src]="shopImgSrc" alt=""/>
            <div class="file btn btn-lg btn-primary">
                Change Photo 
                <input 
                id="file" type="file" class="form-control"
                (change)="onFileChange($event, 1)">
            </div>
        </div>
      </div>
    </div>
      <div class="form-group">
        <label class="control-label">Is Quantity Check When Customer Add To Cart</label>
        <div class="utility" style="border: 1px solid #ddd; padding: 10px;">
          <div class="animated-checkbox">
            <label style="font-size: 12px;">
              <input type="checkbox" hidden><span class="label-text">Is Stock Quantity Check When Customer Add Your Product To Cart? If Checked Then System Will Check Your Quantity.</span>
            </label>
          </div>
        </div>
      </div> -->