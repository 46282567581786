
    <div class="app-title">
        <div>
          <h1><i class="fa fa-th-list"></i> Order</h1>
          <!-- <p>Supplier list</p> -->
        </div>
        <ul class="app-breadcrumb breadcrumb">
          <li class="breadcrumb-item"><i class="fa fa-home fa-lg"></i></li>
          <li class="breadcrumb-item">Order Details</li>
          <li class="breadcrumb-item active"><a routerLink="/pending-order-list">All Pending Order</a></li>
        </ul>
      </div>
      <div class="row">
          <div class="col-md-12">
              <div class="tile">
                
                <div class="tile-title-w-btn">
                    <div class="row">
                        <div class="col-md-4">Order ID: <b>{{ orderData.orderId }}</b>
                        </div>
                        <div class="col-md-4">Order No: <b>{{orderData.orderNo}}</b></div>
                        <div class="col-md-4">Total Amount: <b>{{orderData.totalAmount}}</b></div>
                        <div class="col-md-4">Customer Name: <b>{{orderData.customerName}}</b></div>
                        <div class="col-md-4">Order Date :   {{orderData.orderDate | date: 'dd/MM/yyyy'}}</div>
                    </div>
                
                </div>
                <div class="table-responsive">
            
                          
                    <button (click)="orderd(data)" class="btn btn-success btn-sm rounded-0" type="button" data-toggle="tooltip" data-placement="top" title="Edit">Submit</button>
                <br><br>
                  <table class="table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Product Name</th>
                        <th>Quantity</th>
                        <th>Shop Name</th>
                        <th>Price</th>
                        <th>Sub Total</th>
                      
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let order of data ; let i = index" id="tr_{{order.order}}">
                        <td>{{i+1}}</td>
                        <td>{{order.productName}}</td>
                        <td>{{order.quantity}}</td>
                        <td>{{order.shopName}}</td>
                        <td>{{order.price}}</td>
                        <td>{{order.subTotal}}</td>
                      <td>
                        
                      </td>
                      </tr>
  
                      
                    </tbody>
                  </table>
                </div>
                
              </div>
            </div>
          
      </div>
  