import { Component, OnInit, ElementRef, ViewChild, Input,IterableDiffers } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as XLSX from 'xlsx';
import { UserService } from '../../../services/user/user.service';
import { ToastrService } from 'ngx-toastr';
import { BranchService } from '../../../services/branch/branch.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.scss']
})
export class CustomerListComponent implements OnInit {
  customerData: any;
  public iterableDiffer = null;

 // @Input() public customerData: Array<any>;
 // branchData: Array<any>;
  @ViewChild('TABLE', { static: false }) TABLE: ElementRef;
  title = 'Excel';

  ExportTOExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.TABLE.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'CustomerList.xlsx');
  }
  constructor(private customerService: UserService, private iterableDiffers: IterableDiffers, public datepipe: DatePipe) {
    this.iterableDiffer = iterableDiffers.find([]).create(null);
   }
   dtOptions: any = {};
  ngOnInit(): void {
    // this.loadCustomer();
  }
  ngDoCheck() {
    const lookup = {};
    //debugger;

    if (this.customerData) {
      const items = this.customerData.ProductArray;
      const changes = this.iterableDiffer.diff(items);
      if (changes) {
        this.dtOptions = {
          pagingType: 'full_numbers',
          pageLength: 5,
          lengthMenu : [5, 10, 25],
          processing: true
        };
      }
    }    
  }

   loadCustomer(): any{
    var fromDate = ((document.getElementById("FromDate") as HTMLInputElement).value);
    var todate = ((document.getElementById("ToDate") as HTMLInputElement).value);

    // var nFormdate = this.datepipe.transform(formdate, 'dd/MM/yyyy');
    // var nFodate = this.datepipe.transform(todate, 'dd/MM/yyyy');

    const params = {fromDate, todate};

    this.customerService.getCustomerList(params)
    .subscribe(
      (data: any) => {
        this.customerData = data;
        $('#customerTable').DataTable().clear();
        $('#customerTable').DataTable().destroy();

        // if(formdate == "" && todate == ""){
        //   this.customerData = data;
        // }
        // else {
        //   //debugger;
        //     // var table = $('#customerTable').DataTable();
        //     // table.clear().draw();
        //     $('#customerTable').DataTable().clear();
        //     $('#customerTable').DataTable().destroy();
        //     // data.forEach(item => item.createdDate = this.datepipe.transform(item.createdDate, 'MM/dd/yyyy'));
        //     // data.forEach(item => item.createdDate = item.createdDate.split(" "));
        //     // this.customerData = data.filter(item => item.createdDate[0] >= nFormdate && item.createdDate[0] <= nFodate);
        // }
      },
      error => {console.log(error); }
    );
   }
}
