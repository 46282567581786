import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { PurchaseDetailsService } from '../../../services/purchaseDetails/purchaseDetails.service';
import { ToastrService } from 'ngx-toastr';
// import Swal from 'sweetalert2/dist/sweetalert2.js';
// import { NgxSpinnerService } from "ngx-spinner"; 

@Component({
  selector: 'app-purchase-list',
  templateUrl: './purchase-list.component.html',
  styleUrls: ['./purchase-list.component.scss']
})
export class PurchaseListComponent implements OnInit {

  data: Array<any>;
  categoris: any[] = [];

  constructor(
    private purchaseService: PurchaseDetailsService,
    private router: Router,
    private http: HttpClient,
    private toastrService: ToastrService
    // private SpinnerService: NgxSpinnerService
    ) {
       this.GetPurchaseList();
      }

  ngOnInit(): void {
  }


  // tslint:disable-next-line: typedef
  GetPurchaseList()
    {
      // this.SpinnerService.show();
     this.purchaseService.getPurchaseList()
     .subscribe(
       data => {
         this.data = data;
         console.log(data);
        //  this.SpinnerService.hide();
       },
       error => {console.log(error);
        // this.SpinnerService.hide();
      }
       );
    }

    // tslint:disable-next-line: typedef
  edit(id: any){
    // tslint:disable-next-line: no-debugger
    //debugger;
    this.router.navigate(['/purchase-update/' + id]);
  }

  // tslint:disable-next-line: typedef
delete(id: any){
  // tslint:disable-next-line: no-debugger
  //debugger;
  this.purchaseService.DeletePurchaseById(id).subscribe(success => {
    this.toastrService.success(success.message, 'Data Delete Successfully.');
      // tslint:disable-next-line: typedef
    this.purchaseService.getPurchaseList()
      .subscribe(
          data => {
            this.data = data;
            console.log(data);
            //  this.SpinnerService.hide();
          },
          error => {console.log(error);
            // this.SpinnerService.hide();
          }
        );
    });
  }

}

