<app-header></app-header>
<app-side-menu></app-side-menu>
<main class="app-content">
  <div class="row">
    <div class="col-md-12">
      <div class="tile">
        <div class="tile-title-w-btn" *ngFor="let data of branchData">
          <!-- <h1><i class="fa fa-th-list"></i> {{data.branchName}} Customer List</h1>
            <button (click)="ExportTOExcel()" class="btn btn-success">ExportTOExcel</button> -->
          <div class="col-12">
            <div class="row mt-4">
              <div class="col-md-4">
                <h1 style="text-align: left;"><i class="fa fa-th-list"></i> {{data.branchName}} Customer List</h1>
              </div>
              <div class="col-md-8 mt-2">
                <div class="row">
                  <div class="col-md-3">
                    <input id="FormDate" type="date" class="form-control">
                  </div>
                  <div class="col-md-3">
                    <input id="ToDate" type="date" class="form-control">
                  </div>
                  <div class="col-md-2">
                    <button (click)="getCustomerByParams()" class="btn btn-primary">Search</button>
                  </div>
                  <div class="col-md-4 text-center" *ngIf="userGroupType == 'SU'">
                    <button (click)="ExportTOExcel()" class="btn btn-success">Export To Excel</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12 col-sm-9 col-md-6 col-lg-4">
      <div class="widget-bg-color-icon card-box fadeInDown animated">
        <div class="row">
          <div class="col-md-12 p-2">
            <canvas id="ageGroup"></canvas>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-center">
            <span class="font-weight-bolder">Total Age Group {{ totalAgeGroup }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xs-12 col-sm-9 col-md-6 col-lg-4">
      <div class="widget-bg-color-icon card-box fadeInDown animated">
        <div class="row">
          <div class="col-md-12 p-2">
            <canvas id="billingRange"></canvas>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-center">
            <span class="font-weight-bolder">Total Billing Range {{ totalBillingRange }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12 col-sm-9 col-md-6 col-lg-4">
      <div class="widget-bg-color-icon card-box fadeInDown animated">
        <div class="row">
          <canvas id="experience"></canvas>
        </div>
        <div class="row">
          <div class="col-md-12 text-center">
            <span class="font-weight-bolder">Service Review {{ totalServiceReview }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xs-12 col-sm-9 col-md-6 col-lg-4">
      <div class="widget-bg-color-icon card-box fadeInDown animated">
        <div class="row">
          <canvas id="interestCategory"></canvas>
        </div>
        <div class="row">
          <div class="col-md-12 text-center">
            <span class="font-weight-bolder">Interested Category {{ totalCategorySelect }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12 col-sm-9 col-md-6 col-lg-4">
      <div class="widget-bg-color-icon card-box fadeInDown animated">
        <div class="row">
          <canvas id="gotToKnow"></canvas>
        </div>
        <div class="row">
          <div class="col-md-12 text-center">
            <span class="font-weight-bolder">Get to know about us {{ totalGetToKnow }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xs-12 col-sm-9 col-md-6 col-lg-4">
      <div class="widget-bg-color-icon card-box fadeInDown animated">
        <div class="row">
          <canvas id="seeOnline"></canvas>
        </div>
        <div class="row">
          <div class="col-md-12 text-center">
            <span class="font-weight-bolder">See product from (Offline / Online) {{ totalSeeOnline }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12 col-sm-9 col-md-6 col-lg-4">
      <div class="widget-bg-color-icon card-box fadeInDown animated">
        <div class="row">
          <canvas id="associateOffer"></canvas>
        </div>
        <div class="row">
          <div class="col-md-12 text-center">
            <span class="font-weight-bolder">Associate Offer {{ totalAssociateOffer }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xs-12 col-sm-9 col-md-6 col-lg-4">
      <div class="widget-bg-color-icon card-box fadeInDown animated">
        <div class="row">
          <canvas id="receiveCommercial"></canvas>
        </div>
        <div class="row">
          <div class="col-md-12 text-center">
            <span class="font-weight-bolder">Receive Commercial {{ totalReceiveCommercial }}</span>
          </div>
        </div>
        
      </div>
    </div>
  </div>
  <div class="row" style="display: none;">
    <div class="col-md-12" #TABLE #table>
      <div class="tile">
        <table class="table table-striped table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions"
          id="customerTable">
          <thead>
            <tr>
              <th>Sl No <Noscript></Noscript></th>
              <th>Name</th>
              <th>Gender</th>
              <th>Age Group</th>
              <th>City</th>
              <th>Phone</th>
              <th>Email</th>
              <th>I would be interested in receiving <br/> information about</th>
              <th>How did you get to know <br/> about us?</th>
              <th>Did you see the <br> products online that <br> you have purchased?</th>
              <th>I would like to receive <br> commercials via-</th>
              <th>Did one or more associates <br> offer to help you?</th>
              <th>Remarks</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of customerDetail; let i = index" id="tr_{{item.customerId}}">
              <td>{{i+1}}</td>
              <td><a routerLink="/customer-details/{{item.customerId}}">{{item.name}}</a></td>
              <td>{{item.gender}}</td>
              <td>{{item.ageGroupName}}</td>
              <td>{{item.city}}</td>
              <td>{{item.userMobile}}</td>
              <td>{{item.userEmail}}</td>
              <td>{{item.interestedInfo}}</td>
              <td>{{item.howToKnowAboutUs}}</td>
              <td>{{item.isProductOnline == true ? 'Yes' : 'No'}}</td>
              <td>{{item.rcvComVia}}</td>
              <td>{{item.associateOffer == true ? 'Yes' : 'No'}}</td>
              <td>{{item.remarks}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
    
  <router-outlet></router-outlet>
</main>
<!-- <router-outlet></router-outlet> -->


