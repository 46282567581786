import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {
 // treeViewMenu: any;
  treeViewMenuChildren: any;
  aChildren: any;
  isSubClick: boolean;
  branchId: any;
  userGroupType: any;
  constructor() { }

  // tslint:disable-next-line: typedef
  ngOnInit(){
    this.userGroupType = localStorage.getItem('userGroupType');
    this.branchId = localStorage.getItem('branchId');
    this.isSubClick = false;
    const appMenuATag = document.querySelectorAll('a.app-menu__item');
    const appMenuATagItems = [].slice.call(appMenuATag);
    // open the content element when clicking on the buttonsItems
     appMenuATagItems.forEach((item, idx) => {
          item.addEventListener('click', () => {
              // console.log(self);
             // this.active(item, idx);
              // console.log(item);
          }, false);
      });
     const treeview = document.querySelectorAll('li.treeview');
     treeview.forEach((item, idx) => {
          item.addEventListener('click', () => {
             // this.expand(idx);
          }, false);
      });
     const appSubMenuATag = document.querySelectorAll('a.treeview-item');
     appSubMenuATag.forEach((item, idx) => {
      item.addEventListener('click', () => {
         // this.activeSubMenu(idx);
          // alert(idx);
      }, false);
  });
  }
activeSubMenu(id){
  const appSubMenuATag = document.querySelectorAll('a.treeview-item');
  Array.from(appSubMenuATag).forEach(element => {
    element.classList.remove('active');
  });
  appSubMenuATag[id].classList.add('active');
  appSubMenuATag[id].parentElement.parentElement.parentElement.children[0].classList.add('active');
  appSubMenuATag[id].parentElement.parentElement.parentElement.classList.add('is-expanded');
  this.isSubClick = true;
  // alert(appSubMenuATag[id].parentElement.parentElement.parentElement.children[0].innerHTML);
  // alert(appSubMenuATag[id].parentElement.parentElement.parentElement.innerHTML);
}
  // tslint:disable-next-line: typedef
  active(item, id){
    const appMenuATag = document.querySelectorAll('a.app-menu__item');
  
    Array.from(appMenuATag).forEach(element => {
      element.classList.remove('active');
    });
    appMenuATag[id].classList.add('active');
    // }
  }
  // tslint:disable-next-line: typedef
  expand(id){
    const treeview = document.querySelectorAll('li.treeview');
    // treeview[id].classList.toggle('is-expanded');
    if (!this.isSubClick)
    {
      if (treeview[id].classList.contains('is-expanded'))
      {
       // treeview[id].classList.remove('is-expanded');
      }
      else{
        // Array.from(treeview).forEach(element => {
        //   element.classList.remove('is-expanded');
        // });
        //treeview[id].classList.add('is-expanded');
      }
    }
    this.isSubClick = false;
  }
}
