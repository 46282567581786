import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/user/user.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Common } from '../../../services/common/common';

@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.scss']
})
export class CustomerDetailsComponent implements OnInit {
  customerDetails: Array<any>;
  ageGroupData: Array<any>;
  socialInfoData: Array<any>;
  interesteInfoData: Array<any>;

  constructor(private customerService: UserService, private activeRoute: ActivatedRoute, private toastrService: ToastrService) {
    this.getAgeGroupList();
    this.getSocialInfoList();
    this.getInterestedInfoList();
   }

  ngOnInit(): void {
    const id = +this.activeRoute.snapshot.paramMap.get('id');
    if (id !== null){
      this.loadCustomerDetailsById(id);
      }
  }

  loadCustomerDetailsById(customerId): any{
    // tslint:disable-next-line: no-debugger
    //debugger;
    this.customerDetails = new Array<any>();
    this.customerService.getCustomerDetailsById(customerId).subscribe((data: any[]) => {
     this.customerDetails = data;
     console.log(data);
     this.customerDetails.forEach(item => item.signatureUrl = Common.baseUrl + '/images/' + item.signatureUrl);
   });
   }

  getAgeGroupList(){
    this.ageGroupData = new Array<any>();
    this.customerService.getAgeGroupList().subscribe((data: any) => {
      this.ageGroupData = data;
      // console.log(this.ageGroupData);
    },
    error => {console.log(error); }
    );
  }

  getSocialInfoList(){
    this.socialInfoData = new Array<any>();
    this.customerService.getSocialInfoList().subscribe((data: any) => {
      this.socialInfoData = data;
    //  console.log(this.socialInfoData);
    },
    error => {console.log(error); }
    );
  }

  getInterestedInfoList(){
    this.interesteInfoData = new Array<any>();
    this.customerService.getInterestedInfoList().subscribe((data: any) => {
      this.interesteInfoData = data;
    //  console.log(this.interesteInfoData);
    },
    error => {console.log(error); }
    );
  }

}
