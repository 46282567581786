
    <div class="app-title">
        <div>
          <h1><i class="fa fa-th-list"></i> Order</h1>
          <!-- <p>Supplier list</p> -->
        </div>
        <ul class="app-breadcrumb breadcrumb">
          <li class="breadcrumb-item"><i class="fa fa-home fa-lg"></i></li>
          <li class="breadcrumb-item">Pernding Order</li>
          <!-- <li class="breadcrumb-item active"><a routerLink="/purchase-add">Add Purchase</a></li> -->
        </ul>
      </div>
      <div class="row">
          <div class="col-md-12">
              <div class="tile">
                
                <div class="tile-title-w-btn">
                  <h3 class="title">Pending Order List</h3>
                  
                  <!-- <p><a class="btn btn-primary icon-btn" routerLink="/purchase-add"><i class="fa fa-plus"></i>Add Purchase</a></p> -->
                </div>
                <!-- <div class="table-responsive"> -->
                  <!-- <table  datatable [dtOptions]="dtOptions" class="table"> -->
                    <table id="user-table" class="table table-striped table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions">
                    <!-- <table class="table table-striped table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions"> -->
                    <!-- <table  class="table"> -->
                    <thead>
                      <tr>
                        <!-- <th>#</th> -->
                        <th>Customer Name</th>
                        <th>OrderID</th>
                        <th>OrderNo</th>
                        <th>Total Amount</th>
                        <th>Order Date</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- <tr *ngFor="let order of PendingorderData ; let i = index" id="tr_{{order.order}}"> -->
                        <tr *ngFor="let order of PendingorderData">
                        <!-- <td>{{i+1}}</td> -->
                        <td>{{order.customerName}}</td>
                        <td>{{order.orderId}}</td>
                        <td>{{order.orderNo}}</td>
                        <td>{{order.totalAmount}}</td>
                        <td>{{order.createDate | date: 'dd/MM/yyyy'}}</td>
                      <td>
                        <li class="list-inline-item" (click)="orderdetail(order.orderId)">
                          
                            <button  class="btn btn-success btn-sm rounded-0" type="button" data-toggle="tooltip" data-placement="top" title="Edit">Details</button>
                        </li>
                        <li class="list-inline-item" (click)="Chekout(order.orderId)">
                          <button  class="btn btn-danger btn-sm rounded-0" type="button" data-toggle="tooltip" data-placement="top" title="Edit">Active</button>
                        </li>
                        
                      </td>
                      </tr>
  
                      
                    </tbody>
                  </table>
                <!-- </div> -->

              </div>
            </div>
          
      </div>
  