import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Common } from './common/common';
@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  constructor(private http: HttpClient) { }

  public registrationService(data) {
    return this.http.post<any>(Common.baseUrl + '/Agent/Registration', data)
      .pipe(map((result: any) => {
        if (result) {
          return result;
        }
      }));
  }
}
